import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RWebShare } from "react-web-share";
import { HeartIcon, LocationIcon, ShareIcon } from "../assets/icons";
import { addParticularPropertyIntoFav } from "../services/favouritePropertyServices";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Rating,
  Skeleton,
  Typography,
} from "@mui/material";
import {
  formatCurrency,
  replaceHttpImgUrl,
} from "../utils/helperFunctionForValidte";
import ShareComponent from "./shareComponent";
import { RetrieveLocalUser } from "../utils/authService";

const Property = ({
  propertyName,
  address,
  rate,
  status,
  isFavourite,
  img,
  rent,
  _id,
  propertyID,
  rented,
  liked,
  rentType,
  total_reviews,
  avg_rating,
  favouriteApiCall = () => {}, // this prop is set if the faviourite api we have to call after like or dislike and it's work here as optional props
}) => {
  const navigate = useNavigate();
  const user = RetrieveLocalUser("user");
  const [favouriteStatus, setFavouriteStatus] = useState(liked);
  const [loading, setLoading] = useState(false);

  // add the property to favourite as per the backend api
  const addThePropertyToFav = async (e) => {
    e.stopPropagation();
    setFavouriteStatus(!favouriteStatus);
    setLoading(true);
    const getData = await addParticularPropertyIntoFav(_id);
    setLoading(false);
    favouriteApiCall(!favouriteStatus);
    console.log("getdata", getData);
    // window.location.reload()
  };

  return (
    <Card
      elevation={0}
      sx={{ background: "transparent", cursor: "pointer" }}
      className="product-cards-section"
    >
      <Box
        sx={{
          height: 280,
          position: "relative",
        }}
      >
        <img
          src={replaceHttpImgUrl(img)}
          className="w-100 h-100 object-fit-cover"
          alt="property_pic"
          onClick={() => navigate(`/property-detail/${_id}`)}

          // onLoad={() => setLoading(false)}
          // style={{ display: loading ? "none" : "block" }}
        />
        <div className="d-flex justify-content-between align-items-center p-2 position-absolute top-0 w-100 product-content">
          <Chip
            sx={{
              background: "white",
              color: "#009DA6",
              fontSize: 12,
              fontWeight: "bold",
            }}
            label={rented ? "Rented" : "Available"}
            size="small"
          />
          <div className="d-flex justify-content-center align-items-center gap-3">
            <ShareComponent
              shareData={{ shareWhite: true, propertyName: propertyName,data:{
                id:_id,
                
              } }}
            />
            {user && (
              <div
                style={{ position: "relative", zIndex: "999" }}
                onClick={(e) => {
                  addThePropertyToFav(e);
                }}
              >
                <button
                  disabled={loading}
                  style={{
                    background: "transparent",
                    border: "none",
                  }}
                >
                  <HeartIcon isFavourite={favouriteStatus} />
                </button>
              </div>
            )}
          </div>
        </div>
      </Box>
      <CardContent className="px-0">
        <div className="d-flex justify-content-between align-items-center">
          <Typography fontSize={18} color={"text.secondary"} fontWeight={500}>
            {propertyName}
          </Typography>

          <div className="d-flex align-items-center gap-1">
            <Rating
              precision={1}
              value={avg_rating}
              readOnly
              sx={{
                "& .MuiRating-iconFilled": {
                  color: "#D3DF5E", // Change the color of the filled stars
                },
              }}
              size="small"
            />

            <Typography fontSize={14} color={"text.secondary"}>
              {total_reviews}
            </Typography>
          </div>
        </div>
        <div className="d-flex align-items-center gap-1">
          <LocationIcon />

          <Typography
            fontSize={14}
            color="#8D9B9F"
            fontWeight={200}
            className="mt-1"
          >
            {address?.addressText}
          </Typography>
        </div>

        <Typography
          fontSize={18}
          color="#031013"
          fontWeight={900}
          className="mt-1"
        >
          {`${formatCurrency(rent)}/${rentType}`}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Property;
