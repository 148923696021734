import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { theme } from "../../../assets/theme";
import {
  ApplicationDetail,
  ApplicationRequestStatusChangedService,
} from "../../../services/application.services";
import { useEffect, useState } from "react";
import { inspectionApplicationRequestStatus } from "../../../utils/appConstants";
import { replaceHttpImgUrl } from "../../../utils/helperFunctionForValidte";
import { IdentificationType } from "../../../utils/CONSTANTS";
import LandlordCommonModal from "../../../components/landlordCommonModal";
import InspectionApplicationRequestModal from "../inspection-request/inspectionRequestModal";
import ButtonComponent from "../../../components/Button";
import { fDate } from "../../../utils/format-time";
const ApplicantDetails = () => {
  const location = useLocation();
  const { id } = useParams();
  const [btnLoader, setBtnLoader] = useState(false);
  const [applicationDetailState, setApplicationDetailState] = useState({});
  const [cancelModal, setCancelModal] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const [apiNotCallOnCancel, setApiNotCallOnCancel] = useState("");
  const navigate = useNavigate();

  // get the application detail data from the backend
  const getTheApplicationDetail = async () => {
    const getData = await ApplicationDetail(id);
    console.log("getDetail", getData);
    if (getData?.status) {
      setApplicationDetailState(getData?.data);
    }
  };

  // accept the request of application
  const applicationReqAccept = async () => {
    const body = {
      status: inspectionApplicationRequestStatus?.accept,
      rentApplicationID: applicationDetailState?._id,
    };
    setBtnLoader(true);
    const changeStatus = await ApplicationRequestStatusChangedService(body);
    if (changeStatus.status) {
      // setModalData(data);
      // navigate(-1);
      setAcceptModal(true);
    } else {
      alert("Some thing went wrong");
    }
    setBtnLoader(false);
  };

  useEffect(() => {
    getTheApplicationDetail();
  }, []);

  console.log("application", applicationDetailState);
  return (
    <div className="px-5 pb-5">
      <Box container className="application_details">
        <div className="d-flex align-items-center gap-4">
          {/* <Icon
            onClick={() => navigate(-1)}
            icon="zondicons:arrow-left"
            cursor={"pointer"}
          /> */}
          {/* <Typography className="" variant="h4" py={3}>
         
          </Typography> */}
          <Typography
            fontSize={40}
            fontWeight={700}
            color={theme.palette.text.primary}
            marginBottom={"20px"}
            className="title_page pt-4"
          >
            Application Details
          </Typography>
        </div>
        <Grid container spacing={4} className="mt-2 ">
          <Grid item md={12} xs={12}>
            <Grid container spacing={6}>
              <Grid item md={6} xs={12}>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  gap={6}
                  alignItems={"center"}
                  sx={{
                    backgroundImage: "url(/images/header-back.png)",
                    height: 250,
                    backgroundRepeat: "repeat",
                    borderRadius: 2,
                  }}
                  className="application_details_card"
                >
                  <Box
                    height={167}
                    width={167}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    borderRadius={"50%"}
                  >
                    <img
                      src={applicationDetailState?.renter_info?.picture}
                      style={{
                        height: "155px",
                        width: "155px",
                        borderRadius: "50%",
                      }}
                    />
                  </Box>
                  <div>
                    <div className="d-flex align-items-center  gap-2">
                      <Typography color={"white"} variant="h3">
                        {applicationDetailState?.renter_info?.fullName}
                      </Typography>

                      <img
                        src={"/images/user-verified.png"}
                        style={{ height: 18, width: 62 }}
                      />
                    </div>

                    <Typography
                      color="#C8D736"
                      fontSize={18}
                      fontWeight={"bold"}
                      variant="body1"
                    >
                      "Renter"
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md={6} xs={12} className="mt-3">
                <div className="property_name">
                  <h5>Property Name</h5>
                  <p>
                    <b>{applicationDetailState?.propertyName}</b>
                  </p>
                </div>
                <div className="contact_info mt-4">
                  <h4>Contact Info</h4>
                  <ul>
                    <li>
                      <img src="/assets/icons/call.png" alt="" />
                      {applicationDetailState?.contactNumber}
                    </li>
                    {/* <li>
                      <img src="/assets/icons/RentedProperties.png" alt="" />
                      Sam Okwaraji Street, 900108 Gwarinpa, Nigeria
                    </li> */}
                  </ul>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={4} className="mt-2 ">
          <h3 className="title3 phone-heading-30">Application Form</h3>
        </Grid>
        <div className="mt-5 border-bottom pb-3">
          <h4>Personal details</h4>

          <Grid container spacing={4} className="mt-2">
            <Grid item md={3} xs={12}>
              <div className="form_details_card">
                <p>First Name: </p>
                <h5>{applicationDetailState?.firstName}</h5>
              </div>
            </Grid>
            <Grid item md={3} xs={12}>
              <div className="form_details_card">
                <p>Middle Name: </p>
                <h5>
                  {applicationDetailState?.middleName
                    ? applicationDetailState?.middleName
                    : "No middle name"}
                </h5>
              </div>
            </Grid>
            <Grid item md={3} xs={12}>
              <div className="form_details_card">
                <p>Last Name: </p>
                <h5>{applicationDetailState?.lastName}</h5>
              </div>
            </Grid>
            <Grid item md={3} xs={12}>
              <div className="form_details_card">
                <p>Gender: </p>
                <h5>{applicationDetailState?.gender}</h5>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={4} className="mt-2">
            <Grid item md={3} xs={12}>
              <div className="form_details_card">
                <p>Marital Status: </p>
                <h5>{applicationDetailState?.maritialStatus}</h5>
              </div>
            </Grid>
            <Grid item md={3} xs={12}>
              <div className="form_details_card">
                <p>Contact Number: </p>
                <h5>{applicationDetailState?.contactNumber}</h5>
              </div>
            </Grid>
            <Grid item md={3} xs={12}>
              <div className="form_details_card">
                <p>Alernative number: </p>
                <h5>
                  {applicationDetailState?.alternativeContactNumber
                    ? applicationDetailState?.alternativeContactNumber
                    : "No Alernative number"}
                </h5>
              </div>
            </Grid>
            <Grid item md={3} xs={12}>
              <div className="form_details_card">
                <p>Eamil: </p>
                <h5>{applicationDetailState?.emailID}</h5>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={4} className="mt-2">
            <Grid item md={3} xs={12}>
              <div className="form_details_card">
                <p>DOB: </p>
                <h5>{applicationDetailState?.kinDOB}</h5>
              </div>
            </Grid>

            {/* <Grid item md={3} xs={12}>
              <div className="form_details_card">
                <p>Upload govt Id. </p>
                <h5>
                  {applicationDetailState?.verifcationType ===
                  IdentificationType.VOTER_ID
                    ? "Voter id"
                    : applicationDetailState?.verifcationType ===
                      IdentificationType.NIN_V2
                    ? "Nin Id"
                    : applicationDetailState?.verifcationType}
                </h5>
              </div>
            </Grid> */}
            <Grid item md={3} xs={12}>
              <div className="form_details_card">
                <p>Copy of the document: </p>
                <img
                  style={{
                    height: "100px",
                    width: "100px",
                  }}
                  src={replaceHttpImgUrl(applicationDetailState?.identitiy_doc)}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="mt-5 border-bottom pb-3">
          <h4>Employment details</h4>

          <Grid container spacing={4} className="mt-2">
            <Grid item md={3} xs={12}>
              <div className="form_details_card">
                <p>Employment status: </p>
                <h5>{applicationDetailState?.employmentStatus}</h5>
              </div>
            </Grid>
            {applicationDetailState?.employmentStatus != "unemployed" && (
              <>
                <Grid item md={3} xs={12}>
                  <div className="form_details_card">
                    <p>
                      {applicationDetailState?.employmentStatus ===
                      "self_employed"
                        ? "Business Name"
                        : "Employer Name:"}{" "}
                    </p>
                    <h5>{applicationDetailState?.employerName}</h5>
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  <div className="form_details_card">
                    <p>
                      {applicationDetailState?.employmentStatus ===
                      "self_employed"
                        ? "Business Address"
                        : "Employer Address"}
                      :{" "}
                    </p>
                    <h5>{applicationDetailState?.employerAddress}</h5>
                  </div>
                </Grid>
              </>
            )}
            <Grid item md={3} xs={12}>
              <div className="form_details_card">
                <p>Occupation: </p>
                <h5>{applicationDetailState?.occupation}</h5>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="mt-5 border-bottom pb-3">
          <h4>Kin details</h4>

          <Grid container spacing={4} className="mt-2">
            <Grid item md={3} xs={12}>
              <div className="form_details_card">
                <p>Name: </p>
                <h5>
                  {applicationDetailState?.kinFirstName +
                    " " +
                    applicationDetailState?.kinMiddleName +
                    " " +
                    applicationDetailState?.kinLastName}
                </h5>
              </div>
            </Grid>
            <Grid item md={3} xs={12}>
              <div className="form_details_card">
                <p>Contact Number: </p>
                <h5>{applicationDetailState?.kinContactNumber}</h5>
              </div>
            </Grid>
            <Grid item md={3} xs={12}>
              <div className="form_details_card">
                <p>Email: </p>
                <h5>{applicationDetailState?.kinEmail}</h5>
              </div>
            </Grid>
            <Grid item md={3} xs={12}>
              <div className="form_details_card">
                <p>Relation With Kin: </p>
                <h5>{applicationDetailState?.relationshipKin}</h5>
              </div>
            </Grid>
          </Grid>
        </div>
        {applicationDetailState?.no_of_occupant > 0 ||
        applicationDetailState?.coOccupentName ||
        applicationDetailState?.coOccupentContact ||
        applicationDetailState?.relationWithCoOccupent ? (
          <div className="mt-5 border-bottom pb-3">
            <h4>Co-Occupant</h4>

            <Grid container spacing={4} className="mt-2">
              {applicationDetailState?.no_of_occupant > 0 && (
                <Grid item md={3} xs={12}>
                  <div className="form_details_card">
                    <p>Number of occpant: </p>
                    <h5> {applicationDetailState?.no_of_occupant}</h5>
                  </div>
                </Grid>
              )}
              {applicationDetailState?.coOccupentName && (
                <Grid item md={3} xs={12}>
                  <div className="form_details_card">
                    <p>Name of the Major Occupant: </p>
                    <h5> {applicationDetailState?.coOccupentName}</h5>
                  </div>
                </Grid>
              )}
              {applicationDetailState?.coOccupentContact && (
                <Grid item md={3} xs={12}>
                  <div className="form_details_card">
                    <p> Occupant Contact Number: </p>
                    <h5> {applicationDetailState?.coOccupentContact}</h5>
                  </div>
                </Grid>
              )}
              {applicationDetailState?.relationWithCoOccupent && (
                <Grid item md={3} xs={12}>
                  <div className="form_details_card">
                    <p>Relation ship with Cooccupant: </p>
                    <h5> {applicationDetailState?.relationWithCoOccupent}</h5>
                  </div>
                </Grid>
              )}
            </Grid>
          </div>
        ) : null}
        <div className="mt-5 border-bottom pb-3">
          <h4>Move In information</h4>

          <Grid container spacing={4} className="mt-2">
            <Grid item md={3} xs={12}>
              <div className="form_details_card">
                <p>Move in date: </p>
                <h5> {applicationDetailState?.checkinDate}</h5>
              </div>
            </Grid>
            <Grid item md={3} xs={12}>
              <div className="form_details_card">
                <p>Move out date: </p>
                <h5> {applicationDetailState?.checkoutDate}</h5>
              </div>
            </Grid>
          </Grid>
        </div>
        {applicationDetailState?.businessName && (
          <div className="mt-5 border-bottom pb-3">
            <h4>Business Information</h4>

            <Grid container spacing={4} className="mt-2">
              <Grid item md={3} xs={12}>
                <div className="form_details_card">
                  <p>Name of Business: </p>
                  <h5> {applicationDetailState?.businessName}</h5>
                </div>
              </Grid>
              <Grid item md={3} xs={12}>
                <div className="form_details_card">
                  <p>Type of Business: </p>
                  <h5> {applicationDetailState?.businessType}</h5>
                </div>
              </Grid>
              <Grid item md={3} xs={12}>
                <div className="form_details_card">
                  <p>Number of Employees: </p>
                  <h5> {applicationDetailState?.totalEmployees}</h5>
                </div>
              </Grid>
              <Grid item md={3} xs={12}>
                <div className="form_details_card">
                  <p>Preferred Floor: </p>
                  <h5> {applicationDetailState?.preferredFloor}</h5>
                </div>
              </Grid>
            </Grid>
          </div>
        )}

        <Grid container spacing={4} className="mt-2">
          <Grid item md={6} xs={12}>
            <div className="address_block">
              <h3> Permanent Address</h3>
              <div className="form_details_card">
                <p>Employer Address: </p>
                <h5>{applicationDetailState?.permanentAddress}</h5>
              </div>
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <div className="address_block mb-4">
              <h3> Landlord Information</h3>
              <div className="row">
                <div className="form_details_card col-sm-6">
                  <p>Name: </p>
                  <h5>{applicationDetailState?.landlord_info?.fullName}</h5>
                </div>
                <div className="form_details_card col-sm-6">
                  <p>Previous Landlord Address: </p>
                  <h5>{applicationDetailState?.previousLandlordAddress}</h5>
                </div>
                <div className="form_details_card col-sm-6">
                  <p>Previous Landlord ContactNumber : </p>
                  <h5>{applicationDetailState?.previouLandloadContact}</h5>
                </div>
                <div className="form_details_card col-sm-6">
                  <p>Previous Landlord Reason to leave : </p>
                  <h5>{applicationDetailState?.previouReasonForLeaving}</h5>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        {applicationDetailState?.applicationStatus ===
          inspectionApplicationRequestStatus.pending && (
          <Grid container spacing={4} className="mt-2">
            <Grid item md={6} xs={12}>
              <div className="card_acpt_rjct">
                <h5>
                  Do you want to accept rent application of{" "}
                  {applicationDetailState?.firstName} for{" "}
                  {applicationDetailState?.propertyName}?
                </h5>
                <div className="btn_group">
                  <button
                    className="global-button"
                    onClick={() => setCancelModal(true)}
                  >
                    Reject
                  </button>
                  <ButtonComponent
                    className="global-button"
                    onClick={() => applicationReqAccept()}
                    title={"Accept"}
                    loading={btnLoader}
                    disabled={btnLoader}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        )}

        {location.state === "Renter" && (
          <>
            <div className="mt-5">
              <h4>Employement details</h4>

              <Grid container spacing={4} className="mt-2">
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="grouped-select">
                      Employement status
                    </InputLabel>
                    <Select
                      disabled
                      defaultValue={2}
                      placeholder="Select"
                      value={2}
                      id="grouped-select"
                      label="Employement status"
                    >
                      <MenuItem value="2">
                        <em>None</em>
                      </MenuItem>

                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}> 2</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    label="Employer's name"
                    placeholder="Enter employer's name"
                    fullWidth
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    label="Employer's address"
                    placeholder="Enter employer's address"
                    fullWidth
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="grouped-select">Occupation</InputLabel>
                    <Select
                      disabled
                      defaultValue={2}
                      placeholder="Select"
                      value={2}
                      id="grouped-select"
                      label="Occupation"
                    >
                      <MenuItem value="2">
                        <em>None</em>
                      </MenuItem>

                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}> 2</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>

            <div className="mt-5">
              <h4>Kin details</h4>

              <Grid container spacing={4} className="mt-2">
                <Grid item md={6}>
                  <TextField fullWidth label="Name" placeholder="Enter name" />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    label="Contact Number"
                    placeholder="Enter contact number"
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    placeholder="Enter email address"
                  />
                </Grid>
                <Grid item md={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="grouped-select">
                      Relationship with Kin
                    </InputLabel>
                    <Select
                      disabled
                      defaultValue={2}
                      placeholder="Select"
                      value={2}
                      id="grouped-select"
                      label="Relationship with Kin"
                    >
                      <MenuItem value="2">
                        <em>None</em>
                      </MenuItem>

                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}> 2</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <TextField
                    placeholder="Select"
                    fullWidth
                    label="Upload govt. id."
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            className="global-button"
                            size="small"
                            variant="contained"
                          >
                            upload
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </div>

            <div className="mt-5">
              <h4>Previous landlord information</h4>

              <Grid container spacing={4} className="mt-2">
                <Grid md={12} item>
                  <TextField
                    fullWidth
                    label="Name"
                    placeholder="Enter previous landlord's name"
                  />
                </Grid>
                <Grid md={12} item>
                  <TextField
                    fullWidth
                    label="Address"
                    placeholder="Enter previous landlord's address"
                  />
                </Grid>
              </Grid>
            </div>
          </>
        )}
        <LandlordCommonModal
          open={cancelModal}
          setOpen={setCancelModal}
          heading={"Reason for Rejection"}
          heading2={"Reason for Rejection"}
          detailData={{
            ...applicationDetailState,
            RenterDetails: {
              fullName: applicationDetailState?.firstName,
              picture: applicationDetailState?.renter_info?.picture,
            },
            property_info: [
              {
                propertyName:
                  applicationDetailState?.property_info?.propertyName,
              },
            ],
          }}
          apiNotCallOnCancel={setApiNotCallOnCancel}
          notificationText={`A notification has been sent to ${applicationDetailState?.firstName} regarding his Rent Application`}
        />
        <InspectionApplicationRequestModal
          open={acceptModal}
          setOpen={setAcceptModal}
          heading={"Request Accepted"}
          heading2={`You have accepted the Application request of ${
            applicationDetailState?.firstName
          } for “${
            applicationDetailState?.property_info?.propertyName
          }” on ${fDate(applicationDetailState?.checkinDate)}`}
          Customfunction={() => {
            navigate(-1);
          }}
        />
      </Box>
    </div>
  );
};

export default ApplicantDetails;
