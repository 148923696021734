import * as Yup from "yup";
import { IdentificationType, PROPERTY_CATEGORY } from "./CONSTANTS";

export const addPropertyvalidationSchema = Yup.object().shape({
  category: Yup.string().required("Category is required"),
  propertyName: Yup.string().required("Property name is required"),
  type: Yup.string().required("Type is required"),
  address: Yup.string().required("Address is required"),
  rent: Yup.string().required("Rent is required"),

  bedrooms: Yup.number().when("type", {
    is: (type) =>
      ["Open Space", "Shop", "Office Space", "Warehouse"].includes(type),
    then: () => Yup.number().optional(),
    otherwise: () => Yup.number().required("Number of bedrooms required"),
  }),
  rentType: Yup.string().required("Rent type is required"),
  number_of_floors: Yup.number().when("type", {
    is: (type) =>
      ["Open Space", "Shop", "Office Space", "Warehouse"].includes(type),
    then: () => Yup.number().optional(),
    otherwise: () => Yup.number().required("No of floors required"),
  }),
  number_of_bathrooms: Yup.number().when("type", {
    is: "Open Space",
    then: () => Yup.number().optional(),
    otherwise: () =>
      Yup.number().required("Number of Bathrooms/Toilet required"),
  }),
  carpetArea: Yup.string().required("Carpet area  is required"),
  servicing: Yup.string().when("category", {
    is: "commercial",
    then: () => Yup.string().optional(),
    otherwise: () => Yup.string().required("Select the servicing"),
  }),

  no_of_administrative_offices: Yup.string().when("type", {
    is: "Warehouse",
    then: () =>
      Yup.string().required("no of administrative offices is required"),
    otherwise: () => Yup.string().optional(),
  }),
  superArea: Yup.string().when("category", {
    is: "commercial",
    then: () => Yup.string().required("Super area is required is required"),
    otherwise: () => Yup.string().optional(),
  }),
  age_of_construction: Yup.string().required("Age of construction is required"),
  aboutProperty: Yup.string().required("About property is required"),
  furnishingType: Yup.string().required("Furnishing type is required"),
  availability: Yup.string().required("Availability is required"),
  communityType: Yup.string().when("category", {
    is: "commercial",
    then: () => Yup.string().optional(),
    otherwise: () => Yup.string().required("Select the communityType"),
  }),
  servicesCharges: Yup.string().when("servicing", {
    is: "Non Serviced/Fully Serviced",
    then: () => Yup.string().optional(),
    otherwise: () => Yup.string().required("service charge is required"),
  }),
  serviceType: Yup.string().when("servicing", {
    is: "Non Serviced/Fully Serviced",
    then: () => Yup.string().optional(),
    otherwise: () => Yup.string().required("service type is required"),
  }),
  buildNumber: Yup.string().required("Building Number is required"),
  streetName: Yup.string().required("Street name  is required"),
  estateName: Yup.string().required("Estate  name  is required"),
});

//requiredFields of add property
export const requiredFieldsAddProperty = [
  "category",
  "propertyName",
  "type",
  "address",
  "superArea",
  "carpetArea",
  "availability",
  "age_of_construction",
  "communityType",
  "aboutProperty",
  "number_of_bathrooms",
  "bedrooms",
  "number_of_floors",
  "estateName",
  "buildNumber",
  "streetName",
  "servicing",
  "no_of_toilet",
  "number_of_rooms",
  "no_of_administrative_offices",
  "furnishingType",
  "email",
];

// validation schema for the rent application
export const validationSchemaRentApp = Yup.object().shape({
  personalFirstName: Yup.string().required("First name is required"),
  personalLastName: Yup.string().required("Last name is required"),
  personalGender: Yup.string().required("Select the gender"),
  personalContactNumber: Yup.string().required("Contact number is required"),
  personalEmail: Yup.string()
    .email("Email is required")
    .required("Email is required"),
  employmentStatus: Yup.string().required("Employment Status is required"),
  employerName: Yup.string().when("employmentStatus", {
    is: "unemployed",
    then: () => Yup.string().optional(),
    otherwise: () => Yup.string().required("Field is required"),
  }),
  employerAddress: Yup.string().when("employmentStatus", {
    is: "unemployed",
    then: () => Yup.string().optional(),
    otherwise: () => Yup.string().required("Field is required"),
  }),
  occupation: Yup.string().required("Occupation is required"),
  kinFirstName: Yup.string().required("kin Name is required"),
  kinLastName: Yup.string().required("kin LastName is required"),
  identityType: Yup.string().required("Identity Type is required"),
  kinContactNumber: Yup.string().required("kin contact number is required"),
  kinEmail: Yup.string()
    .email("Enter valid kin email")
    .required("Kin email is required"),
  relationshipKin: Yup.string().required("Relationship with Kin is required"),

  checkinDate: Yup.string().required("Check inDate is required"),
  martialStatus: Yup.string().required("Marital Status is required"),
  rentNowPayLater: Yup.string().required("rentNowPayLater is required"),
  address: Yup.string().required("Permanent Address is required"),

  permanentZipcode: Yup.string().required("Permanent Zipcode is required"),
  previousLandlordAddress: Yup.string().required(
    "Previous Landlord Address is required"
  ),
  previousLandlordName: Yup.string().required(
    "Previous Landlord name is required"
  ),
  previousLandlordReasonToLeave: Yup.string().required(
    "Previous Landlord Reason to leave"
  ),
  previousLandlordContactNumber: Yup.string().required(
    "Previous LandlordContactNumber is required"
  ),
  preferredFloor: Yup.string().when("propertyCategory", {
    is: "commercial",
    then: () => Yup.string().required("Preferred Floor is required"),
    otherwise: () => Yup.string().optional(),
  }),
  nameofBusiness: Yup.string().when("propertyCategory", {
    is: "commercial",
    then: () => Yup.string().required("Name of Business is required"),
    otherwise: () => Yup.string().optional(),
  }),
  typeOfBusiness: Yup.string().when("propertyCategory", {
    is: "commercial",
    then: () => Yup.string().required("Type of business is required"),
    otherwise: () => Yup.string().optional(),
  }),
  numberOfEmployees: Yup.string().when("propertyCategory", {
    is: "commercial",
    then: () => Yup.string().required("Number of Employees is required"),
    otherwise: () => Yup.string().optional(),
  }),
  identitiy_doc: Yup.string().required("Upload the copy of your document"),
  bvn: Yup.string().when("identityType", {
    is: IdentificationType.BVN,
    then: () => Yup.string().required("Bvn number is requried"),
    otherwise: () => Yup.string().optional(),
  }),
  nin: Yup.string().when("identityType", {
    is: IdentificationType.NIN_V2,
    then: () => Yup.string().required("Nin id  is requried"),
    otherwise: () => Yup.string().optional(),
  }),
  voter_id: Yup.string().when("identityType", {
    is: IdentificationType.VOTER_ID,
    then: () => Yup.string().required("Voter id number is requried"),
    otherwise: () => Yup.string().optional(),
  }),
  expectedStays: Yup.string().when("propertyCategory", {
    is: PROPERTY_CATEGORY.shortStay,
    then: () => Yup.string().optional(),
    otherwise: () => Yup.string().required("Expected length is required"),
  }),
  expectedStaysDurationType: Yup.string().when("propertyCategory", {
    is: PROPERTY_CATEGORY.shortStay,
    then: () => Yup.string().optional(),
    otherwise: () => Yup.string().required("select the stay duration option"),
  }),
});

// subscription newsletter schema
export const validationSchemaNewsLetter = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Email is required"),
});

// validation schema of contact form

export const validationSchemaContact = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string()
    .required("Phone number is required")
    .min(10, "Phone number must be at least 10 digits")
    .matches(/^[0-9]+$/, "Phone number must be numeric"),
  company: Yup.string().required("Company name is required"),
  message: Yup.string().required("Message is required"),
});
