import {
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { LocationIcon } from "../../../assets/icons";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { theme } from "../../../assets/theme";
import ButtonComponent from "../../../components/Button";
import { MaintenanceRequest } from "../../../services/maintenanaceRequestServices";
import {
  contentMaintenance,
  maintenanceConcerns,
} from "../../../utils/CONSTANTS";
import { useLocation, useNavigate } from "react-router-dom";
import { GetRentProperties } from "../../../services/rentapplications.service";
import { API_Endpoints } from "../../../services/API_Endpoints";
import { useDispatch } from "react-redux";
import { maintainceRequestSlice } from "../../../redux/maintainceRequest/maintainceRequestSlice";
import SuccessFullySubmitModal from "../../../components/successFullySubmittedModal";

export default function SendRequestModal({
  open,
  setOpen,
  headingContent = contentMaintenance.sendRequest,
  propertyData = {},
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [submitSuccessModal, setSubmitSuccessModal] = React.useState(false);
  //main form state
  const [formData, setFormData] = React.useState({
    concern: {
      value: "",
      warning: false,
    },
    remark: {
      value: "",
      warning: false,
    },
    propertyDataRent: {
      value: "",
      warning: false,
    },
  });
  const [propertyRented, setPropertyRented] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  // onchange function to change the state of form
  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: {
        value: value,
        warning: value === "",
      },
    }));
  };

  // get the propertylist data from the backend
  const getThePropertyList = async () => {
    const getData = await GetRentProperties(
      API_Endpoints.rentApplications.GET_PROPERTIES
    );
    if (getData?.status) {
      // extract the property name and the property id to show in dropdown of property
      const data = getData?.data
        ?.filter((item) => item.rented)
        .map((item) => {
          return {
            label: item.propertyName,
            value: item._id,
          };
        });
      setPropertyRented(data);
    } else {
      console.log("getDta", getData);
    }
  };

  // validate the data
  const onSubmit = (e) => {
    e.preventDefault();
    let data = { ...formData };
    data = {
      ...data,
      concern: { ...data.concern, warning: data.concern.value === "" },
      remark: { ...data.remark, warning: data.remark.value === "" },
      propertyDataRent: {
        ...data.propertyDataRent,
        warning:
          headingContent == contentMaintenance.sendRequest &&
          data.propertyDataRent.value == "",
      },
    };
    setFormData({ ...data });
    if (
      !data.remark.warning &&
      !data.concern.warning &&
      !data.propertyDataRent.warning
    ) {
      onSubmitApiCall();
    }
  };

  // api call for submit the data to backend
  const onSubmitApiCall = async () => {
    setLoading(true);
    const body = {
      concern: formData.concern.value,
      propertyID: propertyData?._id || formData?.propertyDataRent?.value,
      renterRemark: formData.remark.value,
    };
    const postData = await MaintenanceRequest(body);
    if (postData?.status) {
      setSubmitSuccessModal(true);
      // setOpen(false);

      setFormData({
        concern: {
          value: "",
          warning: false,
        },
        remark: {
          value: "",
          warning: false,
        },
      });
      if (headingContent == contentMaintenance.sendRequest) {
        setOpen(false);
        dispatch(maintainceRequestSlice.actions.setTheModalStatus());
      }
    } else {
      console.log("eroorrrrrr", postData);
    }
    setLoading(false);
  };
  console.log("geteerer", submitSuccessModal);

  React.useEffect(() => {
    if (open && headingContent == contentMaintenance.sendRequest)
      getThePropertyList();
  }, [open]);
  console.log("propertyRented", propertyRented);
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography
          fontSize={24}
          fontWeight={700}
          color={theme.palette.text.primary}
          textAlign={"center"}
        >
          {headingContent}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {headingContent === contentMaintenance.maintenanceRequest ? (
          <Card
            elevation={0}
            sx={{ width: "100%", border: "1px solid #dbdbdb", borderRadius: 3 }}
            className="p-3 mb-3"
          >
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-center align-items-center gap-2">
                <div>
                  <Typography fontSize={24} color="#031013" fontWeight={"bold"}>
                    {propertyData?.address?.addressText}
                  </Typography>
                  <div className="d-flex gap-1">
                    <LocationIcon />
                    <Typography color="#8D9B9F" fontWeight={200}>
                      {propertyData?.propertyName}
                    </Typography>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </Card>
        ) : null}
        <FormControl style={{ width: "100%", marginBottom: "10px" }}>
          <InputLabel style={{background:"#fff"}}>Please select concern</InputLabel>
          <Select
            value={formData?.concern?.value}
            name="concern"
            onChange={onChange}
          >
            {maintenanceConcerns &&
              maintenanceConcerns.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
          </Select>
          {formData?.concern?.warning && (
            <span className="text-danger">Select concern</span>
          )}
        </FormControl>
        {headingContent == contentMaintenance.sendRequest && (
          <FormControl style={{ width: "100%", marginBottom: "10px" }}>
            <InputLabel>Please select Property</InputLabel>
            <Select
              value={formData?.propertyDataRent?.value}
              name="propertyDataRent"
              onChange={onChange}
            >
              {propertyRented && propertyRented.length === 0 ? (
                <MenuItem value={""}>No Rented Property found</MenuItem>
              ) : (
                propertyRented.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))
              )}
            </Select>
            {formData?.propertyDataRent?.warning && (
              <span className="text-danger">Select Property</span>
            )}
          </FormControl>
        )}
        <label style={{ fontSize: "14px", fontWeight: "600" }}>
          Enter your remark
        </label>
        <TextareaAutosize
          style={{ width: "100%", marginBottom: "10px" }}
          size="lg"
          placeholder="Write remark"
          minRows={3}
          className="p1"
          value={formData?.remark?.value}
          name="remark"
          onChange={onChange}
        />
        {formData?.remark?.warning && (
          <span className="text-danger">Enter the remark</span>
        )}
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <ButtonComponent
          onClick={(e) => onSubmit(e)}
          title={"Send Request"}
          sx={{ marginBottom: "20px" }}
          loading={loading}
          // onClick={onSubmit}
        />
      </DialogActions>
      <SuccessFullySubmitModal
        open={submitSuccessModal}
        headingAndText={{
          mainHeading: "Submit Maintenance Request",
          middleText: "You request has been successfully submitted",
        }}
        setOpen={setSubmitSuccessModal}
        onCLick={() => {
          if (location?.pathname?.includes("/rented-property-detail")) {
            navigate(-1);
          } else if (
            location?.pathname == "/rented-properties" ||
            location?.pathname == "/maintenance-requests"
          ) {
            setOpen(false);
            setSubmitSuccessModal(false);
          }
        }}
      />
    </Dialog>
  );
}
