import UserNotifications from "../pages/notifications";
import PaymentMethod from "../pages/payment-method/index";
import Wallet from "../pages/wallet";
import ReceipetDetails from "../pages/wallet/receipetDetails";
import { ROLES } from "./CONSTANTS";

import MaintainceRequestPage from "../pages/common/maintaince-requests";
import MyRenters from "../pages/common/my-renters";
import RenterDetail from "../pages/common/my-renters/renterDetail";
import LandlordLandingPage from "../pages/landlord";

import ApplcationRecieved from "../pages/common/application-recieved";
import InspectionRequest from "../pages/common/inspection-request";
import InspectionDetail from "../pages/common/inspection-request/inspectionDetail";
import InspectionPropertyRequest from "../pages/common/inspection-request/inspectionPropertyRequest";
import PropertyManager from "../pages/landlord/property-manager";

import MaintainceDeatil from "../pages/common/maintaince-requests/maintainceDeatil";
import RentTransactions from "../pages/common/rent-transactions";

import Chat from "../pages/common/chat";
import ReferAndEarnLandlordPM from "../pages/landlord/refer-earn";

import Commission from "../pages/property-manager/commission";

import ManageCalender from "../pages/common/manage-calender";

import RentInsurancePolicy from "../pages/insurance-policy";
import RenterLandingPage from "../pages/renter";
import ELectiricityRecharge from "../pages/renter/electricity-recharge";
import Favourites from "../pages/renter/favourites";
import FavouriteDetail from "../pages/renter/favourites/favouriteDetail";
import UserProfile from "../pages/user-profile";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { options } from "../components/stripePopup/stripeCss";
import BlogDetail from "../pages/Blogs/detail";
import ApplicantDetails from "../pages/common/application-recieved/applicant-details";
import ApplicationPropertyRequest from "../pages/common/application-recieved/applicationPropertyRequest";
import LeaseAgrement from "../pages/common/lease-agrement";
import ManagerDetails from "../pages/landlord/property-manager/manager-details";
import MaintainceRequest from "../pages/renter/maintaince-request";
import MaintainceRequestDetails from "../pages/renter/maintaince-request/maintainceRequestDetail";
import MyInspections from "../pages/renter/my-inspections";
import PropertiesInDemand from "../pages/renter/properties-in-demand";
import RentApplications from "../pages/renter/rent-application";
import EditRentApplication from "../pages/renter/rent-application/editRentApplication";
import RentedProperties from "../pages/renter/rentedProperties";
import RentedPropertyDetail from "../pages/renter/rentedProperties/detail";
import Support from "../pages/renter/suppport";
import TransactionHistory from "../pages/renter/transaction-history";
import TransactionDetail from "../pages/renter/transaction-history/transactionDetail";
import { AddProperty } from "../routes/adminRoutes";
import { paymentGateWayKey } from "./keys";
const stripePromise = loadStripe(paymentGateWayKey?.stripe);

export const routesChildren = [
  {
    element: <BlogDetail />,
    path: "blog-detail",
    role: [ROLES.landlord, ROLES.propertyManager, ROLES.renter],
  },
  
  {
    element: <ReferAndEarnLandlordPM />,
    path: "refer-and-earn-page",
    role: [ROLES.landlord, ROLES.propertyManager, ROLES.renter],
  },
  {
    element: <ApplicantDetails />,
    path: "applicant-details/:id",
    role: [ROLES.landlord, ROLES.propertyManager, ROLES.renter],
  },

  {
    element: <RenterLandingPage />,
    path: "renter",
    role: [ROLES.renter],
  },
  {
    element: <MyInspections />,
    path: "my-inspections",
    role: [ROLES.renter],
  },
  {
    element: <RentApplications />,
    path: "rent-applications",
    role: [ROLES.renter],
  },
  {
    element: <EditRentApplication />,
    path: "rent-applications-update",
    role: [ROLES.renter],
  },
  {
    element: <EditRentApplication />,
    path: "rent-applications-edit/:id",
    role: [ROLES.renter],
  },
  {
    element: <PropertiesInDemand />,
    path: "properties-in-demand",
    role: [ROLES.renter],
  },

  {
    element: <RentedProperties />,
    path: "rented-properties",
    role: [ROLES.landlord, ROLES.propertyManager, ROLES.renter],
  },
  {
    element: <RentedProperties />,
    path: "listed-properties",
    role: [ROLES.landlord, ROLES.propertyManager, ROLES.renter],
  },
  {
    element: <RentedProperties />,
    path: "vacant-properties",
    role: [ROLES.landlord, ROLES.propertyManager, ROLES.renter],
  },
  {
    element: <RentedPropertyDetail />,
    path: "rented-property-detail/:id",
    role: [ROLES.landlord, ROLES.propertyManager, ROLES.renter],
  },
  {
    element: <TransactionHistory />,
    path: "transaction-history",
    role: [ROLES.renter],
  },
  {
    element: <TransactionDetail />,
    path: "transaction-history-Detail/:id",
    role: [ROLES.landlord, ROLES.propertyManager, ROLES.renter],
  },
  {
    element: <Favourites />,
    path: "favourite",
    role: [ROLES.renter],
  },
  {
    element: <FavouriteDetail />,
    path: "favourite-detail",
    role: [ROLES.renter],
  },
  {
    element: <LeaseAgrement />,
    path: "lease-agreement",
    role: [ROLES.landlord, ROLES.propertyManager, ROLES.renter],
  },
  {
    element: <MaintainceRequest />,
    path: "maintenance-requests",
    role: [ROLES.landlord, ROLES.propertyManager, ROLES.renter],
  },
  {
    element: <MaintainceRequestDetails />,
    path: "maintenance-requests-details",
    role: [ROLES.landlord, ROLES.propertyManager, ROLES.renter],
  },
  {
    element: <ELectiricityRecharge />,
    path: "electricity-recharge",
    role: [ROLES.renter],
  },
  {
    element: <Wallet />,
    path: "/wallet",
    role: [ROLES.landlord, ROLES.propertyManager, ROLES.renter],
  },
  {
    element: <ReceipetDetails />,
    path: "/top-up-wallet",
    role: [ROLES.landlord, ROLES.propertyManager, ROLES.renter],
  },
  {
    element: (
      <Elements stripe={stripePromise} options={options}>
        <PaymentMethod />
      </Elements>
    ),
    path: "/payment-method",
    role: [ROLES.landlord, ROLES.propertyManager, ROLES.renter],
  },
  {
    element: <UserNotifications />,
    path: "/notifications",
    role: [ROLES.landlord, ROLES.propertyManager, ROLES.renter],
  },
 
  {
    element: <ApplcationRecieved />,
    path: "/applications-recieved",
  },
  {
    element: <PropertyManager />,
    path: "/property-managers",
    role: [ROLES.landlord],
  },
  {
    element: <ManagerDetails />,
    path: "/manager-details/:id",
    role: [ROLES.landlord],
  },
  {
    element: <LandlordLandingPage />,
    path: "landlord-dashboard",
    role: [ROLES.landlord],
  },
  {
    element: <AddProperty />,
    path: "add-property",
    role: [ROLES.landlord, ROLES.propertyManager],
  },
  {
    element: <AddProperty />,
    path: "/edit-property/:id",
    role: [ROLES.landlord, ROLES.propertyManager],
  },
  {
    element: <MyRenters />,
    path: "my-renters",
    role: [ROLES.landlord, ROLES.propertyManager],
  },
  {
    element: <RenterDetail />,
    path: "my-renters-detail/:id",
    role: [ROLES.landlord, ROLES.propertyManager],
  },
  {
    element: <MaintainceRequestPage />,
    path: "maintenance-request",
    role: [ROLES.landlord, ROLES.propertyManager],
  },
  {
    element: <MaintainceDeatil />,
    path: "maintenance-request-detail",
    role: [ROLES.landlord, ROLES.propertyManager],
  },
  {
    element: <TransactionHistory />,
    path: "rent-transactions-history",
    role: [ROLES.renter, ROLES.landlord, ROLES.propertyManager],
  },
  {
    element: <RentTransactions />,
    path: "rent-transaction",
    role: [ROLES.landlord, ROLES.propertyManager],
  },
  {
    element: <RentedProperties />,
    path: "my-properties",
    role: [ROLES.landlord, ROLES.propertyManager],
  },
  {
    element: <InspectionRequest />,
    path: "inspection-requests",
    role: [ROLES.landlord, ROLES.propertyManager],
  },
  {
    element: <InspectionDetail />,
    path: "inspection-requests-detail/:id",
    role: [ROLES.landlord, ROLES.propertyManager],
  },
  {
    element: <InspectionPropertyRequest />,
    path: "inspection-requests-property/:id",
    role: [ROLES.landlord, ROLES.propertyManager],
  },

  {
    element: <ApplicationPropertyRequest />,
    path: "application-requests-property/:id",
    role: [ROLES.landlord, ROLES.propertyManager],
  },

  {
    element: <LandlordLandingPage />,
    path: "property-manager",
    role: [ROLES.landlord, ROLES.propertyManager],
  },

  {
    element: <Chat />,
    path: "chat",
    role: [ROLES.landlord, ROLES.propertyManager, ROLES.renter],
  },
  {
    element: <ManageCalender />,
    path: "manage-calender",
    role: [ROLES.landlord, ROLES.propertyManager],
  },
  {
    element: <Commission />,
    path: "commission",
    role: [ROLES.propertyManager],
  },


  {
    element: <RentInsurancePolicy />,
    path: "rent-insurance-policy",
    role: [ROLES.landlord, ROLES.propertyManager, ROLES.renter],
  },

  {
    element: <UserProfile />,
    path: "/profile",
    role: [ROLES.landlord, ROLES.propertyManager, ROLES.renter],
  },
];
