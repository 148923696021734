import { Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getAdminPrivacy } from "../../services/admin/adminCmsServices";
import Loader from "../../components/loader";

const PrivacyPolicy = () => {
  //  local state
  const [localState, setLocalState] = useState({
    data: null,
    loading: false,
  });
  // get api data
  const getPrivacyPolicyData = async () => {
    setLocalState((prev) => ({
      ...prev,
      loading: true,
    }));
    const res = await getAdminPrivacy("privacy-policy");
    if (res?.status) {
      setLocalState((prev) => ({
        ...prev,
        data: res?.data,
        loading: false,
      }));
    }
  };
  // effect for get api data

  useEffect(() => {
    getPrivacyPolicyData();
  }, []);
  // show loading

  if (localState.loading) {
    return <Loader />;
  }
  return (
    <Container maxWidth="xl">
      <div className="my-5">
        <Typography color="text.secondary" fontSize={18} fontWeight={500}>
          RENTRANZACT PRIVACY POLICY
        </Typography>
        <Typography
          // color="text.secondary"
          // fontSize={16}
          // fontWeight={500}
          mt={3}
          dangerouslySetInnerHTML={{
            __html: localState?.data && localState?.data?.content,
          }}
        />
        {/*static content */}
        <div className="d-none">
          <Typography
            color="text.secondary"
            fontSize={18}
            fontWeight={500}
            className="mt-4"
          >
            1. DEFINITION AND INTERPRETATION
          </Typography>
          <Typography
            color="text.secondary"
            fontSize={16}
            fontWeight={500}
            mt={1}
          >
            In these Terms, unless the context otherwise requires, the following
            expressions shall have the following meanings:
          </Typography>
          <ul className="mt-3">
            <Typography fontSize={16} fontWeight={500} component={"li"}>
              "Applicable Laws"
            </Typography>
            <Typography>
              means the constitution of the Federal Republic of Nigeria, any
              national, provincial, state or local law, statute, bye-law,
              ordinance, decree, directive, regulation, standard, circular,
              guideline, rule, code, delegated or subordinated legislation,
              judicial act or decision, judgment, order, proclamation,
              directive, executive order, other legislative measure, binding
              actions or enactments of the Federal Republic of Nigeria or any
              other government authority in Nigeria;
            </Typography>
            <Typography fontSize={16} fontWeight={500} component={"li"} mt={2}>
              "Personal Data"
            </Typography>
            <Typography>
              means any information relating to an identified or identifiable
              natural person, including personal data or information as defined
              by the Applicable Laws;
            </Typography>
            <Typography fontSize={16} fontWeight={500} component={"li"} mt={2}>
              "Intellectual Property Rights"
            </Typography>
            <Typography>
              means patents, utility models, rights to inventions, copyright and
              neighbouring and related rights, moral rights, trademarks and
              service marks, business names and domain names, rights in get-up
              and trade dress, goodwill and the right to sue for passing off or
              unfair competition, rights in designs, rights in computer
              software, database rights, rights to use, and protect the
              confidentiality of, confidential information (including know-how
              and trade secrets), and all other intellectual property rights, in
              each case whether registered or unregistered and including all
              applications and rights to apply for and be granted, renewals or
              extensions of, and rights to claim priority from, such rights and
              all similar or equivalent rights or forms of protection which
              subsist or will subsist now or in the future in any part of the
              world;
            </Typography>
            <Typography fontSize={16} fontWeight={500} component={"li"} mt={2}>
              "Professional Use"
            </Typography>
            <Typography>
              has the meaning giving in clause 5 of these Terms;
            </Typography>
            <Typography fontSize={16} fontWeight={500} component={"li"} mt={2}>
              "Professional User"
            </Typography>
            <Typography>
              means a User who accesses the Platform for Professional Use;
            </Typography>
            <Typography fontSize={16} fontWeight={500} component={"li"} mt={2}>
              "Services"
            </Typography>
            <Typography>
              means the services offered on the Platform including: property
              listing, streamlined rent collection, rent loans, caution deposit
              escrow services, in-app wallet services etc.
            </Typography>
            <Typography fontSize={16} fontWeight={500} component={"li"} mt={2}>
              "User(s)"
            </Typography>
            <Typography>
              means anyone who accesses the Platform and participates in a
              transaction on the Platform, and this includes Professional Users.
              References to “you”, “your” and “yours” should all be read as
              referring to a User; and
            </Typography>
            <Typography fontSize={16} fontWeight={500} component={"li"} mt={2}>
              "User Material"
            </Typography>
            <Typography>
              has the meaning given in Clause 7 of these Terms.
            </Typography>
          </ul>

          <Typography
            color="text.secondary"
            fontSize={18}
            fontWeight={500}
            className="mt-4"
          >
            2. RENTRANZACT'S ROLE
          </Typography>
          <Typography mt={1}>
            Rentranzact, and the Services, may assist you in performing the
            various tasks in a real estate transaction. However, unless
            explicitly specified by this Terms, THE SERVICES ARE NOT INTENDED TO
            provide you with any financial, real estate, or related advice of
            any kind. [You understand and agree that the Services may include
            advertisements. To help make the advertisements relevant and useful
            to you, Rentranzact may serve advertisements based on the
            information we collect through the Services. See our Privacy Policy
            for more details.] <br />
            Rentranzact assumes no responsibility for any result or consequence
            related directly or indirectly to any action or inaction that you or
            any User takes based on the Services or any other information
            available through or in connection with the Services.
          </Typography>

          <Typography
            color="text.secondary"
            fontSize={18}
            fontWeight={500}
            className="mt-4"
          >
            3. ACCOUNT AND SITE USE
          </Typography>
          <Typography mt={3}>
            To be eligible to use the Platform and the Services you must be at
            least 18 years old. By agreeing to these Terms, you represent and
            warrant that:
          </Typography>
          <Typography mt={3}>(a) you are at least 18 years of age;</Typography>
          <Typography mt={3}>
            (b) you have not previously been suspended or removed from the
            Platform;
          </Typography>
          <Typography mt={3}>
            (c) the information you provide for the registration of an account
            with Rentranzact is accurate and that you will keep it accurate and
            up-to-date at all times;
          </Typography>
          <Typography mt={3}>
            (d) your personal data can be collected to process your request;
          </Typography>
          <Typography mt={3}>
            (e) you will not access this platform through automated or non-human
            means, whether through script, bot or otherwise;
          </Typography>
          <Typography mt={3}>
            (f) you agree to keep your password and Username confidential and
            shall be responsible for all use of your account;
          </Typography>
          <Typography mt={3}>
            (g) you will not use this Platform for any illegal or any
            unauthorized purposes;
          </Typography>
          <Typography mt={3}>
            (h) your registration and your use of the Platform and the Services
            complies with all Applicable Laws and;
          </Typography>
          <Typography mt={3}>
            (i) If you provide any information that is inaccurate, untrue,
            invalid, or incomplete, we reserve the right to suspend or terminate
            your account and refuse any current or future use of this Platform.
          </Typography>

          <Typography mt={4}>
            You will be able to access some parts of thePlatform without having
            to register any details with us. However, to view the full contents
            of this Platform or access some Services on the Platform you must
            register and agree to the terms and conditions of the particular
            Services or any other applicable terms and conditions, which may be
            incorporated herein or made available on a separate Rentranzact
            site. When you register for an account, you will be asked to provide
            us with some information about yourself, such as email address,
            phone number, National Identificationor other contact information .
            When you register, please read our Privacy Policy. You agree to keep
            your password confidential and will be responsible for all use of
            your account and password. We reserve the right to remove, reclaim
            or change a username you select if we determine in our sole
            discretion that such username is inappropriate, obscene, or
            objectionable. No username or password shall be transferred or used
            by anyone except by the person for whom they were issued. You are
            solely responsible for maintaining the confidentiality of your
            account and password, you will be responsible for all use under your
            account, including the unauthorised use of any username or password.
            You agree to promptly update the login details or password on your
            accounts, if you become aware or reasonably suspect any security
            breach, including any loss, theft, or unauthorized disclosure or use
            of your access login or password.
          </Typography>
        </div>
      </div>
    </Container>
  );
};

export default PrivacyPolicy;
