import {
  Box,
  Button,
  Card,
  Chip,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "../../../assets/theme";
import "./index.css";
import SendRequestModal from "./sendRequestModal";
import { useNavigate } from "react-router-dom";
import { getMaintenanceRequest } from "../../../services/maintenanaceRequestServices";
import Loader from "../../../components/loader";
import { fDate } from "../../../utils/format-time";
import { useDispatch, useSelector } from "react-redux";
import { maintainceRequestSlice } from "../../../redux/maintainceRequest/maintainceRequestSlice";
export default function MaintainceRequest() {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { modalStatus } = useSelector((state) => state.MAINTAINCEREQUEST)
  const [maintenanceData, setMaintenanceData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filters = [
    { label: "Pending", value: "pending" },
    { label: "Resolved", value: "resolved" },
    { label: "Remarked", value: "remarked" },
  ];
  const [filterValue, setFilterValue] = useState("pending");

  //get the mainteance request data from the backend
  const getTheDataOfRequest = async () => {
    try {
      setLoading(true);
      const getData = await getMaintenanceRequest(filterValue);
      console.log("getDtaa", getData);
      if (getData?.status) {
        setMaintenanceData(getData?.data);
      }
    } catch (error) {
      console.log("expevctional error", error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getTheDataOfRequest();
  }, [filterValue,modalStatus]);
  return (
    <Container maxWidth="xl" className="mt-3">
      {loading ? (
        <Loader />
      ) : (
        <div className="py-4">
          <Box>
            <Typography
              className="phone-heading-30"
              fontSize={40}
              fontWeight={700}
              color={theme.palette.text.primary}
            >
              Maintenance Requests
            </Typography>
          </Box>
          <Box textAlign={"right"}>
            <Button onClick={() => setModal(true)}>
              <Typography
                className="send_new_request"
                fontSize={24}
                fontWeight={700}
                color={"#C8D736"}
                marginBottom={"20px"}
              >
                Send New Request
              </Typography>
            </Button>
          </Box>
          <Grid
            container
            className="d-flex justify-content-between align-items-center"
          >
            <Grid item md={8}></Grid>
            <Grid
              item
              md={4}
              textAlign={"right"}
              className="d-flex justify-content-end align-items-center gap-2"
            >
              {filters.map((x, i) => {
                return (
                  <div
                    onClick={() => {
                      setFilterValue(x.value);
                    }}
                    key={i}
                    className={
                      x.value === filterValue
                        ? "chipdesgin chipdesginActive"
                        : "chipdesgin"
                    }
                  >
                    {x?.label}
                  </div>
                );
              })}
            </Grid>
          </Grid>

          {maintenanceData?.length === 0 ? (
            <h2>No Maintenance Request found</h2>
          ) : (
            maintenanceData.map((item) => (
              <Card
                elevation={0}
                className="mt-3 p-3"
                sx={{
                  width: "100%",
                  borderRadius: 3,
                  boxShadow: "5px 5px 19px 4px #0000000d",
                }}
              >
                <Grid
                  container
                  marginBottom={"20px"}
                  onClick={() => {
                    navigate("/maintenance-requests-details");
                    dispatch(
                      maintainceRequestSlice.actions.maintainceRequestDetail(
                        item
                      )
                    );
                  }}
                  sx={{ cursor: "pointer" }}
                  className="maintaince_request"
                >
                  <Grid item md={10}>
                    <Typography
                      fontSize={18}
                      fontWeight={700}
                      color={theme.palette.text.primary}
                      marginBottom={"16px"}
                    >
                      {item?.concern}
                    </Typography>
                    <Typography
                      fontSize={16}
                      fontWeight={700}
                      color={theme.palette.text.primary}
                      marginBottom={"10px"}
                    >
                      Your remark
                    </Typography>
                    <Typography
                      fontSize={16}
                      fontWeight={400}
                      color={"#395158"}
                      marginBottom={"15px"}
                    >
                      {item?.renterRemark}
                    </Typography>
                    {/* this is optional if landlord add their remark then it will see on front-end */}
                    {item?.landlordRemark ? (
                      <>
                        {" "}
                        <Typography
                          fontSize={16}
                          fontWeight={700}
                          color={theme.palette.text.primary}
                          marginBottom={"10px"}
                        >
                          Landlord remark
                        </Typography>
                        <Typography
                          fontSize={16}
                          fontWeight={400}
                          color={"#395158"}
                          marginBottom={"15px"}
                        >
                          {item?.landlordRemark}
                        </Typography>{" "}
                      </>
                    ) : null}
                    <Typography
                      fontSize={14}
                      fontWeight={400}
                      color={"#395158"}
                      marginBottom={"20px"}
                    >
                      {fDate(item?.createdAt)}
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    {item?.status == "pending" ? (
                      <Typography
                        fontSize={18}
                        fontWeight={700}
                        color={"#C8D736"}
                        marginBottom={"20px"}
                        textAlign={"right"}
                      >
                        OPEN
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
              </Card>
            ))
          )}
        </div>
      )}
      {modal && <SendRequestModal open={modal} setOpen={setModal} />}
    </Container>
  );
}
