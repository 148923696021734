import {
  Box,
  Button,
  Collapse,
  Container,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../components/Button";
import CollapseWithTitle from "../../components/Collapse";
import {
  getProfileDetailOfUser,
  uploadImage,
  uploadImageOfUser,
  userProfileUpdate,
} from "../../services/profileServices";
import { RetrieveLocalUser } from "../../utils/authService";
import {
  IdentificationType,
  ROLES,
  employementStatus,
  kinIdentityType,
  martialStatusArr,
} from "../../utils/CONSTANTS";
import {
  onChangeToNumber,
  validateEmail,
} from "../../utils/helperFunctionForValidte";
import dayjs from "dayjs";

const UserProfile = () => {
  const navigate = useNavigate();
  const user = RetrieveLocalUser("user");
  const [collapsePersonalInformation, setCollapsePersonalInformation] =
    useState(true);
  const [addressInformationCollapse, setAddressInformationCollapse] =
    useState(true);
  const [employementDetailsCollapse, setEmployementDetailsCollapse] =
    useState(true);
  const [kinDetailsCollapse, setKinDetailsCollapse] = useState(true);
  const [landlordCollapse, setLandlordCollapse] = useState(true);
  const [propertiesCollapse, setPropertiesCollapse] = useState(true);
  const [loader, setLoader] = useState({
    phonebtnLoader: false,
    updatebtnLoader: false,
  });
  const [userDetail, setUserDetail] = useState({
    email: { value: "", warning: false },
    password: { value: "", warning: false },
    role: "",
    fullName: { value: "", warning: false },
    phone: { value: "", warning: false },
    countryCode: { value: "", warning: false },
    referralCode: { value: "", warning: false },
    status: false,
    verified: false,
    picture: { value: "", warning: false },
    martialStatus: { value: "", warning: false },
    age: { value: "", warning: false },
    permanentAddress: { value: "", warning: false },
    permanentCity: { value: "", warning: false },
    permanentState: { value: "", warning: false },
    permanentZipcode: { value: "", warning: false },
    kinFirstName: { value: "", warning: false },
    kinLastName: { value: "", warning: false },
    kinMiddleName: { value: "", warning: false },
    bvn: { value: "", warning: false },
    kindob: { value: new Date(), warning: false },
    nin: { value: "", warning: false },
    voter_id: { value: "", warning: false },
    kinContactNumber: { value: "", warning: false },
    kinEmail: { value: "", warning: false },
    relationshipKin: { value: "", warning: false },
    identificationType: { value: "", warning: false },
    employmentStatus: { value: "", warning: false },
    employerName: { value: "", warning: false },
    employerAddress: { value: "", warning: false },
    occupation: { value: "", warning: false },
    editableField: true,
  });

  //@@ GET THE USER PROFILE DETAIL AS PER THE BACKEND
  const getTheUserData = async () => {
    try {
      const getData = await getProfileDetailOfUser();
      console.log("userDteail", getData?.data);
      if (getData?.status) {
        setUserDetail((prev) => ({
          ...prev,
          email: { ...prev.email, value: getData?.data?.email },
          role: getData?.data?.role,
          fullName: { ...prev.fullName, value: getData?.data?.fullName },
          password: { ...prev.password, value: getData?.data?.password },
          phone: { ...prev.phone, value: getData?.data?.phone },
          countryCode: {
            ...prev.countryCode,
            value: getData?.data?.countryCode,
          },
          picture: { ...prev.picture, value: getData?.data?.picture },
          permanentAddress: {
            ...prev.permanentAddress,
            value:
              getData?.data?.permanentAddress?.permanentAddress === undefined
                ? ""
                : getData?.data?.permanentAddress?.permanentAddress,
          },
          permanentState: {
            ...prev.permanentState,
            value:
              getData?.data?.permanentAddress?.permanentState === undefined
                ? ""
                : getData?.data?.permanentAddress?.permanentState,
          },
          permanentCity: {
            ...prev.permanentCity,
            value:
              getData?.data?.permanentAddress?.permanentCity === undefined
                ? ""
                : getData?.data?.permanentAddress?.permanentCity,
          },

          permanentZipcode: {
            ...prev.permanentZipcode,
            value:
              getData?.data?.permanentAddress?.permanentZipcode === undefined
                ? ""
                : getData?.data?.permanentAddress?.permanentZipcode,
          },
          age: {
            ...prev.age,
            value: getData?.data?.age,
          },
          martialStatus: {
            ...prev.martialStatus,
            value:
              getData?.data?.maritialStatus == undefined
                ? ""
                : getData?.data?.maritialStatus,
          },
          ...(user?.role == ROLES.renter
            ? {
                kinContactNumber: {
                  ...prev.kinContactNumber,
                  value: getData?.data?.kinDetails?.kinContactNumber,
                },
                kindob: {
                  ...prev.kindob,
                  value: getData?.data?.kinDetails?.kindob,
                },
                kinFirstName: {
                  ...prev.kinFirstName,
                  value: getData?.data?.kinDetails?.first_name,
                },
                kinLastName: {
                  ...prev.kinLastName,
                  value: getData?.data?.kinDetails?.last_name,
                },
                kinMiddleName: {
                  ...prev.kinMiddleName,
                  value: getData?.data?.kinDetails?.middle_name,
                },
                kinEmail: {
                  ...prev.kinEmail,
                  value: getData?.data?.kinDetails?.kinEmail,
                },
                identificationType: {
                  ...prev.identificationType,
                  value: getData?.data?.kinDetails?.identificationType,
                },
                bvn: {
                  ...prev.bvn,
                  value: getData?.data?.kinDetails?.bvn,
                },
                nin: {
                  ...prev.nin,
                  value: getData?.data?.kinDetails?.nin,
                },
                voter_id: {
                  ...prev.voter_id,
                  value: getData?.data?.kinDetails?.voter_id,
                },
                relationshipKin: {
                  ...prev.relationshipKin,
                  value: getData?.data?.kinDetails?.relationshipKin,
                },
                employmentStatus: {
                  ...prev.employmentStatus,
                  value: getData?.data?.employmentDetails?.employmentStatus,
                },
                employerAddress: {
                  ...prev.employerAddress,
                  value: getData?.data?.employmentDetails?.employerAddress,
                },
                employerName: {
                  ...prev.employerName,
                  value: getData?.data?.employmentDetails?.employerName,
                },
                occupation: {
                  ...prev.occupation,
                  value: getData?.data?.employmentDetails?.occupation,
                },
              }
            : {}),
        }));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // onchange function
  const onChange = (e) => {
    const { name, value } = e.target;
    // onchange validation check
    setUserDetail((prev) => ({
      ...prev,
      [name]: {
        value:
          name == "permanentZipcode" ||
          name == "age" ||
          name == "phone" ||
          name == "kinContactNumber"
            ? onChangeToNumber(value)
            : value,
        warning: name == "email" ? validateEmail(value) : value === "",
      },
    }));
  };

  // validate the data of form
  const validateForm = async (e) => {
    let data = { ...userDetail };
    data = {
      ...data,
      email: {
        ...data.email,
        warning: validateEmail(data.email.value),
      },
      phone: {
        ...data.phone,
        warning: data.phone.value == "",
      },
      fullName: {
        ...data.fullName,
        warning: data.fullName.value == "",
      },
      martialStatus: {
        ...data.martialStatus,
        warning: data.martialStatus.value == "",
      },
      age: {
        ...data.age,
        warning: data.age.value == "",
      },
      permanentAddress: {
        ...data.permanentAddress,
        warning: data.permanentAddress.value == "",
      },
      permanentState: {
        ...data.permanentState,
        warning: data.permanentState.value == "",
      },
      permanentCity: {
        ...data.permanentCity,
        warning: data.permanentCity.value == "",
      },

      permanentZipcode: {
        ...data.permanentZipcode,
        warning: data.permanentZipcode.value == "",
      },
      ...(user?.role === ROLES.renter
        ? {
            kinFirstName: {
              ...data.kinFirstName,
              warning: data.kinFirstName.value == "",
            },
            kinLastName: {
              ...data.kinFirstName,
              warning: data.kinLastName.value == "",
            },
            kinEmail: {
              ...data.kinEmail,
              warning: validateEmail(data.kinEmail.value),
            },
            kinContactNumber: {
              ...data.kinContactNumber,
              warning: data.kinContactNumber.value == "",
            },
            relationshipKin: {
              ...data.relationshipKin,
              warning: data.relationshipKin.value == "",
            },
            identificationType: {
              ...data.identificationType,
              warning: data.identificationType.value == "",
            },
            bvn: {
              ...data.bvn,
              warning:
                data.identificationType?.value == IdentificationType.BVN &&
                data.bvn.value == "",
            },
            voter_id: {
              ...data.voter_id,
              warning:
                data.identificationType?.value == IdentificationType.VOTER_ID &&
                data.voter_id.value == "",
            },
            nin: {
              ...data.nin,
              warning:
                data.identificationType?.value == IdentificationType.NIN_V2 &&
                data.nin.value == "",
            },
            employmentStatus: {
              ...data.employmentStatus,
              warning: data.employmentStatus.value == "",
            },
            occupation: {
              ...data.occupation,
              warning: data.occupation.value == "",
            },
            employerAddress: {
              ...data.employerAddress,
              warning:
                data.employmentStatus.value != "unemployed" &&
                data.employerAddress.value == "",
            },
            employerName: {
              ...data.employerName,
              warning:
                data.employmentStatus.value != "unemployed" &&
                data.employerName.value == "",
            },
          }
        : {}),
    };
    setUserDetail({ ...data });
    if (
      !data.email.warning &&
      !data.phone.warning &&
      !data.age.warning &&
      !data.martialStatus.warning &&
      !data.fullName.warning &&
      !data.permanentAddress.warning &&
      !data.permanentCity.warning &&
      !data.permanentState.warning &&
      !data.permanentZipcode.warning
    ) {
      if (user?.role == ROLES.renter) {
        if (
          data.kinContactNumber.warning ||
          data.kinLastName.warning ||
          data.kinLastName.warning ||
          data.kinEmail.warning ||
          data.relationshipKin.warning ||
          data.bvn.warning ||
          data.nin.warning ||
          data.voter_id.warning ||
          data.employerAddress.warning ||
          data.employmentStatus.warning ||
          data.employerName.warning ||
          data.occupation.warning
        ) {
          return;
        }
      }
      setLoader((prev) => ({
        ...prev,
        updatebtnLoader: true,
      }));
      const body = {
        fullName: userDetail.fullName.value,
        phone: userDetail.phone.value,
        countryCode: userDetail.countryCode.value,
        age: Number(userDetail.age.value),
        maritialStatus: userDetail.martialStatus.value,
        permanentAddress: {
          permanentAddress: userDetail.permanentAddress.value,
          permanentCity: userDetail.permanentCity.value,
          permanentState: userDetail.permanentState.value,
          permanentZipcode: Number(userDetail.permanentZipcode.value),
        },
        ...(user.role === ROLES.renter
          ? {
              kinDetails: {
                first_name: userDetail.kinFirstName.value,
                last_name: userDetail.kinLastName.value,
                middle_name: userDetail.kinMiddleName.value,
                bvn: userDetail.bvn.value,
                dob: userDetail.kindob.value,
                nin: userDetail.nin.value,
                voter_id: userDetail.voter_id.value,
                kinContactNumber: userDetail.kinContactNumber.value,
                kinEmail: userDetail.kinEmail.value,
                relationshipKin: userDetail.relationshipKin.value,
                identificationType: userDetail?.identificationType?.value,
              },
              employmentDetails: {
                employmentStatus: userDetail.employmentStatus.value,
                employerName: userDetail.employerName.value,
                employerAddress: userDetail.employerAddress.value,
                occupation: userDetail.occupation.value,
              },
            }
          : {}),
      };
      const postData = await userProfileUpdate(body);
      setLoader((prev) => ({
        ...prev,
        updatebtnLoader: false,
      }));
      setUserDetail((prev) => ({
        ...prev,
        editableField: true,
      }));
      console.log("pistDta", postData);
    }
  };

  useEffect(() => {
    getTheUserData();
  }, []);
  console.log("usedetal", userDetail);
  return (
    <Container maxWidth="xl" className="mb-5">
      <div className="py-4">
        <Typography fontSize={40} fontWeight={"bold"} color="text.primary">
          Profile
        </Typography>
        <Grid container spacing={4} className="mt-2 ">
          <Grid item md={12} xs={12}>
            <Grid container spacing={6}>
              <Grid item md={6} xs={12}>
                <Box
                className="profile_card"
                  display={"flex"}
                  justifyContent={"center"}
                  gap={6}
                  alignItems={"center"}
                  sx={{
                    backgroundImage: "url(/images/header-back.png)",
                    height: 250,
                    backgroundRepeat: "repeat",
                    borderRadius: 2,
                  }}
                
                >
                  <Box
                    height={167}
                    width={167}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    borderRadius={"50%"}
                    border={"1px solid white"}
                  >
                    <img
                      src={userDetail?.picture?.value}
                      style={{
                        height: "155px",
                        width: "155px",
                        borderRadius: "50%",
                      }}
                    />
                    <label htmlFor="image" className="position-relative">
                      <img
                        src="/images/editProfile.svg"
                        style={{
                          position: "absolute",
                          right: "12px",
                          top: "51px",
                          cursor: "pointer",
                        }}
                      />
                    </label>
                    <input
                      id="image"
                      type="file"
                      className="d-none"
                      onChange={async (e) => {
                        setUserDetail((prev) => ({
                          ...prev,
                          picture: {
                            ...prev.picture,
                            value: URL.createObjectURL(e.target.files[0]),
                          },
                        }));
                        const uplaodImage = await uploadImage({
                          media: e.target.files[0],
                        });
                        if (uplaodImage?.status) {
                          await userProfileUpdate({
                            picture: uplaodImage?.data?.fullPath,
                          });
                        }
                        console.log("uploadImgae", uplaodImage);
                      }}
                    />
                  </Box>
                  <div>
                    <div className="d-flex align-items-center  gap-2">
                      <Typography color={"white"} variant="h3">
                        {userDetail?.fullName?.value}
                      </Typography>

                      <img
                        src={"/images/user-verified.png"}
                        style={{ height: 18, width: 62 }}
                      />
                    </div>
                    <Typography
                      color="#C8D736"
                      fontSize={18}
                      fontWeight={"bold"}
                      variant="body1"
                    >
                      {userDetail?.role}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md={6} xs={12} className="mt-3">
                <div>
                  <TextField
                    value={userDetail?.email?.value}
                    fullWidth
                    name="email"
                    label={"Email"}
                    disabled={true}
                    onChange={onChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button size="medium"></Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {userDetail?.email?.warning && (
                    <span className="text-danger">
                      Enter your email address
                    </span>
                  )}
                </div>
                {/* <TextField
                  value={userDetail?.password?.value}
                  className="mt-4"
                  name=""
                  fullWidth
                  label={"Password"}
                  onChange={(e) =>
                    setUserDetail((prev) => ({
                      ...prev,
                      password: e.target.value,
                    }))
                  }
                  type={"password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button size="medium">Change</Button>
                      </InputAdornment>
                    ),
                  }}
                /> */}
                <TextField
                  value={`${userDetail?.phone?.value}`}
                  className="mt-4"
                  fullWidth
                  label={`Contact Number`}
                  disabled={userDetail?.editableField}
                  name="phone"
                  inputProps={{
                    maxlength: "14",
                  }}
                  onChange={onChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          disabled={loader.phonebtnLoader}
                          onClick={async () => {
                            if (!userDetail.phone.warning) {
                              setLoader((prev) => ({
                                ...prev,
                                phonebtnLoader: true,
                              }));
                              const postData = await userProfileUpdate({
                                phone: userDetail.phone.value,
                              });
                              setLoader((prev) => ({
                                ...prev,
                                phonebtnLoader: false,
                              }));
                            }
                          }}
                          size="medium"
                        >
                          Change
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
                {userDetail?.phone?.warning && (
                  <span className="text-danger">Enter your phone number</span>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div className="mt-5">
          <CollapseWithTitle
            title={"Personal Information"}
            open={employementDetailsCollapse}
            setOpen={setEmployementDetailsCollapse}
          />
          <Collapse in={employementDetailsCollapse}>
            <Grid container spacing={4} className="mt-2">
              <Grid item md={6} xs={12}>
                <TextField
                  label="Name"
                  value={userDetail?.fullName?.value}
                  onChange={onChange}
                  disabled={userDetail?.editableField}
                  name="fullName"
                  fullWidth
                />
                {userDetail?.fullName?.warning && (
                  <span className="text-danger">Enter the full name</span>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Marital status"
                  select
                  fullWidth
                  value={userDetail?.martialStatus?.value}
                  name="martialStatus"
                  disabled={userDetail?.editableField}
                  placeholder={""}
                  onChange={onChange}
                >
                  {martialStatusArr?.map((item) => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  ))}
                </TextField>
                {userDetail?.martialStatus?.warning && (
                  <span className="text-danger">Enter marital status</span>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Age"
                  value={userDetail?.age?.value}
                  name="age"
                  onChange={onChange}
                  disabled={userDetail?.editableField}
                  fullWidth
                />
                {userDetail?.age?.warning && (
                  <span className="text-danger">Enter your age</span>
                )}
              </Grid>
            </Grid>
          </Collapse>
        </div>
        <div className="mt-5">
          <CollapseWithTitle
            title={"Permanent Address"}
            open={kinDetailsCollapse}
            setOpen={setKinDetailsCollapse}
          />
          <Collapse in={kinDetailsCollapse}>
            <Grid container spacing={4} className="mt-2">
              <Grid item md={6} xs={12}>
                <TextField
                  label="House number/Flat number"
                  fullWidth
                  disabled={userDetail?.editableField}
                  value={userDetail.permanentAddress.value}
                  name="permanentAddress"
                  onChange={onChange}
                />
                {userDetail?.permanentAddress?.warning && (
                  <span className="text-danger">
                    Enter House number and flat number
                  </span>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="State"
                  disabled={userDetail?.editableField}
                  fullWidth
                  value={userDetail.permanentState.value}
                  name="permanentState"
                  onChange={onChange}
                />
                {userDetail?.permanentState?.warning && (
                  <span className="text-danger">Enter your state</span>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  value={userDetail.permanentCity.value}
                  name="permanentCity"
                  label="City"
                  disabled={userDetail?.editableField}
                  onChange={onChange}
                  fullWidth
                />
                {userDetail?.permanentCity?.warning && (
                  <span className="text-danger">Enter your city</span>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Zipcode"
                  disabled={userDetail?.editableField}
                  value={userDetail.permanentZipcode.value}
                  name="permanentZipcode"
                  inputProps={{
                    maxlength: "8",
                  }}
                  onChange={onChange}
                  fullWidth
                />
                {userDetail?.permanentZipcode?.warning && (
                  <span className="text-danger">Enter your zipcode</span>
                )}
              </Grid>
            </Grid>
          </Collapse>
        </div>
        {user?.role === ROLES.renter && (
          <div className="mt-5">
            <CollapseWithTitle
              title={"Kin details"}
              open={kinDetailsCollapse}
              setOpen={setKinDetailsCollapse}
            />

            <Collapse in={kinDetailsCollapse}>
              <Grid container spacing={4} className="mt-2">
                <Grid item md={6}>
                  <TextField
                    label="First Name"
                    disabled={userDetail?.editableField}
                    value={userDetail.kinFirstName.value}
                    name="kinFirstName"
                    onChange={onChange}
                    fullWidth
                  />
                  {userDetail.kinFirstName.warning && (
                    <span className="text-danger">Enter kin first name</span>
                  )}
                </Grid>
                <Grid item md={6}>
                  <TextField
                    label="Middle Name"
                    disabled={userDetail?.editableField}
                    value={userDetail.kinMiddleName.value}
                    name="kinMiddleName"
                    onChange={onChange}
                    fullWidth
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    label="Last Name"
                    value={userDetail.kinLastName.value}
                    name="kinLastName"
                    onChange={onChange}
                    disabled={userDetail?.editableField}
                    fullWidth
                  />
                  {userDetail.kinLastName.warning && (
                    <span className="text-danger">Enter kin last name</span>
                  )}
                </Grid>
                <Grid item md={6}>
                  <TextField
                    value={userDetail.kinContactNumber.value}
                    name="kinContactNumber"
                    onChange={onChange}
                    label="Contact Number"
                    disabled={userDetail?.editableField}
                    fullWidth
                  />
                  {userDetail.kinContactNumber.warning && (
                    <span className="text-danger">
                      Enter kin contact number
                    </span>
                  )}
                </Grid>
                <Grid item md={6}>
                  <Select
                    fullWidth
                    label="Identity Type"
                    value={userDetail?.identificationType?.value}
                    placeholder={"Identity Type"}
                    name="identificationType"
                    onChange={onChange}
                    disabled={userDetail?.editableField}
                  >
                    {kinIdentityType?.map((item) => (
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                  </Select>
                  {userDetail?.identificationType?.warning && (
                    <span className="text-danger">Select identity type</span>
                  )}
                </Grid>
                {userDetail.identificationType?.value ===
                IdentificationType.VOTER_ID ? (
                  <Grid item md={6} xs={12}>
                    <TextField
                      onChange={onChange}
                      name="voter_id"
                      value={userDetail.voter_id.value}
                      fullWidth
                      label="Voter Id Number"
                      placeholder={"Enter voter id"}
                      disabled={userDetail?.editableField}
                    />
                    {userDetail.voter_id.warning && (
                      <span className="text-danger">Enter voter_id </span>
                    )}
                  </Grid>
                ) : userDetail?.identificationType?.value ===
                  IdentificationType?.BVN ? (
                  <Grid item md={6} xs={12}>
                    <TextField
                      onChange={onChange}
                      name="bvn"
                      value={userDetail.bvn.value}
                      fullWidth
                      label="Bank Verfication number"
                      placeholder={"Enter bvn"}
                      disabled={userDetail?.editableField}
                    />
                    {userDetail.bvn.warning && (
                      <span className="text-danger">
                        Enter Bank verfication number{" "}
                      </span>
                    )}
                  </Grid>
                ) : userDetail.identificationType?.value ===
                  IdentificationType.NIN_V2 ? (
                  <Grid item md={6} xs={12}>
                    <TextField
                      onChange={onChange}
                      name="nin"
                      value={userDetail.nin.value}
                      fullWidth
                      label="National Identification Number"
                      placeholder={"Enter nin"}
                      disabled={userDetail?.editableField}
                    />
                    {userDetail.nin.warning && (
                      <span className="text-danger">
                        Enter National Identification Number
                      </span>
                    )}
                  </Grid>
                ) : null}
                <Grid item md={6}>
                  <TextField
                    label="Email"
                    name="kinEmail"
                    value={userDetail.kinEmail.value}
                    onChange={onChange}
                    disabled={userDetail?.editableField}
                    fullWidth
                  />
                  {userDetail.kinEmail.warning && (
                    <span className="text-danger">enter kin email</span>
                  )}
                </Grid>
                <Grid item md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        name="kindob"
                        value={dayjs(userDetail.kindob.value)}
                        label="kin dob"
                        onChange={(value) => {
                          setUserDetail((prev) => ({
                            ...prev,
                            kindob: {
                              value: value,
                              warning: false,
                            },
                          }));
                        }}
                        disabled={userDetail?.editableField}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  {userDetail.kindob.warning && (
                    <span className="text-danger">enter kin email</span>
                  )}
                </Grid>
                <Grid item md={6}>
                  <TextField
                    placeholder="Select"
                    fullWidth
                    label="Relationship with kin"
                    value={userDetail.relationshipKin.value}
                    name="relationshipKin"
                    onChange={onChange}
                    disabled={userDetail?.editableField}
                  />
                  {userDetail.relationshipKin.warning && (
                    <span className="text-danger">
                      enter relationship with Kin
                    </span>
                  )}
                </Grid>
              </Grid>
            </Collapse>
          </div>
        )}
        {user?.role === ROLES.renter && (
          <div className="mt-5">
            <CollapseWithTitle
              title={"Employment details"}
              open={kinDetailsCollapse}
              setOpen={setKinDetailsCollapse}
            />

            <Collapse in={kinDetailsCollapse}>
              <Grid container spacing={4} className="mt-2">
                <Grid item md={6} xs={12}>
                  <Select
                    onChange={onChange}
                    placeholder={"Select"}
                    name={"employmentStatus"}
                    value={userDetail.employmentStatus.value}
                    label={"Employement Status"}
                    fullWidth
                    disabled={userDetail?.editableField}
                  >
                    {employementStatus?.map((item) => (
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                  </Select>
                  {userDetail.employmentStatus.warning && (
                    <span className="text-danger">
                      Select the employee status
                    </span>
                  )}
                </Grid>

                {userDetail.employmentStatus.value === "unemployed" ? null : (
                  <>
                    {" "}
                    <Grid item md={6} xs={12}>
                      <TextField
                        onChange={onChange}
                        fullWidth
                        name="employerName"
                        label="Employer's name"
                        value={userDetail.employerName.value}
                        placeholder={"Enter employer's name"}
                        disabled={userDetail?.editableField}
                      />
                      {userDetail.employerName.warning && (
                        <span className="text-danger">
                          Enter employer's name
                        </span>
                      )}
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        onChange={onChange}
                        fullWidth
                        name="employerAddress"
                        label="Employer's address"
                        disabled={userDetail?.editableField}
                        value={userDetail.employerAddress.value}
                        placeholder={"Enter employer's address"}
                      />
                      {userDetail.employerAddress.warning && (
                        <span className="text-danger">
                          Enter employer's address
                        </span>
                      )}
                    </Grid>
                  </>
                )}
                <Grid item md={6} xs={12}>
                  <TextField
                    onChange={onChange}
                    fullWidth
                    disabled={userDetail?.editableField}
                    name="occupation"
                    label="Occupation"
                    value={userDetail.occupation.value}
                    placeholder={"Enter Occupation"}
                  />
                  {userDetail.occupation.warning && (
                    <span className="text-danger">Enter Occupation</span>
                  )}
                </Grid>
              </Grid>
            </Collapse>
          </div>
        )}
      </div>
      <div className="mt-4">
        {userDetail?.editableField ? (
          <ButtonComponent
            sx={{ width: 160, height: 64, borderRadius: 2 }}
            title={"Edit"}
            onClick={(e) =>
              setUserDetail((prev) => ({
                ...prev,
                editableField: false,
              }))
            }
          />
        ) : (
          <ButtonComponent
            sx={{ width: 160, height: 64, borderRadius: 2 }}
            title={"Update"}
            onClick={(e) => validateForm(e)}
            disabled={loader.updatebtnLoader}
            loading={loader.updatebtnLoader}
          />
        )}
      </div>
    </Container>
  );
};

export default UserProfile;
