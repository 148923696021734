import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { formatCurrency } from "../utils/helperFunctionForValidte";

const OrderSummary = ({ title, items }) => {
  const [total,setTotal] = useState(0)
  console.log("items",typeof items[0]?.amount)
  useEffect(() => {
    let totals = 0;
    items.map((x) => {
      console.log(Number(x.amount))
      totals += Number(x.amount);
    });
    console.log("totals",totals)
    setTotal(totals)
  }, [items]);
  return (
    <Card className="" elevation={0} sx={{ border: "1px solid #009DA6", borderRadius: 2, '@media (max-width: 600px)': {
      marginBottom:"30px"
     }, }}>
      <CardHeader
        sx={{
          background: "#009DA6",
          color: "white",
          fontSize: 24,
          fontWeight: "bold",
        }}
        title={title}
      />
      <CardContent sx={{ p: 2 }}>
        {items.map((x, i) => {
          return (
            <>
              <div
                className="d-flex justify-content-between align-items-center"
                key={i}
              >
                <Typography color="#596E73" fontSize={16} fontWeight={300}>
                  {x.title}
                </Typography>
                <Typography color="#031013" fontSize={16}>
                  { formatCurrency(x.amount)}
                </Typography>
              </div>
              <hr />
            </>
          );
        })}
        <div className="d-flex justify-content-between align-items-center">
          <Typography color="#596E73" fontSize={16} fontWeight={"bold"}>
            Total Amount
          </Typography>
          <Typography color="#031013" fontSize={16} fontWeight={"bold"}>
            { formatCurrency(total)}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default OrderSummary;
