import {
  Box,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SearchIcon } from "../../../assets/icons";
import { theme } from "../../../assets/theme";
import Property from "../../../components/Property";
import Loader from "../../../components/loader";
import { getFavourPropertyData } from "../../../services/favouritePropertyServices";
import useDebounceSearch from "../../../services/globalSearchService";
import { API_Endpoints } from "../../../services/API_Endpoints";
export default function Favourites() {
  const [loading, setLoading] = useState(false);
  const [liked, setLiked] = useState(false);
  const [allStatusSearchState, setAllStatusSearchState] = useState({
    search: "",
  });
  const [favouriteProperty, setFavouriteProperty] = useState([]);
  const getFavouriteProperty = async () => {
    try {
      setLoading(true);
      const response = await getFavourPropertyData(
        `${API_Endpoints.favourite.getFavouriteProperties}?search=${allStatusSearchState.search}`
      );
      if (response.status) {
        const data = response?.data?.map((item) => {
          return {
            ...item,
            liked: true,
          };
        });
        setFavouriteProperty(data);
      }
      setLoading(false);
    } catch (error) {
      console.log("expectional error", error);
    }
  };

  // this callback function is work as the user click on the favourite button of the property

  const updatedataFavouriteDataAfterLikeOrDislike = () => {
    setLiked(!liked);
  };

  useDebounceSearch(allStatusSearchState.search, getFavouriteProperty, 1000, {
    liked: liked,
  });
  return (
    <Container maxWidth="xl" className="mt-3">
      <div className="py-4">
        <Box>
          <Typography
            className="phone-heading-30"
            fontSize={40}
            fontWeight={700}
            color={theme.palette.text.primary}
          >
            Favourite Properties
          </Typography>
        </Box>
        <Box className="mb-4 mt-4">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                type="search"
                variant="outlined"
                className="w-100"
                placeholder="search"
                onChange={(e) =>
                  setAllStatusSearchState((prev) => ({
                    ...prev,
                    search: e.target.value,
                  }))
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box className="property-list">
          <Grid container>
            {loading ? (
              <Loader />
            ) : favouriteProperty.length === 0 ? (
              <h2 className="property_not_found w-100">
                No Favourite Property Found Click on the "Like" button to add
                favourite properties
              </h2>
            ) : (
              favouriteProperty?.map((x, i) => {
                return (
                  <Grid
                    item
                    md={4}
                    xs={12}
                    key={x._id}
                    className="pt-2 pb-2 pe-4 phone-padding-0"
                  >
                    <Property
                      isFavourite={""}
                      {...x}
                      img={x?.images[0]?.url}
                      favouriteApiCall={
                        updatedataFavouriteDataAfterLikeOrDislike
                      }
                    />
                  </Grid>
                );
              })
            )}
          </Grid>
        </Box>
      </div>
    </Container>
  );
}
