import React, { useState } from "react";
import { Box, Button, Card, Grid, TextField, Typography } from "@mui/material";
import contactUsBanner from "../../assets/images/contactUsBanner.png";
import { theme } from "../../assets/theme";
import contactForm from "../../assets/images/contactForm.png";
import ButtonComponent from "../../components/Button";
import {
  Call,
  FacebookIcon,
  Home,
  InstaIcon,
  LinkedinIcon,
  Mail,
  TwitterIcon,
} from "../../assets/icons";
import { Field, Form, Formik } from "formik";
import { validationSchemaContact } from "../../utils/schema";
import SuccessFullySubmitModal from "../../components/successFullySubmittedModal";
import { addContact } from "../../services/cms/contactUs";
import { NotificationManager } from "react-notifications";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [successFullyModal, setSuccessFullyModal] = useState(false);
  const addContactApiIntigration = async (values, { resetForm }) => {
    setLoading(true)
    const postData = await addContact(values);
    if (postData?.status) {
      resetForm();
      setSuccessFullyModal(true);
    } else if (postData?.response?.status === 400) {
      NotificationManager.error("", postData?.response?.data?.message);
    } else {
      NotificationManager.error("", postData?.message);
    }
    console.log("postdata", postData);
    setLoading(false);
  };
  return (
    <div>
      <Box
        display={"flex"}
        // justifyContent={"flex-start"}
        px={6}
        alignItems={"center"}
        sx={{
          backgroundImage: `url(${contactUsBanner})`,
          width: "100%",
          backgroundSize: "cover",
          height: 497,
          '@media (max-width: 600px)': {
          height: '320px',  // 
        },
        }}
      >
        <div>
          <Typography
            className="phone-heading-30"
            fontSize={56}
            fontWeight={"bold"}
            color={theme.palette.text.primary}
          >
            Contact Us
          </Typography>
          <Typography
            className="para-heading-18"
            fontSize={24}
            color={theme.palette.text.secondary}
          >
            Reach out and let's make It happen.
          </Typography>
        </div>
      </Box>
      <div
        style={{ width: "100%" }}
        className="d-flex justify-content-center mb-md-5"
      >
        <Card
          elevation={2}
          sx={{
            maxWidth: 1131,
            width: "100%",
            mt: -7,
            mb: 4,
            borderRadius: 3,
            boxShadow: "5px 5px 19px 4px #0000000d",
          }}
        >
          <Grid container>
            <Grid item md={8} p={3}>
              <Typography
                fontSize={24}
                fontWeight={500}
                color={"text.secondary"}
              >
                Get in touch!
              </Typography>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  phone: "",
                  company: "",
                  message: "",
                }}
                validationSchema={validationSchemaContact}
                onSubmit={addContactApiIntigration}
                // validationSchema = {validationSchemaContact}
              >
                {({ handleChange, handleBlur, values, errors, touched }) => (
                  <Form>
                    <Grid container spacing={4} mt={2}>
                      <Grid item md={6} xs={12}>
                        <Field
                          as={TextField}
                          fullWidth
                          label="Name"
                          placeholder="Enter your name"
                          name="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          error={touched.name && Boolean(errors.name)}
                          helperText={touched.name && errors.name}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Field
                          as={TextField}
                          fullWidth
                          label="Email id"
                          placeholder="Enter email id"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Field
                          as={TextField}
                          fullWidth
                          label="Phone"
                          placeholder="Enter contact number"
                          name="phone"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                          error={touched.phone && Boolean(errors.phone)}
                          helperText={touched.phone && errors.phone}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Field
                          as={TextField}
                          fullWidth
                          label="Company"
                          placeholder="Enter organization name"
                          name="company"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.company}
                          error={touched.company && Boolean(errors.company)}
                          helperText={touched.company && errors.company}
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Field
                          as={TextField}
                          multiline={true}
                          rows={5}
                          fullWidth
                          label="Message"
                          placeholder="Enter your message here"
                          name="message"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.message}
                          error={touched.message && Boolean(errors.message)}
                          helperText={touched.message && errors.message}
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <ButtonComponent
                          type="submit"
                          variant="contained"
                          color="primary"
                          sx={{ mt: 3, width: 165 }}
                          disabled={loading}
                          loading={loading}
                          title={"Submit"}
                        />
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box
                sx={{
                  backgroundImage: `url(${contactForm})`,
                  height: "100%",
                  backgroundSize: "cover",
                  py: 6,
                  px: 3,
                }}
              >
                <Typography fontSize={24} fontWeight={500} color={"white"}>
                  Contact Information
                </Typography>

                <div className="mt-3">
                  <div className="d-flex justify-content-start align-items-center mt-3 gap-4">
                    <Home />
                    <Typography color="white" fontSize={16}>
                      21 Hunponu-Wusu Rd,
                      <br />
                      Eti-Osa 106104, Lekki, Lagos
                    </Typography>
                  </div>
                  <div className="d-flex justify-content-start align-items-center gap-4 mt-4">
                    <Call />
                    <Typography color="white" fontSize={16}>
                      01 8880440
                    </Typography>
                  </div>
                  <div className="d-flex justify-content-start align-items-center mt-4 gap-4">
                    <Mail />
                    <Typography color="white" fontSize={16}>
                      info@rentranzact.com
                    </Typography>
                  </div>
                </div>


                <div  style={{ marginTop: 150 }} className="social_contact d-flex gap-3">

                  <FacebookIcon />
                  <LinkedinIcon />
                  <TwitterIcon />
                  <InstaIcon />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </div>
      <SuccessFullySubmitModal
        open={successFullyModal}
        setOpen={setSuccessFullyModal}
        btnText="Close"
        headingAndText={{
          mainHeading: "Request send successfully we will reach you shortly",
        }}
        onCLick={() => {
          setSuccessFullyModal(false);
        }}
      />
    </div>
  );
};

export default ContactUs;
