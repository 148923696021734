import { RetrieveLocalUser } from "../utils/authService";
import axiosInstance from "../utils/axios";
import { API_Endpoints } from "./API_Endpoints";

export const AddPropertyService = (values) => {
  return axiosInstance
    .post(API_Endpoints.property.ADD_PROPERTY, values, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
};
export const EditPropertyService = (values) => {
  return axiosInstance
    .put(API_Endpoints.property.EDIT_PROPERTY, values, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
};
export const PropertyListService = (values) => {
  return axiosInstance
    .post(API_Endpoints.property.PROPERTY_LIST, values)
    .then((res) => res.data)
    .catch((err) => err);
};

export const SearchPropertyService = (filters) => {
  return axiosInstance
    .post(API_Endpoints.property.SEARCH_PROPERTY, filters)
    .then((res) => res.data)
    .catch((err) => err);
};

export const GetPropertyDetailService = (id) => {
  const user = RetrieveLocalUser("user");
  return axiosInstance
    .get(
      `${API_Endpoints.property.GET_PROPERTY_DETAIL}/${id}?userID=${
        user?._id === undefined || user._id === null ? "" : user?._id
      }`
    )
    .then((res) => res.data)
    .catch((err) => err);
};

export const ReasonToLeaveTheProperty = (id, body) => {
  return axiosInstance
    .post(`${API_Endpoints.property.REASON_TO_LEAVE_PROPERTY}/${id}`, body)
    .then((res) => res.data)
    .catch((err) => err);
};

export const MultipleFilterProperty = (url) => {
  return axiosInstance
    .get(url)
    .then((res) => res.data)
    .catch((err) => err);
};

export const DeleteProperty = (id) => {
  return axiosInstance
    .delete(`${API_Endpoints.property.ADD_PROPERTY}/${id}`)
    .then((res) => res.data)
    .catch((err) => err);
};

export const EditPropertyServiceByAdmin = (values) => {
  return axiosInstance
    .put(API_Endpoints.property.EDITBY_ADMIN, values, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
};
