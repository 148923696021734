import React, { useEffect, useState } from "react";
import AuthenticationLayout from ".";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import CustomTextField from "../../components/TextField";
import { ArrowRightIcon } from "../../assets/icons";
import ButtonComponent from "../../components/Button";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import CustomSelectBox from "../../components/SelectBox";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { baseURL } from "../../utils/axios";
import { API_Endpoints } from "../../services/API_Endpoints";
import { NotificationManager } from "react-notifications";
import { useDispatch } from "react-redux";
import { authSlice } from "../../redux/auth/authSlice";
import { verifyReferCode } from "../../services/referAndEarnServices";

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required("Name is required"),
  phone: Yup.string().required("Phone number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  gender: Yup.string().required("Gender is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  termCondition: Yup.boolean()
    .required("The terms and conditions must be accepted.")
    .oneOf([true], "The terms and conditions must be accepted."),
});
const SignUp = () => {
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  const roles = ["Renter", "Property Manager", "Landlord"];
  const [referralCode, setReferralCode] = useState({
    referral: queryParams.get("referral") ? queryParams.get("referral") : "",
    valid: false,
  });
  const [selectedRole, setSelectedRole] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      role: roles[selectedRole],
      fullName: "",
      email: "",
      phone: "",
      gender: "",
      countryCode: "+91",
      confirm_password: "",
      password: "",
      termCondition: false,
      referralCode: queryParams.get("referral")
        ? queryParams.get("referral")
        : "",
    },
    onSubmit: async (values) => {
      if (!referralCode?.valid && values?.referralCode) {
        return NotificationManager.error("", "Verify referral code");
      }
      const newValues = {
        ...values,
        role: roles[selectedRole],
        countryCode: "+91",
        referralCode: values?.referralCode,
      };
      delete newValues["termCondition"];
      setLoading(true);
      delete newValues.confirm_password;
      await axios
        .post(baseURL + API_Endpoints.auth.SIGNUP, newValues)
        .then((res) => {
          console.log(res);
          if (res?.status) {
            formik.resetForm();
            // NotificationManager.success("", res.data.message);
            navigate("/email-verification", { state: res.data });
            dispatch(
              authSlice.actions.setTheUserIdAtTimeOfLoginOrSignUp(
                res?.data?.data?.id
              )
            );
          }
        })
        .catch((err) => {
          NotificationManager.error("", err.response.data.message);
          console.log(err.response, "ERRRORRR");
        });

      setLoading(false);
      formik.resetForm();
    },
  });

  console.log(loading, "loading");

  //verfiy the code
  const verifyTHeReferralCode = async () => {
    if (formik.values.referralCode) {
      const postData = await verifyReferCode({
        referralCode: formik.values.referralCode,
      });
      console.log("dasa", postData);
      if (postData?.status) {
        setReferralCode((prev) => ({
          ...prev,
          valid: true,
        }));
        NotificationManager.success("", "Referral code is valid");
      } else {
        NotificationManager.error("", postData?.response?.data?.message);
      }
    }
  };

  // reset the forgotpassword flow
  useEffect(() => {
    dispatch(authSlice.actions.setforgotPasswordIntialState());
  }, []);
  // useEffect(() => {
  //   formik.resetForm();
  // }, [selectedRole]);
  return (
    <FormikProvider value={formik}>
      {formik && (
        <AuthenticationLayout>
          <div>
            <Typography
              fontSize={24}
              fontWeight={"bold"}
              color="#111111"
              className="text-center"
              mb={2}
            >
              Sign Up
            </Typography>

            <div className="d-flex align-items-center justify-content-center fixed">
              <ButtonGroup
                size="large"
                variant="outlined"
                aria-label="Basic button group"
                color="secondary"
              >
                {roles.map((x, i) => {
                  return (
                    <Button
                      variant={selectedRole === i ? "contained" : "outlined"}
                      onClick={() => setSelectedRole(i)}
                      sx={{ textTransform: "none", borderRadius: 2 }}
                      key={i}
                    >
                      {x}
                    </Button>
                  );
                })}
              </ButtonGroup>
            </div>

            <Form>
              <Container maxWidth="xs">
                <Grid container spacing={2} mt={2}>
                  <Grid item md={12} xs={12}>
                    <CustomTextField
                      name="fullName"
                      onChange={(e) =>
                        formik.setFieldValue("fullName", e.target.value)
                      }
                      placeholder="Enter Name"
                      value={formik.values.fullName}
                      label="Name"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <CustomTextField
                      name="email"
                      value={formik.values.email}
                      onChange={(e) =>
                        formik.setFieldValue("email", e.target.value)
                      }
                      placeholder="Enter email id"
                      label="Email id"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <CustomTextField
                      type={"number"}
                      value={formik.values.phone}
                      name="phone"
                      onChange={(e) =>
                        formik.setFieldValue("phone", e.target.value)
                      }
                      placeholder="Enter contact no."
                      label="Contact Number"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <CustomSelectBox
                      onChange={(e) =>
                        formik.setFieldValue("gender", e.target.value)
                      }
                      placeholder={"Select"}
                      name="gender"
                      label={"Gender"}
                      value={formik.values.gender}
                      menu={[
                        { label: "Male", value: "male" },
                        { label: "Female", value: "female" },
                      ]}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <CustomTextField
                      name="password"
                      value={formik.values.password}
                      onChange={(e) =>
                        formik.setFieldValue("password", e.target.value)
                      }
                      type={"password"}
                      placeholder="password"
                      label="Password"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <CustomTextField
                      value={formik.values.confirm_password}
                      type={"password"}
                      name="confirm_password"
                      onChange={(e) =>
                        formik.setFieldValue("confirm_password", e.target.value)
                      }
                      placeholder="Confirm password"
                      label="Confirm Password"
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <Box
                      sx={{
                        background: "#e8eef1",
                        border: "1px dashed #13556D",
                        p: 2,
                        borderRadius: 2,
                      }}
                    >
                      <div>
                        <label className="text-left">
                          Referral Code (optional)
                        </label>
                        <TextField
                          // variant="outlined"
                          value={formik.values.referralCode}
                          sx={{
                            background: "white",
                            borderRadius: 2,
                            "& .MuiOutlinedInput-root": {
                              outline: "none",
                            },
                            "& .MuiOutlinedInput-input": {
                              border: "none",
                            },
                            p: 0.3,
                          }}
                          onChange={(e) =>
                            formik.setFieldValue("referralCode", e.target.value)
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                <Box
                                  sx={{
                                    background: "#13556D",
                                    borderRadius: 2,
                                    cursor: "pointer",
                                  }}
                                  height={45}
                                  width={45}
                                  display={"flex"}
                                  alignItems={"center"}
                                  justifyContent={"center"}
                                  onClick={verifyTHeReferralCode}
                                >
                                  <ArrowRightIcon />
                                </Box>
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                        />
                      </div>
                    </Box>
                  </Grid>
                </Grid>
                <div className="d-flex align-items-center mt-2">
                  <Checkbox
                    name="termCondition"
                    value={formik.values.termCondition}
                    onChange={(e) =>
                      formik.setFieldValue("termCondition", e.target.checked)
                    }
                  />

                  <Typography
                    fontSize={14}
                    color="text.primary"
                    fontWeight={500}
                  >
                    I agree to{" "}
                    <Link style={{ color: "#13556D" }} to="/terms&conditions">
                      Terms & Conditions
                    </Link>
                  </Typography>
                </div>
                {formik.errors.termCondition && (
                  <span className="text-danger">
                    {formik.errors.termCondition}
                  </span>
                )}
                <ButtonComponent
                  // loading={loading}
                  // disabled={formik.isSubmitting}
                  type={"submit"}
                  // onClick={() => formik.handleSubmit()}
                  sx={{ width: "100%", mt: 1, height: 53 }}
                  title={"Sign Up"}
                  color={"secondary"}
                />
                <div className="d-flex align-items-center justify-content-center mt-3">
                  <Typography fontSize={14} color="#B2BCBE">
                    Already have an account?
                  </Typography>
                  &nbsp;
                  <Link to={"/login"}>
                    <Typography fontSize={14} color="text.primary">
                      Sign in
                    </Typography>
                  </Link>
                </div>
              </Container>
            </Form>
          </div>
        </AuthenticationLayout>
      )}
    </FormikProvider>
  );
};

export default SignUp;
