import {
  Dialog,
  DialogTitle,
  Button,
  DialogActions,
  CircularProgress,
} from "@mui/material";
const ConfirmationDialog = ({
  open,
  setDeleteDialog,
  title,
  yes,
  apiNotCallOnCancel = () => {},
  loader = false,
  showLoader = false,
}) => {
  const handleClose = () => {
    setDeleteDialog(false);
    apiNotCallOnCancel("cancel");
  };
  console.log("loader",loader)
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

      <DialogActions>
        <Button
          disabled={loader}
          style={{ background: "#C8D736", color: "#fff" }}
          onClick={yes}
        >
          {loader ? <CircularProgress size={24} color="inherit" /> : "Yes"}
        </Button>
        <Button
          style={{ background: "#d32f2f", color: "#fff" }}
          onClick={handleClose}
          color="error"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
