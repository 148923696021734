import { TextareaAutosize, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import renterImg from "../assets/images/reviewPic.png";
import { theme } from "../assets/theme";
import ButtonComponent from "./Button";
import { fDate, fDateTime } from "../utils/format-time";
import { CancelInspectionRequestByRenterService } from "../services/inspections.services";
import { inspectionApplicationRequestStatus } from "../utils/appConstants";
import { ApplicationRequestStatusChangedService } from "../services/application.services";
import { terminateRenter } from "../services/renterService";

export default function LandlordCommonModal({
  open,
  setOpen,
  heading,
  heading2,
  notificationText = " A notification has been sent to Joy regarding his tenancy termination",
  detailData,
  apiNotCallOnCancel = () => {},
}) {
  const [step, setStep] = React.useState(1);
  const naviagte = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();
  const [cancelRequestText, setCancelRequestText] = React.useState({
    value: "",
    warning: false,
  });
  // cancel the request from the backend api intigration
  const onCancelTheRequest = async () => {
    if (cancelRequestText?.value === "") {
      return setCancelRequestText((prev) => ({
        ...prev,
        warning: true,
      }));
    }
    // this ternary check will handle the popuop is render on application recived page or inspection recived page and triger the api as per the route
    const body = {
      ...(location.pathname === "/applications-recieved"
        ? { rentApplicationID: detailData?._id }
        : { inspectionID: detailData?._id }),
      status: inspectionApplicationRequestStatus?.cancel,
      reason: cancelRequestText?.value,
    };
    console.log(location);
    setLoading(true);
    // this ternary check will handle the popuop is render on application recived page or inspection recived page and triger the api as per the route
    const cancelData =
      location.pathname === "/applications-recieved" ||
      location.pathname.includes("/applicant-details")
        ? await ApplicationRequestStatusChangedService(body)
        : location.pathname.includes("my-renters-detail")
        ? await terminateRenter(
            detailData?.property_info && detailData?.property_info[0]?._id
          )
        : await CancelInspectionRequestByRenterService(body);
    if (cancelData?.status) {
      setStep(2);
      setCancelRequestText({ value: "", warning: false });
      if (location.pathname.includes("/applicant-details")) {
        naviagte(-1);
      }
    }
    setLoading(false);
    console.log("cancedata", cancelData);
  };
  console.log("ddetaildata", detailData);
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        apiNotCallOnCancel("cancel");
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography
          fontSize={40}
          fontWeight={700}
          color={theme.palette.text.primary}
          textAlign={step === 1 ? "left" : "center"}
        >
          {step === 1 ? heading : heading2}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div className="d-flex mb-4 align-items-center">
          {step === 1 ? (
            <>
              <img
                style={{
                  width: "180px",
                  height: "180px",
                  borderRadius: "50%",
                }}
                src={
                  detailData?.RenterDetails?.picture ||
                  (detailData?.renter_info &&
                    detailData?.renter_info[0]?.picture)
                }
              />
              <div className="ps-3">
                {" "}
                <Typography color={"#13556D"} fontWeight={700} fontSize={18}>
                  {detailData?.RenterDetails?.fullName ||
                    (detailData?.renter_info &&
                      detailData?.renter_info[0]?.fullName)}
                </Typography>
                <Typography
                  className="mt-1 mb-1"
                  color={"#07262E"}
                  fontWeight={400}
                  fontSize={18}
                >
                  {detailData?.message ||
                    (detailData?.property_info &&
                      detailData?.property_info[0]?.propertyName)}
                </Typography>
                <Typography
                  className="mt-1 mb-1"
                  color={"#8D9B9F"}
                  fontWeight={400}
                  fontSize={16}
                >
                  {fDateTime(detailData?.inspectionDate) ||
                    fDateTime(detailData?.checkinDate)}
                </Typography>
              </div>
            </>
          ) : (
            <>
              <Typography
                fontSize={18}
                fontWeight={700}
                color={"#07262E"}
                textAlign={"center"}
              >
                {notificationText}
              </Typography>
            </>
          )}
        </div>
        {step === 1 ? (
          <>
            <TextareaAutosize
              size="lg"
              name="Size"
              placeholder={`Mention reason for ${heading}`}
              minRows={3}
              className="p-1"
              style={{
                width: "100%",
                borderRadius: "7px",
              }}
              value={cancelRequestText.value}
              onChange={(e) =>
                setCancelRequestText((prev) => ({
                  ...prev,
                  value: e.target.value,
                  warning: false,
                }))
              }
            />
            {cancelRequestText.warning && (
              <span className="text-danger">Enter the Reason</span>
            )}
          </>
        ) : null}
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        {step === 1 ? (
          <>
            <ButtonComponent
              onClick={() => {
                setOpen(false);
                apiNotCallOnCancel("cancel");
              }}
              title={"Cancel"}
              sx={{
                border: "1px solid #C8D736",
                backgroundColor: "#fff",
                marginLeft: "20px",
                marginBottom: "20px",
                paddingLeft: "50px",
                paddingRight: "50px",
              }}
            />
            <ButtonComponent
              onClick={() => onCancelTheRequest()}
              title={"Submit"}
              loading={loading}
              disabled={loading}
              sx={{
                marginLeft: "20px",
                marginBottom: "20px",
                paddingLeft: "50px",
                paddingRight: "50px",
              }}
            />
          </>
        ) : (
          <ButtonComponent
            onClick={() => {
              setOpen(false);
              // only work if this component work on the renter detail page
              if (location.pathname.includes("my-renters-detail")) {
                naviagte(-1);
              } else if (location.pathname === "/inspection-requests") {
                naviagte("/inspection-requests");
              }
              setTimeout(() => {
                setStep(1);
                apiNotCallOnCancel("");
              }, 200);
            }}
            title={"Done"}
            sx={{
              marginLeft: "20px",
              marginBottom: "20px",
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}
