import {
  Box,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import blogBanner from "../../assets/images/blogbanner.png";
import { theme } from "../../assets/theme";
import blog1 from "../../assets/images/blog1.png";
import Blog from "../../components/Blog";
import { getWebBlogList } from "../../services/blogsServices";
import { getBlogsList } from "../../services/admin/adminCmsServices";
import Loader from "../../components/loader";
import { getImageUrl, getQueryParams } from "../../utils/methods";
import { sortingFilterForBlog } from "../../utils/CONSTANTS";
const blogs = [
  {
    title: "Housing Bubble 2024: Are Housing Markets Crashing?",
    date: "24 Jan 2024",
    img: blog1,
  },
  {
    title: "Housing Bubble 2024: Are Housing Markets Crashing?",
    date: "24 Jan 2024",
    img: blog1,
  },
  {
    title: "Housing Bubble 2024: Are Housing Markets Crashing?",
    date: "24 Jan 2024",
    img: blog1,
  },
  {
    title: "Housing Bubble 2024: Are Housing Markets Crashing?",
    date: "24 Jan 2024",
    img: blog1,
  },
  {
    title: "Housing Bubble 2024: Are Housing Markets Crashing?",
    date: "24 Jan 2024",
    img: blog1,
  },
  {
    title: "Housing Bubble 2024: Are Housing Markets Crashing?",
    date: "24 Jan 2024",
    img: blog1,
  },
  {
    title: "Housing Bubble 2024: Are Housing Markets Crashing?",
    date: "24 Jan 2024",
    img: blog1,
  },
  {
    title: "Housing Bubble 2024: Are Housing Markets Crashing?",
    date: "24 Jan 2024",
    img: blog1,
  },
  {
    title: "Housing Bubble 2024: Are Housing Markets Crashing?",
    date: "24 Jan 2024",
    img: blog1,
  },
  {
    title: "Housing Bubble 2024: Are Housing Markets Crashing?",
    date: "24 Jan 2024",
    img: blog1,
  },
];

const Blogs = () => {
  //  local state
  const [localState, setLocalState] = useState({
    data: [],
    sortKey: {
      id: 3,
      value: "title",
      order: "desc",
    },
    loading: false,
  });
  // get api data
  const getBlogData = async () => {
    setLocalState((prev) => ({
      ...prev,
      loading: true,
    }));
    const param = {
      page: 1,
      search: "",
      sort_order: localState?.sortKey?.order,
      sort_key: localState?.sortKey?.value,
    };
    const queryString = getQueryParams(param);
    const res = await getBlogsList(queryString);
    if (res?.status) {
      setLocalState((prev) => ({
        ...prev,
        data: res?.data[0]?.data,
        loading: false,
      }));
    }
  };
  // handling the sort filter
  const handleSortSelect = async (e) => {
    setLocalState((prev) => ({
      ...prev,
      sortKey: sortingFilterForBlog.find(
        (item) => item.id === e?.target?.value
      ),
    }));
  };

  // effect for get api data
  useEffect(() => {
    getBlogData();
  }, [localState?.sortKey]);

  // show loading
  if (localState.loading) {
    return <Loader />;
  }
  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        px={6}
        alignItems={"center"}
        sx={{
          backgroundImage: `url(${blogBanner})`,
          width: "100%",
          backgroundSize: "cover",

          height: 497,
          '@media (max-width: 600px)': {
          height: '320px',  // 
        },
        }}
        className="blog_container"
      >
        <div className="blog-banner">
          <Typography
            fontSize={56}
            fontWeight={"bold"}
            color={theme.palette.text.primary}
          >
            Blog
          </Typography>

          <Typography
            fontSize={24}
            color={theme.palette.text.secondary}
            className="blog-content"
          >
            Turning spaces into homes, dreams into reality. Your
            <br /> streamline trusted partner in real estate excellence.
          </Typography>
        </div>
      </Box>
      <Container maxWidth={"xl"}>
        <div className="d-flex blogs_top_container justify-content-center flex-column align-items-center">
          <div className="d-flex align-items-center gap-2 mt-5">
            <hr style={{ border: "1px solid #008F97", width: 40 }} />
            <Typography color="#009DA6" fontSize={24}>
              Blogs
            </Typography>
          </div>
          <Typography
            fontSize={40}
            className="phone-heading-30"
            fontWeight={500}
            color={theme.palette.text.primary}
          >
            Explore, Engage, Empower: Unveiling Insights in Every Blog.
          </Typography>
        </div>
        <div className="d-flex justify-content-between align-items-center  mt-4">
          <Typography
            fontSize={24}
            color={theme.palette.text.primary}
            fontWeight={500}
          >
            Blogs
          </Typography>
          <FormControl>
            <InputLabel>Sort By</InputLabel>
            <Select
              sx={{ width: 275, borderColor: "#DBDBDB" }}
              label="Sort By"
              placeholder="Sort By"
              onChange={handleSortSelect}
              value={localState.sortKey.id}
            >
              {" "}
              {sortingFilterForBlog.map((curElm) => (
                <MenuItem value={curElm.id} key={curElm?.id}>
                  {curElm?.label}{" "}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Grid container spacing={3} mt={3} mb={5}>
          {localState?.data &&
            localState?.data?.map((x, index) => {
              return (
                <Grid className="mobile_100 blogs_card" item md={4} key={x._id}>
                  <Blog {...x} img={x.media} />
                </Grid>
              );
            })}
        </Grid>
      </Container>
    </>
  );
};

export default Blogs;
