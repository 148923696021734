import { SOCKET_API_ENGPOINTS } from "./API_Endpoints";
import axiosInstance from "../utils/axios";

export const joinChatRoom = (body) => {
  return axiosInstance
    .post(SOCKET_API_ENGPOINTS.joinChatRoom, body)
    .then((res) => res.data)
    .catch((err) => err);
};

export const getChatRooms = (params) => {
  return axiosInstance
    .get(`${SOCKET_API_ENGPOINTS.getChatRooms}?${params}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

export const getAllMessages = (params) => {
  return axiosInstance
    .get(`${SOCKET_API_ENGPOINTS.getMessages}?${params}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

export const getTheAllContactsOfChat = (user_id) => {
  return axiosInstance
    .get(
      `${SOCKET_API_ENGPOINTS.getContacts}?user_id=${user_id}&page=1&count=10000000&search`
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

export const uploadImageAndDoc = (body) => {
  console.log("sad", body);
  return axiosInstance
    .post(SOCKET_API_ENGPOINTS.uploadImgDoc, body, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
