import { Card, Container, Grid, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import ButtonComponent from "../../components/Button";
import { getImageUrl, getQueryParams } from "../../utils/methods";
import { useEffect, useState } from "react";
import {
  getBlogById,
  getBlogsList,
  getUserBlogById,
} from "../../services/admin/adminCmsServices";
import Loader from "../../components/loader";

const BlogDetail = () => {
  const location = useLocation();
  // define local state
  const [localState, setLocalState] = useState({
    data: null,
    relatedBlogData: [],
    loading: false,
  });

  // get blog by id
  const getBlogDetail = async () => {
    try {
      setLocalState((prev) => ({
        ...prev,
        loading: true,
      }));
      const response = await getUserBlogById(location.state.selectedBlogId);
      if (response?.status) {
        setLocalState((prev) => ({
          ...prev,
          data: response?.data,
          loading: false,
        }));
      }
    } catch (error) {
      console.log(error);
      setLocalState((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  // get related blog with excluding id

  const getRelatedBlogDetail = async () => {
    const queryParams = {
      exclude_id: location?.state?.selectedBlogId,
      count: 5,
    };

    const queryString = getQueryParams(queryParams);
    setLocalState((prev) => ({
      ...prev,
      loading: true,
    }));
    const response = await getBlogsList(queryString);
    if (response.status) {
      setLocalState((prev) => ({
        ...prev,
        relatedBlogData: response?.data[0]?.data,
        loading: false,
      }));
    }
  };

  useEffect(() => {
    if (location.state.selectedBlogId) {
      getBlogDetail();
      getRelatedBlogDetail();
    }
  }, [location.state.selectedBlogId]);

  // show loading

  if (localState.loading) {
    return <Loader />;
  }
  return (
    <Container maxWidth="xl">
      <Grid container className="mt-5" spacing={4}>
        <Grid item md={12}>
          <img
            src={getImageUrl(location?.state?.img, "blogs")}
            style={{ width: "100%", height: 500, borderRadius: 12 }}
            className="blog_img"
          />
        </Grid>
        <Grid item md={8}>
          <Typography variant="h4">
            {localState?.data && localState?.data?.title}
          </Typography>
          <div className="mt-3">
            <Typography
              fontSize={16}
              component={"p"}
              color="text.primary"
              mt={3}
              dangerouslySetInnerHTML={{
                __html: localState?.data && localState?.data?.current?.content,
              }}
              className="para_blog"
            />
          </div>
        </Grid>
        <Grid item md={4} mt={2} mb={3}>
          <Typography variant="h6">Related Blogs</Typography>
          {localState?.relatedBlogData &&
            localState?.relatedBlogData.map((curElm) => (
              <Card
                elevation={0}
                style={{ border: "1px solid #DBDBDB", borderRadius: 5 }}
                className="p-3 mt-3"
                key={curElm?._id}
              >
                <div className="d-flex align-items-center gap-2">
                  <img
                    src={getImageUrl(curElm?.media, "blogs")}
                    style={{ height: 70, width: 70, borderRadius: 5 }}
                  />
                  <Typography variant="body2">{curElm?.title}</Typography>
                </div>
              </Card>
            ))}
        </Grid>
      </Grid>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <ButtonComponent
          sx={{
            color: "#13556D",
            border: "1px solid #C8D736",
            background: "white",
            fontWeight: 500,
          }}
          title="Previous Blog"
        />
        <ButtonComponent
          sx={{
            color: "#13556D",
            border: "1px solid #C8D736",
            background: "white",
            fontWeight: 500,
          }}
          title="Next Blog"
        />
      </div>

      <div className="d-flex flex_wrap_wrap justify-content-between align-items-center mb-3">
        <Card
          elevation={0}
          style={{
            border: "1px solid #DBDBDB",
            borderRadius: 5,
            maxWidth: 362,
          }}
          className="p-3 mt-3 related_blog"
        >
          {localState?.data?.previous ? (
            <div className="d-flex align-items-center gap-2">
              <img
                src={getImageUrl(localState?.data?.previous?.media, "blogs")}
                style={{ height: 70, width: 70, borderRadius: 5 }}
              />
              <Typography variant="body2">
                {localState?.data && localState?.data?.previous?.title}
              </Typography>
            </div>
          ) : (
            <Typography variant="body2">Previous blog not find</Typography>
          )}
        </Card>
        <Card
          elevation={0}
          style={{
            border: "1px solid #DBDBDB",
            borderRadius: 5,
            maxWidth: 362,
          }}
          className="p-3 mt-3 related_blog"
        >
          <div className="d-flex align-items-center gap-2">
            <img
              src={getImageUrl(localState?.data?.next?.media, "blogs")}
              style={{ height: 70, width: 70, borderRadius: 5 }}
            />
            <Typography variant="body2">
              {localState?.data && localState?.data?.next?.title}{" "}
            </Typography>
          </div>
        </Card>
      </div>
    </Container>
  );
};

export default BlogDetail;
