import {
  Card,
  Chip,
  Container,
  Grid,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ButtonComponent from "../../../components/Button";
import PlacesAutoComplete from "../../../components/PlacesAutocomplete";
import Property from "../../../components/Property";
import { API_Endpoints } from "../../../services/API_Endpoints";
import useDebounceSearch from "../../../services/globalSearchService";
import {
  MultipleFilterProperhty,
  MultipleFilterProperty,
} from "../../../services/properties.services";
import { FILTER_CHIP } from "../../../utils/appConstants";
import { baseURL } from "../../../utils/axios";
import {
  formatCurrency,
  replaceHttpImgUrl,
} from "../../../utils/helperFunctionForValidte";
import { RetrieveLocalUser } from "../../../utils/authService";
import { propertyStatus } from "../../../utils/CONSTANTS";
const SearchProperties = () => {
  const { state } = useLocation();
  const user = RetrieveLocalUser("user");
  const [minmaxValue, setMinMaxValue] = useState({
    min: 1000,
    max: 4000000000,
    error: false,
  });
  const [latlng, setLatLng] = React.useState({ lat: "", lng: "" });
  const [filterProperties, setFilterProperties] = useState({
    latitude: "",
    longitude: "",
    maxDistance: 200,
    budget: {
      min: 0,
      max: 0,
    },
    type: "",
    address: "",
    searchValue: "",
  });
  console.log("state", state);
  const formik = useFormik({
    initialValues: {
      address: "",
    },
  });
  const [searchedProperties, setSearchedProperties] = useState([]);
  const [loading, setLoading] = useState({
    btnLoader: false,
    globalLoader: false,
  });

  const [chips, setChips] = useState(FILTER_CHIP);
  console.log(state, "state");
  useEffect(() => {
    if (typeof state != "string") {
      setFilterProperties((prev) => ({
        ...prev,
        state,
      }));
    }
    formik.setValues({
      address: state.address,
    });
  }, [state]);

  const handleSelectedFilter = (currentIndex, objectName) => {
    let updatedChips = [...chips[objectName]];
    if (objectName == "availability" || objectName == "rooms") {
      updatedChips?.forEach((item, index) => {
        if (index === currentIndex) {
          item.isSelected = !item.isSelected;
        } else {
          item.isSelected = false;
        }
      });
    } else {
      updatedChips[currentIndex].isSelected =
        !updatedChips[currentIndex]?.isSelected;
    }
    setChips((pre) => {
      return { ...pre, [objectName]: updatedChips };
    });
  };
  console.log("chips", chips);

  let CustomChip = ({ label, isSelected, index, titleName }) => (
    <Chip
      onClick={() => handleSelectedFilter(index, titleName)}
      sx={{
        border: "1px solid #F1F1F1",
        background: isSelected ? "#13556D" : "white",
        margin: "14px 6px 2px 0px",
        cursor: "pointer",
        fontSize: 14,
        fontWeight: 400,
        color: isSelected ? "white" : "#395158",
      }}
      label={label}
    />
  );
  // manage the filter url
  const setTheFilterUrl = () => {
    const getSelectedValues = (items, key) => {
      return items
        ?.filter((item) => item.isSelected)
        .map((item) => item[key])
        .toString();
    };

    const categoryFilterStr = {
      propertyPurpose: getSelectedValues(chips?.propertyPurpose, "value"),
      propertyType: getSelectedValues(chips?.propertyType, "title"),
      availability: getSelectedValues(chips?.availability, "value"),
      furnishingType: getSelectedValues(chips?.furnishing, "title"),
      communityType: getSelectedValues(chips?.typeOfCommunity, "title"),
      rooms: chips?.rooms?.filter((item) => item.isSelected)[0],
      inDemand: chips?.inDemand?.filter((item) => item.isSelected)[0]
        ?.isSelected,
    };

    console.log("Category Filter:", categoryFilterStr, filterProperties);

    const getFilterValue = (value, defaultValue = "") => value || defaultValue;
    const getAvailabilityFilter = (availability) => {
      if (availability === "29") {
        return { max: "", min: availability };
      }
      return { max: availability, min: "" };
    };

    const { max: maxAvailability, min: minAvailability } =
      getAvailabilityFilter(categoryFilterStr.availability);

    const baseURL = `${
      API_Endpoints.property.FILTER_PROPERTY
    }?category=${getFilterValue(
      categoryFilterStr.propertyPurpose,
      typeof state === "string" ? state : ""
    )}&type=${getFilterValue(categoryFilterStr.propertyType)}&min_rent=${
      minmaxValue.min
    }&max_rent=${minmaxValue.max}&furnishingType=${getFilterValue(
      categoryFilterStr.furnishingType
    )}&communityType=${getFilterValue(
      categoryFilterStr.communityType
    )}&max_availability=${maxAvailability}&min_availability=${minAvailability}&min_rooms=${getFilterValue(
      categoryFilterStr.rooms?.minValue
    )}&latitude=${latlng.lat}&longitude=${latlng.lng}&search=${
      filterProperties?.searchValue
    }&user_id=${
      user?._id ? user?._id : ""
    }&sort_key=rented&sort_order=asc&approval_status=${
      propertyStatus.approved
    }&inDemand=${categoryFilterStr?.inDemand}`;

    const maxRoomsValue = getFilterValue(categoryFilterStr.rooms?.maxValue);
    const url =
      maxRoomsValue !== "" ? `${baseURL}&max_rooms=${maxRoomsValue}` : baseURL;
    return url;
  };

  // get the multiple filter api call
  const getTheMultipleFilterApi = async () => {
    const getUrl = setTheFilterUrl();
    setLoading((prev) => ({
      ...prev,
      btnLoader: true,
    }));
    const getData = await MultipleFilterProperty(getUrl);
    if (getData?.status) {
      setSearchedProperties(getData?.data[0]?.data);
    }
    setLoading((prev) => ({
      ...prev,
      btnLoader: false,
    }));
    console.log("++", getData);
  };
  console.log("filter", filterProperties);

  useDebounceSearch(
    filterProperties?.searchValue ? filterProperties?.searchValue : "ss",
    !minmaxValue?.error ? getTheMultipleFilterApi : () => {}, // if minmum value is greater then the maximum value
    1000,
    {
      chips: chips,
      minMaxValue: minmaxValue,
    }
  );
  useEffect(() => {
    if (state?.budget?.max != undefined && state?.budget?.min != undefined)
      setMinMaxValue((prev) => ({
        ...prev,
        max: state?.budget?.max == 0 ? 4000000000 : state?.budget?.max,
        min: state?.budget?.min == 0 ? 1000 : state?.budget?.min,
      }));
    console.log("state", state);
    if (state.type) {
      setChips((prev) => ({
        ...prev,
        propertyType: prev.propertyType?.map((item) => {
          return {
            ...item,
            isSelected: item.title == state?.type,
          };
        }),
      }));
    }
    if (state.latitude && state.longitude) {
      setLatLng({
        lat: state.latitude,
        lng: state.longitude,
      });
    }
    if (typeof state === "string") {
      setChips((prev) => ({
        ...prev,
        propertyPurpose: prev.propertyPurpose?.map((item) => {
          return {
            ...item,
            isSelected: item.value == state,
          };
        }),
      }));
    }
    if (state?.inDemand) {
      setChips((prev) => ({
        ...prev,
        inDemand: prev.inDemand?.map((item) => {
          return {
            ...item,
            isSelected:  state?.inDemand,
          };
        }),
      }));
    }
  }, [state]);
  console.log("onchange", state);

  return (
    <Container maxWidth="xl">
      <Typography
        className="mb-1 search_title mt-4"
        fontWeight={700}
        fontSize={40}
        color={"text.primary"}
      >
        Search Your Dream Home
      </Typography>
      <Typography fontWeight={500} fontSize={24} color="text.secondary">
        Where every search leads to your perfect space!
      </Typography>

      <div
        // style={{ width: "100%" }}
        className="mt-3"
      >
        <Card
          elevation={0}
          sx={{
            position: "relative",
            zIndex: 1,
            boxShadow: "0px 2px 0px 4px white",
            overflow: "visible",
          }}
        >
          <div className="row d-flex  align-items-center p-3 renter-form">
            <div className="col-md-3">
              <FormikProvider value={formik}>
                <PlacesAutoComplete
                  setLatLng={setLatLng}
                  value={formik.values.address}
                  setFieldValue={(key, value) =>
                    formik.setFieldValue(key, value)
                  }
                  key={"autoComplete"}
                  placeholder="Location"
                />
              </FormikProvider>
            </div>
            <div className="col-md-3">
              <TextField
                label="Search by property name"
                type="search"
                value={filterProperties?.searchValue}
                onChange={(e) => {
                  setFilterProperties((prev) => ({
                    ...prev,
                    searchValue: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="col-md-3">
              <ButtonComponent
                onClick={(e) => getTheMultipleFilterApi()}
                sx={{ width: 126 }}
                title={"Search"}
                loading={loading.btnLoader}
                disabled={loading.btnLoader}
              />
            </div>
          </div>
        </Card>

        <div className="d-flex mt-3 gap-2">
          <Typography fontSize={18} fontWeight={500} color={"text.secondary"}>
            Searching For:
          </Typography>
          <Typography
            fontStyle={"italic"}
            color="#596E73"
            fontFamily="Rubik, sans-serif"
            fontSize={18}
          >
            {formik.values.address
              ? formik.values.address
              : filterProperties.address}
          </Typography>
        </div>

        <Grid container spacing={4} mt={1} mb={3}>
          <Grid item md={4}>
            <Card
              elevation={0}
              sx={{ border: "1px solid #F1F1F1", p: 3, borderRadius: 2 }}
            >
              <div>
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  color="text.secondary"
                >
                  Property Purpose
                </Typography>
                {chips.propertyPurpose.map((x, i) => {
                  return (
                    <CustomChip
                      label={x.title}
                      titleName={"propertyPurpose"}
                      index={i}
                      isSelected={x.isSelected}
                      key={i}
                    />
                  );
                })}
              </div>
              <div className="mt-3">
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  color="text.secondary"
                >
                  Property Type
                </Typography>
                {chips.propertyType.map((x, i) => {
                  return (
                    <CustomChip
                      label={x.title}
                      isSelected={x.isSelected}
                      index={i}
                      titleName={"propertyType"}
                      key={i}
                    />
                  );
                })}
              </div>
              <div className="mt-3">
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  color="text.secondary"
                >
                  Availability
                </Typography>
                {chips.availability.map((x, i) => {
                  return (
                    <CustomChip
                      label={x.title}
                      isSelected={x.isSelected}
                      index={i}
                      titleName={"availability"}
                      key={i}
                    />
                  );
                })}
              </div>

              <div className="mt-3">
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  color="text.secondary"
                >
                  Budget
                </Typography>

                <Slider
                  valueLabelDisplay="on"
                  track="inverted"
                  size="small"
                  min={10000}
                  max={4000000}
                  color="secondary"
                  // aria-labelledby="track-inverted-range-slider"
                  value={[minmaxValue.min, minmaxValue.max]}
                  // marks={marks}
                  onChange={(e) => {
                    console.log("fgdge", e.target.value, minmaxValue);
                    setMinMaxValue({
                      min: e.target.value[0],
                      max: e.target.value[1],
                    });
                  }}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <TextField
                    fullWidth
                    size="small"
                    type="text"
                    sx={{ maxWidth: 120 }}
                    variant="outlined"
                    value={formatCurrency(minmaxValue?.min, true)}
                    onChange={(e) => {
                      setMinMaxValue((prev) => ({
                        ...prev,
                        min: Number(e.target.value.replaceAll(/,/g, "")),
                        error:
                          Number(e.target.value.replaceAll(/,/g, "")) >
                          prev.max,
                      }));
                    }}
                    focused
                    label="Minimum"
                  />

                  <TextField
                    fullWidth
                    size="small"
                    type="text"
                    sx={{ maxWidth: 120 }}
                    variant="outlined"
                    value={formatCurrency(minmaxValue?.max, true)}
                    onChange={(e) => {
                      setMinMaxValue((prev) => ({
                        ...prev,
                        max: Number(e.target.value.replaceAll(/,/g, "")),
                        error:
                          Number(e.target.value.replaceAll(/,/g, "")) <
                          prev.min,
                      }));
                    }}
                    focused
                    label="Maximum"
                  />
                </div>
                {minmaxValue.error && (
                  <span className="d-block text-danger">
                    Minimum amount should not be greater than Maximum amount
                  </span>
                )}
              </div>
              <div className="mt-3">
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  color="text.secondary"
                >
                  Number of rooms
                </Typography>
                {chips.rooms.map((x, i) => {
                  return (
                    <CustomChip
                      label={x.title}
                      titleName={"rooms"}
                      index={i}
                      isSelected={x.isSelected}
                      key={i}
                    />
                  );
                })}
              </div>
              <div className="mt-3">
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  color="text.secondary"
                >
                  Furnishing
                </Typography>
                {chips.furnishing.map((x, i) => {
                  return (
                    <CustomChip
                      label={x.title}
                      isSelected={x.isSelected}
                      index={i}
                      titleName={"furnishing"}
                      key={i}
                    />
                  );
                })}
              </div>
              <div className="mt-3">
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  color="text.secondary"
                >
                  Type of community
                </Typography>
                {chips.typeOfCommunity.map((x, i) => {
                  return (
                    <CustomChip
                      label={x.title}
                      isSelected={x.isSelected}
                      index={i}
                      titleName={"typeOfCommunity"}
                      key={i}
                    />
                  );
                })}
              </div>
              <div className="mt-3">
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  color="text.secondary"
                >
                  Property In Demand
                </Typography>
                {chips.inDemand.map((x, i) => {
                  return (
                    <CustomChip
                      label={x.title}
                      isSelected={x.isSelected}
                      index={i}
                      titleName={"inDemand"}
                      key={i}
                    />
                  );
                })}
              </div>
            </Card>
          </Grid>
          <Grid item md={8}>
            <Grid container spacing={4}>
              {searchedProperties.length === 0 ? (
                <h2 className="ms-5 mt-5">No Property Found</h2>
              ) : (
                searchedProperties.map((x, i) => {
                  return (
                    <Grid
                      item
                      md={4}
                      sm={12}
                      key={i}
                      className="phone-full-width"
                    >
                      <Property
                        isFavourite={false}
                        {...x}
                        img={replaceHttpImgUrl(x?.images && x?.images[0]?.url)}
                      />
                    </Grid>
                  );
                })
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default SearchProperties;
