import { Chip, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { theme } from "../../../assets/theme";
import { validateEmail } from "../../../utils/helperFunctionForValidte";
import ButtonComponent from "../../../components/Button";
import { shareTheReferralCode } from "../../../services/referAndEarnServices";
import { NotificationManager } from "react-notifications";
export default function ReferEarnModal({ open, setOpen }) {
  const [loading, setLoading] = React.useState(false);
  const [emailInputData, setEmailInputData] = React.useState({
    inputValue: { value: "", warning: false },
    emailData: [],
    erroMessage: false,
  });

  // handle change to get the input value
  // with in same function added input value push to the emailINputData key emailData by press the enter key
  const handleChange = (e) => {
    const { value, name } = e.target;
    setEmailInputData((prev) => ({
      ...prev,
      inputValue: { value: value, warning: validateEmail(value) },
      erroMessage: false,
    }));

    // only work when enter is press
    if (e.key === "Enter" && !validateEmail(value)) {
      let data = [...emailInputData.emailData];
      data.push(value);
      setEmailInputData((prev) => ({
        ...prev,
        emailData: data,
        inputValue: { value: "", warning: false },
        erroMessage: false,
      }));
    }
  };

  /**
   * remove the data for the emailINputstate  for particular index
   * @param {number} index of the give value clicked
   */
  const handleDeleteValue = (index) => {
    let data = [...emailInputData.emailData];
    data.splice(index, 1);
    setEmailInputData((prev) => ({
      ...prev,
      emailData: data,
      erroMessage: false,
    }));
  };

  // share button api intigration
  const onClickOnShareBtn = async () => {
    if (
      emailInputData?.emailData.length === 0 ||
      emailInputData?.inputValue?.warning
    ) {
      return setEmailInputData((prev) => ({
        ...prev,
        erroMessage: true,
      }));
    }
    setLoading(true);
    const body = {
      share_via: "email",
      refer_to: emailInputData?.emailData,
    };
    const postData = await shareTheReferralCode(body);
    if (postData?.status) {
      setOpen(false);
      NotificationManager.success("", "Referral code share successFully");
      setEmailInputData({
        inputValue: { value: "", warning: false },
        emailData: [],
        erroMessage: false,
      });
    } else {
      NotificationManager.error("", postData?.message);
    }
    setLoading(false);
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        setEmailInputData({
          inputValue: { value: "", warning: false },
          emailData: [],
          erroMessage: false,
        });
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{
          minWidth: "600px",
        }}
      >
        <Typography
          fontSize={32}
          fontWeight={700}
          color={theme.palette.text.primary}
          textAlign={"center"}
        >
          Refer and earn
        </Typography>
      </DialogTitle>
      <DialogContent className="mt-4">
        <div className="input-field-multiple">
          <div className={"container-chip"}>
            {emailInputData?.emailData &&
              emailInputData?.emailData?.map((item, index) => (
                <Chip
                  size="small"
                  onDelete={() => handleDeleteValue(index)}
                  label={item}
                />
              ))}
          </div>
          <TextField
            onChange={handleChange}
            value={emailInputData?.inputValue?.value}
            name="inputValue"
            id="outlined-basic"
            label=""
            variant="standard"
            onKeyDown={handleChange}
            fullWidth
            className="mt-4"
            placeholder="enter email"
          />
          {emailInputData?.inputValue?.warning && (
            <span className="text-danger">Enter valid email</span>
          )}
        </div>
        {emailInputData?.erroMessage && (
          <span className="text-danger">
            Enter email's for share the referral code
          </span>
        )}
        <div className="mt-4 text-center">
          <ButtonComponent
            title={"Share"}
            className={""}
            loading={loading}
            disabled={loading}
            onClick={onClickOnShareBtn}
          />
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}></DialogActions>
    </Dialog>
  );
}
