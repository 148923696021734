import { Box, Chip, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LocationIcon } from "../../../assets/icons";
import { theme } from "../../../assets/theme";
import LandlordCommonModal from "../../../components/landlordCommonModal";
import { API_Endpoints } from "../../../services/API_Endpoints";
import {
  CancelInspectionRequestByRenterService,
  GetTheInspectionReqForRelatedProperty,
} from "../../../services/inspections.services";
import { GetPropertyDetailService } from "../../../services/properties.services";
import { inspectionApplicationRequestStatus } from "../../../utils/appConstants";
import { fDate } from "../../../utils/format-time";
import {
  formatCurrency,
  replaceHttpImgUrl,
} from "../../../utils/helperFunctionForValidte";
import InspectionApplicationRequestModal from "./inspectionRequestModal";
export default function InspectionPropertyRequest() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [apiNotCallOnCancel, setApiNotCallOnCancel] = useState("");
  const [cancelModal, setCancelModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [acceptModal, setAcceptModal] = useState(false);
  const [inspectioReqPropertyData, setInspectionReqPropertyData] = useState({
    inspectionData: [],
    propertyDetail: {},
  });

  // get the respected inspection req related to property
  const getTheInspectionReq = async () => {
    const getData = await GetTheInspectionReqForRelatedProperty(
      `${API_Endpoints.inspections.GET_INSPECTION_LIST}s?inspectionStatus=initiated&propertyID=${id}&page=1&count=10&search`
    );
    console.log("data", getData);
    if (getData?.status) {
      setInspectionReqPropertyData((prev) => ({
        ...prev,
        inspectionData: getData?.data[0]?.data,
      }));
    }
  };

  // get the property detail
  const GetPropertyDetailById = async () => {
    const response = await GetPropertyDetailService(id);
    if (response.status) {
      setInspectionReqPropertyData((prev) => ({
        ...prev,
        propertyDetail: response?.data,
      }));
    }
  };

  // accept the request api initgration
  const acceptTheRequest = async (inspectionData) => {
    setLoading(true);
    const body = {
      status: inspectionApplicationRequestStatus?.accept,
      inspectionID: inspectionData?._id,
    };
    const acceptData = await CancelInspectionRequestByRenterService(body);
    console.log("accept ", acceptData);
    setModalData(inspectionData);
    setLoading(false);
    setAcceptModal(true);
  };

  useEffect(() => {
    if (!cancelModal && !acceptModal) {
      // this check is implemented if user click on cancel button and outside of the modal
      if (apiNotCallOnCancel === "cancel") {
        return;
      }
      getTheInspectionReq();
    }
  }, [cancelModal, acceptModal]);
  console.log(
    "modalData",
    `You have accepted the inspection request of ${
      modalData?.RenterDetails?.fullName
    } for ${modalData?.propertyName} on ${fDate(modalData?.inspectionDate)}`
  );
  useEffect(() => {
    GetPropertyDetailById();
  }, []);

  console.log("proper", inspectioReqPropertyData);
  return (
    <Container maxWidth="xl" className="my-3">
      <Box>
        <Typography
          fontSize={40}
          fontWeight={700}
          color={theme.palette.text.primary}
          marginBottom={"20px"}
          className="title_page pt-4 phone-heading-30"
        >
          Inspection Request (
          {inspectioReqPropertyData?.propertyDetail?.propertyData?.propertyName}
          )
        </Typography>
      </Box>

      <Grid container spacing={3} p={2}>
        <Grid item md={5}>
          <Grid item md={12}>
            <Box
              sx={{
                backgroundImage: `url(${replaceHttpImgUrl(
                  inspectioReqPropertyData?.propertyDetail?.propertyData
                    ?.images[0]?.url
                )})`,
                width: "100%",
                height: 258,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                objectFit: "cover",
                p: 1,
              }}
            >
              {inspectioReqPropertyData?.propertyDetail?.propertyData
                ?.rent_period_end && (
                <Chip
                  label={`Available`}
                  className="bg-white"
                  sx={{
                    color: "#009DA6",
                    fontWeight: "bold",
                    fontSize: 16,
                  }}
                />
              )}
            </Box>
            {/* <img className="w-100" src={x.img} /> */}
          </Grid>
          <Grid item md={12}>
            <Typography
              className="py-1"
              fontSize={24}
              color="#031013"
              fontWeight={"bold"}
            >
              {
                inspectioReqPropertyData?.propertyDetail?.propertyData
                  ?.propertyName
              }
            </Typography>
            <div className="d-flex mb_align_center gap-1 py-1 mb-2">
              <LocationIcon />
              <Typography color="#8D9B9F" fontWeight={200}>
                {
                  inspectioReqPropertyData?.propertyDetail?.propertyData
                    ?.address?.addressText
                }
              </Typography>
            </div>
            {/* <Rating value={4} /> */}
            <Typography
              className="py-2"
              fontWeight={"bold"}
              color="#031013"
              fontSize={18}
            >
              {formatCurrency(
                inspectioReqPropertyData?.propertyDetail?.propertyData?.rent
              )}
              /{" "}
              {inspectioReqPropertyData?.propertyDetail?.propertyData?.rentType}
            </Typography>
          </Grid>
        </Grid>

        <Grid item md={7}>
          <Typography
            fontSize={24}
            fontWeight={"bold"}
            color={"text.secondary"}
          >
            Requests
          </Typography>
          {inspectioReqPropertyData?.inspectionData?.map((inspectionData) => (
            <Grid container className="pt-1">
              <Grid className="applications_card">
                <Grid className="left_detail">
                  <div className="img_block">
                    <img
                      src={inspectionData?.RenterDetails?.picture}
                      alt=""
                      style={{
                        width: "150px",
                        height: "150px",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        navigate(
                          `/inspection-requests-detail/${inspectionData?._id}`
                        )
                      }
                    />
                  </div>
                  <div className="app_person_details">
                    <h3>{inspectionData?.RenterDetails?.fullName}</h3>
                    <h5>{inspectionData?.message}</h5>
                    <p>{inspectionData?.RenterDetails?.phone}</p>
                    <span>
                      {fDate(inspectionData?.inspectionDate)}
                      {"  "}
                      {inspectionData?.inspectionTime}
                    </span>
                  </div>
                </Grid>
                <Grid className="d-flex right_btns text-end">
                  <button
                    onClick={() => {
                      setCancelModal(true);
                      setModalData(inspectionData);
                    }}
                    className="btn_common reject"
                  >
                    Reject
                  </button>
                  <button
                    onClick={() => acceptTheRequest(inspectionData)}
                    className="btn_common"
                  >
                    Accept
                  </button>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <LandlordCommonModal
        open={cancelModal}
        setOpen={setCancelModal}
        heading={"Reason for Rejection"}
        heading2={"Reason for Rejection"}
        detailData={{
          ...modalData,
          property_info: [
            {
              propertyName: modalData?.propertyName,
            },
          ],
        }}
        apiNotCallOnCancel={setApiNotCallOnCancel}
        notificationText={`A notification has  been sent to ${modalData?.RenterDetails?.fullName} regarding his Inspection Request`}
      />
      <InspectionApplicationRequestModal
        open={acceptModal}
        setOpen={setAcceptModal}
        heading={"Request Accepted"}
        heading2={`You have accepted the inspection request of ${
          modalData?.RenterDetails?.fullName
        } for “${inspectioReqPropertyData?.propertyDetail?.propertyData?.propertyName}” on ${fDate(
          modalData?.inspectionDate
        )}`}
        Customfunction={() => {
          navigate("/inspection-requests");
          setApiNotCallOnCancel("");
        }}
      />
    </Container>
  );
}
