import { useEffect, useState, useCallback } from "react";
import { socket } from "../utils/socketIo";
import { RetrieveLocalUser } from "../utils/authService";
import {
  getAllMessages,
  getChatRooms,
  joinChatRoom,
} from "../services/socket.services";
import { getQueryParams } from "../utils/methods";
import debounce from "lodash.debounce";
import { ROLES } from "../utils/CONSTANTS";

const useSocket = () => {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [messages, setMessages] = useState([]);
  const [chatList, setChatList] = useState([]);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [msgReceiver, setMsgReceiver] = useState(null);
  const [chatListLoader, setChatListLoader] = useState(false);
  const [messageListLoader, setMessageListLoader] = useState(false);
  const [typingStatus, setTypingStatus] = useState({
    isTyping: false,
    room_id: "",
    user_id: "",
  });
  const [originalChatList, setOriginalChatList] = useState([]);
  const userData = RetrieveLocalUser("user");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSelectedUser, setCurrentSelectedUser] = useState("");

  const getFilteredChatList = (data, userId) => {
    if (Array.isArray(data)) {
      return data.map((item) => ({
        ...item,
        user_details: item.user_details.find((user) => user._id !== userId),
      }));
    } else {
      return {
        ...data,
        user_details: data.user_details.find((user) => user._id !== userId),
      };
    }
  };

  const onChatSearchHandler = (e) => {
    const { value } = e.target;
    const searchTerm = value.trim().toLowerCase();

    if (!searchTerm) {
      setChatList(originalChatList);
    } else {
      const filteredChatList = originalChatList.filter((chat) =>
        chat.user_details.fullName.toLowerCase().includes(searchTerm)
      );
      setChatList(filteredChatList);
    }
  };

  const fetchMessages = useCallback(
    debounce(async (roomId, page, loader = true) => {
      if (loader) {
        setMessageListLoader(true);
      }
      try {
        if (!roomId) return;
        const queryParams = {
          page,
          count: 20,
          room_id: roomId,
        };
        const queryString = getQueryParams(queryParams);
        const response = await getAllMessages(queryString);
        if (response.status) {
          const sortedMessages =
            response.data[0]?.data.sort(
              (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
            ) || [];
          setMessages((prevMessages) =>
            [...prevMessages, ...sortedMessages].sort(
              (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
            )
          );
        } else {
          console.error("Failed to fetch messages:", response.error);
        }
      } catch (error) {
        console.error("Error occurred while fetching messages:", error);
      } finally {
        if (loader) {
          setMessageListLoader(false);
        }
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (currentRoom) {
      fetchMessages(currentRoom, currentPage, false);
    }
  }, [currentRoom, currentPage, fetchMessages]);

  const fetchMoreData = () => {
    setCurrentPage((prev) => prev + 1);
  };
  // Join chat room handler these are for  user particaular chating from the another user
  const joinChatRoomHandler = useCallback(
    (room) => {
      if (room) {
        socket.emit("join-room", { room_id: room });
        setCurrentRoom(room);
        setMessages([]); // Clear existing messages when joining a new room
        setCurrentPage(1); // Reset page number on room change
        fetchMessages(room, 1);
      }
    },
    [fetchMessages]
  );
  // Get all chats from the left side bar
  const getAllChats = useCallback(
    async (chatWith) => {
      setChatListLoader(true);
      try {
        const queryParams = {
          page: 1,
          count: 10,
          user_id: userData._id,
        };
        const queryString = getQueryParams(queryParams);
        const result = await getChatRooms(queryString);
        if (result.status) {
          const filteredData = getFilteredChatList(
            result.data[0]?.data,
            userData._id
          );
          console.log("filteredData", filteredData);
          setOriginalChatList(filteredData); // Save the original list for search

          if (chatWith) {
            const selected = filteredData.find(
              (item) => item.user_details._id === chatWith
            );
            setSelectedChat(selected?.user_details || null);
            joinChatRoomHandler(selected?._id);
          } else {
            setSelectedChat(filteredData[0]?.user_details || null);
            joinChatRoomHandler(filteredData[0]?._id);
          }
          setChatList(filteredData);
        }
      } catch (error) {
        console.error("Error fetching chat rooms:", error);
      } finally {
        setChatListLoader(false);
      }
    },
    [userData._id, joinChatRoomHandler]
  );

  // Join chat handler when user intitally come's to the chat
  const joinChatHandler = useCallback(
    async (chatWith) => {
      setMsgReceiver(chatWith);
      setChatListLoader(true);
      try {
        const body = {
          user_id: userData._id,
          chat_with: chatWith,
          is_admin: false,
        };

        if (userData.role !== ROLES.renter) {
          body.admin_id = null; // Example admin ID
        }

        const response = await joinChatRoom(body);
        console.log("chatsres", response);
        if (response.status) {
          getAllChats(chatWith);
        }
      } catch (error) {
        console.error("Error joining chat room:", error);
      } finally {
        setChatListLoader(false);
      }
    },
    [userData._id, userData.role, getAllChats]
  );

  //Effect for joining chat room
  useEffect(() => {
    console.log(currentRoom, "====Current ROmm");
    if (currentRoom) {
      joinChatRoomHandler(currentRoom);
    }
  }, [currentRoom, joinChatRoomHandler]);

  useEffect(() => {
    if (currentSelectedUser) {
      joinChatHandler(currentSelectedUser);
    }
  }, [currentSelectedUser]);

  const onUserOffline = () => {
    console.log("useroffline now");
    // socket.emit("user-offline", data);
  };

  // Effect for socket events
  useEffect(() => {
    const onConnect = () => setIsConnected(true);
    const onDisconnect = () => setIsConnected(false);
    const onMessageReceived = (data) => {
      const newMessage = data.data;
      setMessages((prevMessages) =>
        [...prevMessages, newMessage].sort(
          (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
        )
      );
    };
    const onTyping = (data) => {
      console.log("tyopingsdfs", data);
      setTypingStatus(data?.data);
    };

    const onPrivateRoomUpdate = (data) => {
      const result = data.data;
      const filteredData = getFilteredChatList(result, userData._id);
      setChatList((prevChatList) => {
        const existingChatIndex = prevChatList?.findIndex(
          (chat) => chat._id === result._id
        );
        const existingChat = prevChatList.find(
          (chat) => chat._id === result._id
        );
        console.log("findindersxz", existingChatIndex);
        console.log("chat", existingChat, prevChatList);
        if (existingChatIndex >= 0) {
          let data = [...prevChatList];
          // remove the current chating and move them at the top
          data.splice(existingChatIndex, 1);
          // just shift the cuurent chatting user at top
          data.unshift({ ...existingChat, ...filteredData });
          return data;
        } else {
          // If chat doesn't exist, add the new chat
          return [...prevChatList, filteredData];
        }
      });
    };

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("new-message", onMessageReceived);
    socket.on("typing", onTyping);
    socket.on("private-room-updated", onPrivateRoomUpdate);
    socket.on("user-offline", onUserOffline);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("new-message", onMessageReceived);
      socket.off("typing", onTyping);
      socket.off("private-room-updated", onPrivateRoomUpdate);
      socket.off("user-offline", onUserOffline);
    };
  }, [userData._id]);

  const sendMessage = (message,message_type) => {
    if (message && currentRoom) {
      socket.emit("new-message", {
        room_id: currentRoom,
        content: message,
        media: message,
        message_type: message_type,
        reciever_id: msgReceiver || selectedChat?._id,
      });
    }
  };

  const sendTypingStatus = (isTyping) => {
    console.log("Sending typing status:", { roomId: currentRoom, isTyping });
    if (currentRoom && socket.connected) {
      socket.emit("typing", {
        room_id: currentRoom,
        isTyping,
      });
    }
  };
  console.log("id",selectedChat)

  return {
    isConnected,
    messages,
    chatList,
    selectedChat,
    joinChatHandler,
    joinChatRoomHandler,
    setSelectedChat,
    sendMessage,
    sendTypingStatus,
    setCurrentRoom,
    chatListLoader,
    messageListLoader,
    getAllChats,
    typingStatus,
    onChatSearchHandler,
    onUserOffline,
    fetchMoreData,
    setChatList,
    setChatListLoader,
    setCurrentSelectedUser,
  };
};

export default useSocket;
