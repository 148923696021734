import axiosInstance from "../utils/axios";
import { API_Endpoints } from "./API_Endpoints";

export const getCommissionList = (param) => {
  const { page } = param;
  return axiosInstance
    .get(
      `${API_Endpoints.propertyManger.commissionList}?page=${page}&count=10&search&type=rent`
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
