import { Card, Grid, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { InviteRenterIcon, TelePhone } from "../../../assets/svg";
import { myRenterSlice } from "../../../redux/my-renters/myRentersSlice";

export default function RenterCard({ cardData, pastRenter = false }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log("cardata", cardData);
  return (
    <Grid item md={4} className="mobile_col_renter_card">
      <Card
        className="p-4"
        sx={{
          borderRadius: 3,
          boxShadow: "5px 5px 19px 4px #0000000d",
          backgroundColor: "#fff",
        }}
      >
        <Grid className="mobile_renter_card" container spacing={4}>
          <Grid
            item
            md={3}
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(
                myRenterSlice.actions.setTheStatusOfPastRenter(
                  pastRenter ? true : false
                )
              );
            
              navigate(`/my-renters-detail/${cardData?._id}`);
              //  dispatch()
            }}
          >
            <img
              style={{ height: "80px", width: "80px", borderRadius: "50%" }}
              src={cardData?.picture}
            />
          </Grid>
          <Grid item md={9}>
            <div className="d-flex justify-content-between align-items-center">
              <Typography color={"#13556D"} fontWeight={700} fontSize={18}>
                {cardData?.fullName}
              </Typography>
              {cardData?.renterActive ? (
                <Link to="/chat">
                  <Typography color={"#C8D736"} fontWeight={700} fontSize={14}>
                    Chat Now
                  </Typography>
                </Link>
              ) : null}
            </div>
            <Typography
              className="mt-3 mb-3"
              color={"#8D9B9F"}
              fontWeight={400}
              fontSize={16}
            >
              <span style={{ marginRight: "10px" }}>{TelePhone()}</span>
              {cardData?.phone}
            </Typography>
            <Typography
              className="mt-3 mb-3"
              color={"#8D9B9F"}
              fontWeight={400}
              fontSize={16}
            >
              <span style={{ marginRight: "10px" }}>{InviteRenterIcon()} </span>
              {cardData?.email}
            </Typography>
            {/* <Typography
              className="mt-3 mb-3"
              color={"#8D9B9F"}
              fontWeight={400}
              fontSize={16}
            >
              <span style={{ marginRight: "10px" }}>{Calender()}</span>
              {fDate(cardData?.createdAt, "")}
            </Typography> */}
            {/* <Typography
              className="mt-3 mb-3"
              color={"#8D9B9F"}
              fontWeight={400}
              fontSize={16}
            >
              <span style={{ marginRight: "10px" }}>{LocationIcon()}</span>
              {cardData?.propertyDetails &&
                cardData?.propertyDetails[0]?.propertyName}
              ,
            </Typography> */}
          </Grid>
          {cardData?.renterActive ? (
            <div className="d-flex justify-content-between align-items-center w-100 px-3">
              <Typography
                color={"#ED1717"}
                fontWeight={400}
                fontSize={16}
                className="rentdue"
              >
                {/* Rent Due in: 2 days */}
              </Typography>
              {/* <ButtonComponent
                className="terminate-btn"
                onClick={() => setModal(true)}
                title={"Terminate Tenancy"}
              /> */}
            </div>
          ) : (
            <Typography ml={3} color={"#07262E"} fontWeight={400} fontSize={16}>
              {/* Left on: 5 June 2023 */}
            </Typography>
          )}
        </Grid>
      </Card>
      {/* <LandlordCommonModal
        open={modal}
        setOpen={setModal}
        heading={"Terminate tenancy"}
        heading2={"Terminate tenancy"}
        detailData={{
          RenterDetails: {
            fullName:
              cardData?.renterDetails && cardData?.renterDetails[0].fullName,
            picture:
              cardData?.renterDetails && cardData?.renterDetails[0]?.picture,
          },
          property_info: cardData?.propertyDetails,
          inspectionDate: fDate(cardData?.createdAt),
        }}
      /> */}
    </Grid>
  );
}
