import React, { useEffect, useState } from "react";
import faqBanner from "../../assets/images/faqBanner.png";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Container,
  Divider,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { theme } from "../../assets/theme";
import { DownIcon, UpIcon } from "../../assets/icons";
import { faqCategories } from "../../utils/CONSTANTS";
import { getFaqList } from "../../services/admin/adminCmsServices";
import Loader from "../../components/loader";
const tabs = [
  { label: "General", value: faqCategories.general },
  { label: "Service", value: faqCategories.service },
  { label: "Payments", value: faqCategories.payment },
  { label: "Rent", value: faqCategories.rent },
  { label: "Add Property", value: faqCategories.property },
  { label: "Refund", value: faqCategories.refund },
];
const FAQ = () => {
  const [tabValue, setTabValue] = useState(faqCategories.general);
  const [loading, setLoading] = useState(false);
  const [faqData, setFaqData] = useState([]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getFaqListApiIntigration = async () => {
    setLoading(true);
    const getData = await getFaqList(
      `page=1&count=10&seach&category=${tabValue}`
    );
    if (getData?.status) {
      const data = getData?.data[0]?.data?.map((item) => {
        return {
          ...item,
          collapse: false,
        };
      });
      setFaqData(data);
    }
    setLoading(false);
    console.log("getdatad", getData);
  };

  /**
   *
   * @param {Object} item  contain array particular object
   * @param {number} index
   */
  const collapseOpen = (item, index) => {
    let data = [...faqData];
    if (item) {
      data[index].collapse = !item?.collapse;
    }
    setFaqData(data);
  };

  useEffect(() => {
    getFaqListApiIntigration();
  }, [tabValue]);
  console.log("asdsadasd", faqData);
  return loading ? (
    <Loader />
  ) : (
    <div>
      <Box
        display={"flex"}
        px={6}
        alignItems={"center"}
        sx={{
          backgroundImage: `url(${faqBanner})`,
          width: "100%",
          backgroundSize: "cover",
          height: 497,
          '@media (max-width: 600px)': {
          height: '320px',  // 
        },
        }}
        className="faq_banner"
      >
        <div>
          <Typography
            fontSize={56}
            className="phone-heading-30"
            fontWeight={"bold"}
            color={theme.palette.text.primary}
          >
            Frequently
            <br /> Asked Questions
          </Typography>
          <Typography
            fontSize={24}
            className="para-text-18"
            color={theme.palette.text.secondary}
          >
            Everything you need to know about our all in one platform
          </Typography>
        </div>
      </Box>
      <Container maxWidth="xl">
        <div className="d-flex justify-content-center flex-column align-items-center flex-wrap">
          <div className="d-flex align-items-center gap-2 mt-5 flex-wrap">
            <hr style={{ border: "1px solid #008F97", width: 40 }} />
            <Typography color="#009DA6" fontSize={24}>
              FAQ’s
            </Typography>
          </div>
          <Typography
            fontSize={40}
            className="phone-heading-30"
            fontWeight={500}
            color={theme.palette.text.primary}
          >
            Have Any Questions?
          </Typography>

          <div className="faq_tabs">
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="lab API tabs example"
              className="mt-3 flex-wrap faq-tabs"
              TabIndicatorProps={{
                style: { background: "gray", color: "red" },
              }}

              //   indicatorColor="blue"
            >
              {tabs.map((x, i) => {
                return (
                  <Tab
                    key={i}
                    sx={{
                      "&.Mui-selected": {
                        color: "#13556D",
                      },
                      textTransform: "none",
                      fontSize: 24,
                      className: "para-text-18 ",
                      fontWeight: tabValue === x.value ? "bold" : "",
                      color: "#13556D",
                    }}
                    label={x.label}
                    value={x.value}
                  />
                );
              })}
            </Tabs>
          </div>

          <div className="my-5">
            <Card
              elevation={0}
              sx={{
                maxWidth: 1107,
                width: "100%",
                border: "1px solid #E3E3E3",
                background: "#FCFCFC",
              }}
            >
              <CardHeader
                component={() =>
                  faqData &&
                  faqData?.map((item, index) => (
                    <div className="">
                      <div className="d-flex justify-content-between align-items-center p-3">
                        <Typography
                          fontSize={18}
                          color="#141B34"
                          fontWeight={500}
                        >
                          {item?.question &&
                          item?.question.charAt(0)?.toUpperCase() + item?.question?.slice(1)}
                        </Typography>
                        <IconButton onClick={() => collapseOpen(item, index)}>
                          {item?.collapse ? <DownIcon /> : <UpIcon />}
                        </IconButton>
                      </div>
                      <Divider
                        sx={{
                          background: "#E3E3E3",
                        }}
                      />
                      <Collapse in={item.collapse}>
                        <CardContent>
                          <Typography color="#626677" fontSize={18}>
                            {item.answer}
                          </Typography>
                        </CardContent>
                      </Collapse>
                    </div>
                  ))
                }
              />
            </Card>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default FAQ;
