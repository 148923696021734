import {
  Box,
  Checkbox,
  Collapse,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Form, FormikProvider, useField, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Link, useLocation, useParams } from "react-router-dom";
import { theme } from "../../../assets/theme";
import ButtonComponent from "../../../components/Button";
import CollapseWithTitle from "../../../components/Collapse";
import CustomSelectBox from "../../../components/SelectBox";
import CustomTextField from "../../../components/TextField";
import { uploadImage } from "../../../services/profileServices";
import {
  AddRentApplications,
  EditRentApplicationId,
  GetRentApplicationDetail,
} from "../../../services/rentapplications.service";
import {
  BUSINESS_NAME_DATA,
  EXPECTEDSTAY_TYPE,
  GENDER_DATA,
  IdentificationType,
  MARTIAL_STATUS,
  PROPERTY_CATEGORY,
} from "../../../utils/CONSTANTS";
import { fDate } from "../../../utils/format-time";
import {
  dateAhead,
  onChangeToNumber,
} from "../../../utils/helperFunctionForValidte";
import { validationSchemaRentApp } from "../../../utils/schema";
import RentApplicationModal from "./rentApplicationModal";
import PlacesAutoComplete from "../../../components/PlacesAutocomplete";

export default function EditRentApplication() {
  const { id } = useParams();
  const [personalDetailColl, setPersonalDetailColl] = useState(true);
  const [imageData, setImageData] = useState({});
  const [coocupant, setCoocupant] = useState(true);
  const [previousLandlordColl, setPreviousLandlordColl] = useState(true);
  const [businessInfoColl, setBusinessInfoColl] = useState(true);
  const [floorPerfrenceColl, setFloorPerfernceColl] = useState(true);
  const [collapaseEmployee, setCollapaseEmployee] = useState(true);
  const [collapaseKinDetails, setCollapaseKinDetails] = useState(true);
  const [collapaseOtherDetail, setCollapaseOtherDetail] = useState(true);
  const [collapasePersonalDetail, setCollapasePersonalDetail] = useState(true);

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const [renderConditionalField, setRenderConditionalField] = useState({
    employeeStatus: "",
  });
  const formik = useFormik({
    validateOnMount: id ? true : false,
    validationSchema: validationSchemaRentApp,
    initialValues: {
      propertyID: "",
      employmentStatus: "",
      employerName: "",
      employerAddress: "",
      occupation: "",
      kinFirstName: "",
      kinLastName: "",
      kinContactNumber: "",
      kinMiddleName: "",
      kinDOB: dayjs(new Date()),
      kinEmail: "",
      identityType: "",
      relationshipKin: "",
      name: "",
      no_of_occupant: "",
      checkinDate: dayjs(new Date()),
      checkOutDate: dayjs(new Date()),
      emailID: "",
      contactNumber: "",
      martialStatus: "",
      age: "",
      rentNowPayLater: false,
      address: "",
      permanentCity: "",
      permanentState: "",
      permanentZipcode: "",
      bvn: "",
      nin: "",
      voter_id: "",
      previousLandlordAddress: "",
      previousLandlordName: "",
      previousLandlordContactNumber: "",
      previousLandlordReasonToLeave: "",
      personalFirstName: "",
      personalMiddleName: "",
      personalLastName: "",
      personalGender: "",
      personalContactNumber: "",
      personalAlternativeContact: "",
      personalEmail: "",
      nameOfMajorOcupant: "",
      ocupantContactNumber: "",
      relationshipWithCoOccupant: "",
      propertyCategory: "",
      preferredFloor: "",
      nameofBusiness: "",
      numberOfEmployees: "",
      typeOfBusiness: "",
      identitiy_doc: "",
      expectedStays: "",
      expectedStaysDurationType: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      // payload send to backend
      const payload = {
        ...(id ? { id: id } : {}), // add this on the time of the edit
        propertyID: state.id,
        identitiy_doc: values?.identitiy_doc,
        employmentStatus: values.employmentStatus,
        employerName: values.employerName,
        employerAddress: values.employerAddress,
        occupation: values.occupation,
        kinFirstName: values.kinFirstName,
        kinLastName: values.kinLastName,
        kinContactNumber: values.kinContactNumber,
        kinEmail: values.kinEmail,
        kinDOB: fDate(values.kinDOB, "yyyy-MM-dd"),
        relationshipKin: values.relationshipKin,
        name: values.name,
        no_of_occupant: values.no_of_occupant,
        checkinDate: fDate(values.checkinDate, "MM-dd-yyyy"),
        checkoutDate: fDate(values.checkOutDate, "MM-dd-yyyy"),
        emailID: values?.personalEmail,
        kinMiddleName: values.kinMiddleName,
        maritialStatus: values.martialStatus,
        rentNowPayLater: values.rentNowPayLater,
        permanentAddress: values.address,
        permanentCity: values.permanentCity,
        permanentState: values.permanentState,
        permanentZipcode: Number(values.permanentZipcode),
        contactNumber: values?.personalContactNumber,
        identificationType: values.identityType,
        nin: values.nin,
        bvn: values?.bvn,
        voter_id: values?.voter_id,
        previousLandlordAddress: values?.previousLandlordAddress,
        previousLandlordName: values?.previousLandlordName,
        firstName: values?.personalFirstName,
        middleName: values?.personalMiddleName,
        lastName: values?.personalLastName,
        gender: values?.personalGender,
        alternativeContactNumber: values?.personalAlternativeContact,
        expectedCoOccupents: Number(values?.no_of_occupant),
        coOccupentName: values?.nameOfMajorOcupant,
        coOccupentContact: values?.ocupantContactNumber,
        relationWithCoOccupent: values?.relationshipWithCoOccupant,
        previouLandloadContact: values?.previousLandlordContactNumber,
        previouReasonForLeaving: values?.previousLandlordReasonToLeave,
        businessName: values?.nameofBusiness,
        businessType: values?.typeOfBusiness,
        totalEmployees: values?.numberOfEmployees,
        preferredFloor: values?.preferredFloor,
        expectedStays: Number(values?.expectedStays),
        expectedStaysDurationType: values?.expectedStaysDurationType
      };
      const response = id
        ? await EditRentApplicationId(payload)
        : await AddRentApplications(payload);
      if (response.status) {
        setModal(true);
      } else {
        if (response?.code === "ERR_NETWORK") {
          NotificationManager.error("", response.message);
        } else {
          console.log(response);
          NotificationManager.error("", response?.response?.data?.message);
          setLoading(false);
        }
      }
      setLoading(false);
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(
      name,
      name === "rentNowPayLater" ? e.target.checked : value
    );
    if (name === "employmentStatus") {
      setRenderConditionalField((prev) => ({
        ...prev,
        employeeStatus: value,
      }));
    }
  };
  console.log("state", state);
  useEffect(() => {
    console.log("dayahead", dateAhead());
    formik.setFieldValue(
      "checkOutDate",
      dateAhead(
        formik.values.checkinDate,
        formik?.values?.expectedStays
          ? Number(formik?.values?.expectedStays)
          : 1
      )
    );
  }, [formik.values.checkinDate, formik?.values?.expectedStays]);

  // for get the rent application detail if user will click to the edit rent application
  const getRentApplicationDetail = async () => {
    if (id) {
      const getData = await GetRentApplicationDetail(id);
      console.log("getData", getData);
      formik.setValues({
        ...getData?.data,
        kinDOB: dayjs(new Date(getData?.data?.kinDOB)),
        checkinDate: dayjs(new Date(getData?.data?.checkinDate)),
        checkOutDate: dayjs(new Date(getData?.data?.checkOutDate)),
        martialStatus: getData?.data?.maritialStatus,
        identityType: getData?.data?.verifcationType,
        no_of_occupant: getData?.data?.coOccupent,
        permanentZipcode: getData?.data?.permanentZipcode,
        personalFirstName: getData?.data?.firstName,
        personalMiddleName: getData?.data?.middleName,
        personalLastName: getData?.data?.lastName,
        personalGender: getData?.data?.gender,
        personalContactNumber: getData?.data?.contactNumber,
        personalAlternativeContact: getData?.data?.alternativeContactNumber,
        personalEmail: getData?.data?.emailID,
        no_of_occupant: getData?.data?.expectedCoOccupents,
        nameOfMajorOcupant: getData?.data?.coOccupentName,
        ocupantContactNumber: getData?.data?.coOccupentContact,
        relationshipWithCoOccupant: getData?.data?.relationWithCoOccupent,
        previousLandlordContactNumber: getData?.data?.previouLandloadContact,
        previousLandlordReasonToLeave: getData?.data?.previouReasonForLeaving,
        address: getData?.data?.permanentAddress,
        propertyCategory: state?.category,
        nameofBusiness: getData?.data?.businessName,
        typeOfBusiness: getData?.data?.businessType,
        numberOfEmployees: getData?.data?.totalEmployees,
        expectedStays: String(getData?.data?.expectedStays),
      });
      setImageData({
        originalname: getData?.data?.identitiy_doc,
      });
    }
  };

  useEffect(() => {
    getRentApplicationDetail();
    formik.setFieldValue("propertyCategory", state?.category);
    formik.setFieldValue(
      "checkOutDate",
      dateAhead(formik.values.checkinDate, 1)
    );
  }, []);

  console.log("formik", formik);
  return (
    <Container maxWidth="xl" className="rent-application-page">
      <FormikProvider value={formik}>
        <Form>
          <Box className={"mt-5"}>
            <Typography
              className="phone-heading-30"
              fontSize={40}
              fontWeight={700}
              color={theme.palette.text.primary}
            >
              Rent Application
            </Typography>
          </Box>
          <div className="mt-2 mb-5">
            <CollapseWithTitle
              title={"Personal information"}
              open={personalDetailColl}
              setOpen={setPersonalDetailColl}
            />
            <Collapse in={personalDetailColl}>
              <Grid container spacing={4} className="mt-2">
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    onChange={handleChange}
                    fullWidth
                    name="personalFirstName"
                    label="First Name"
                    value={formik.values.personalFirstName}
                    placeholder={"Enter employer's name"}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <CustomTextField
                    onChange={handleChange}
                    fullWidth
                    name="personalMiddleName"
                    label="Middle Name"
                    value={formik.values.personalMiddleName}
                    placeholder={"Enter employer's name"}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    onChange={handleChange}
                    fullWidth
                    name="personalLastName"
                    label="Last Name"
                    value={formik.values.personalLastName}
                    placeholder={"Enter Occupation"}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomSelectBox
                    onChange={handleChange}
                    placeholder={"Select"}
                    name={"personalGender"}
                    value={formik.values.personalGender}
                    label={"Gender"}
                    menu={GENDER_DATA}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomSelectBox
                    onChange={handleChange}
                    placeholder={"Select"}
                    name={"martialStatus"}
                    label={"Marital Status"}
                    value={formik.values.martialStatus}
                    menu={MARTIAL_STATUS}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    onChange={handleChange}
                    fullWidth
                    name="personalContactNumber"
                    label="Contact Number"
                    value={onChangeToNumber(
                      formik.values.personalContactNumber
                    )}
                    placeholder={"Enter Contact Number"}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    onChange={handleChange}
                    fullWidth
                    name="personalAlternativeContact"
                    label="Alternative Contact Number"
                    value={onChangeToNumber(
                      formik.values.personalAlternativeContact
                    )}
                    placeholder={"Enter Alternative Contact Number"}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    onChange={handleChange}
                    fullWidth
                    name="personalEmail"
                    label="Email"
                    value={formik.values.personalEmail}
                    placeholder={"Enter email"}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className="rent_application">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        format="YYYY-MM-DD"
                        name="kinDOB"
                        value={formik.values.kinDOB}
                        onChange={(value) =>
                          formik.setFieldValue("kinDOB", value)
                        }
                        fullWidth
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            fullWidth
                            label="Check in Date"
                          />
                        )}
                        label="Date of Birth"
                      />
                    </LocalizationProvider>
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className="position-relative border text-end form-control">
                    <input
                      // onChange={handleChange}
                      name=""
                      label="Upload the copy of goverment id"
                      placeholder={"Upload the copy of goverment id"}
                      type={"file"}
                      id="uploadfile"
                      className={"d-none"}
                      onChange={async (e) => {
                        const uplaodImage = await uploadImage({
                          media: e.target.files[0],
                        });
                        console.log("asdsad", uplaodImage?.data?.fullPath);
                        setImageData(uplaodImage?.data);
                        if (uplaodImage?.status) {
                          formik.setFieldValue(
                            "identitiy_doc",
                            uplaodImage?.data?.fullPath
                          );
                        }
                      }}
                    />
                    <div className="d-flex align-items-center justify-content-between">
                      {" "}
                      <span
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {imageData?.originalname
                          ? imageData?.originalname
                          : "Upload ID"}
                      </span>
                      <label
                        htmlFor="uploadfile"
                        style={{
                          backgroundColor: "#C8D736",
                          color: "#618D9D",
                          marginLeft: "5px",
                        }}
                        className="btn"
                      >
                        Upload
                      </label>
                    </div>
                  </div>
                  {formik?.touched?.identitiy_doc &&
                  formik?.errors?.identitiy_doc ? (
                    <span className="text-danger">Upload ID</span>
                  ) : null}
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomSelectBox
                    onChange={handleChange}
                    placeholder={"Select"}
                    name={"identityType"}
                    value={formik.values.identityType}
                    label={"Verification through Government ID"}
                    menu={[
                      { label: "Voter Id", value: IdentificationType.VOTER_ID },
                      {
                        label: "Bank Verification Number (BVN)",
                        value: IdentificationType.BVN,
                      },
                      {
                        label: "National Identification Number (NIN)",
                        value: IdentificationType.NIN_V2,
                      },
                    ]}
                  />
                </Grid>
                {formik.values.identityType === IdentificationType.VOTER_ID ? (
                  <Grid item md={6} xs={12}>
                    <CustomTextField
                      onChange={handleChange}
                      name="voter_id"
                      value={formik.values.voter_id}
                      fullWidth
                      label="Voter Id Number"
                      placeholder={"Enter voter id"}
                    />
                  </Grid>
                ) : formik.values.identityType === IdentificationType.BVN ? (
                  <Grid item md={6} xs={12}>
                    <CustomTextField
                      onChange={handleChange}
                      name="bvn"
                      value={formik.values.bvn}
                      fullWidth
                      label="Bank Verfication number"
                      placeholder={"Enter bvn"}
                    />
                  </Grid>
                ) : formik.values.identityType === IdentificationType.NIN_V2 ? (
                  <Grid item md={6} xs={12}>
                    <CustomTextField
                      onChange={handleChange}
                      name="nin"
                      value={formik.values.nin}
                      fullWidth
                      label="National Identification Number"
                      placeholder={"Enter nin"}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Collapse>
          </div>
          <div className="mt-2 mb-5">
            <CollapseWithTitle
              title={"Employment details"}
              open={collapaseEmployee}
              setOpen={setCollapaseEmployee}
            />
            <Collapse in={collapaseEmployee}>
              <Grid container spacing={4} className="mt-2">
                <Grid item md={6} xs={12}>
                  <CustomSelectBox
                    onChange={handleChange}
                    placeholder={"Select"}
                    name={"employmentStatus"}
                    value={formik.values.employmentStatus}
                    label={"Employment Status"}
                    menu={[
                      { label: "Employed", value: "employed" },
                      { label: "Unemployed", value: "unemployed" },
                      { label: "Self employed", value: "self_employed" },
                    ]}
                  />
                </Grid>

                {formik.values.employmentStatus === "unemployed" ? null : (
                  <>
                    {" "}
                    <Grid item md={6} xs={12}>
                      <CustomTextField
                        onChange={handleChange}
                        fullWidth
                        name="employerName"
                        label={
                          formik.values.employmentStatus === "self_employed"
                            ? "Business name"
                            : "Employer's name"
                        }
                        value={formik.values.employerName}
                        placeholder={
                          formik.values.employmentStatus === "self_employed"
                            ? "Enter Business's name"
                            : "Enter Employer's name"
                        }
                        helperText={
                          formik.values.employmentStatus === "self_employed"
                            ? " Business's name is required"
                            : " Employer's name is required"
                        }
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <CustomTextField
                        onChange={handleChange}
                        fullWidth
                        name="employerAddress"
                        label={
                          formik.values.employmentStatus === "self_employed"
                            ? "Business's address"
                            : "Employer's address"
                        }
                        value={formik.values.employerAddress}
                        placeholder={
                          formik.values.employmentStatus === "self_employed"
                            ? "Enter Business's address"
                            : "Enter Employer's address"
                        }
                        helperText={
                          formik.values.employmentStatus === "self_employed"
                            ? " Business's address is required"
                            : " Employer's address is required"
                        }
                      />
                    </Grid>
                  </>
                )}
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    onChange={handleChange}
                    fullWidth
                    name="occupation"
                    label="Occupation"
                    value={formik.values.occupation}
                    placeholder={"Enter Occupation"}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </div>
          <div className="mt-2 mb-5">
            <CollapseWithTitle
              title={"Co-Occupant"}
              open={coocupant}
              setOpen={setCoocupant}
            />
            <Collapse in={coocupant}>
              <Grid container spacing={4} className="mt-2">
                <Grid item md={6} xs={12}>
                  <CustomSelectBox
                    onChange={handleChange}
                    fullWidth
                    name="no_of_occupant"
                    label="Number of Occupant"
                    value={formik.values.no_of_occupant}
                    placeholder={"Enter Number of Occupant"}
                    menu={[1, 2, 3, 4, 5, 6, 7].map((item) => {
                      return {
                        label: item,
                        value: item,
                      };
                    })}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    onChange={handleChange}
                    fullWidth
                    name="nameOfMajorOcupant"
                    label="Name of Major Occupant"
                    value={formik.values.nameOfMajorOcupant}
                    placeholder={"Enter Name of Major Occupant"}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    onChange={handleChange}
                    fullWidth
                    name="ocupantContactNumber"
                    label="Occupant Contact Number"
                    value={onChangeToNumber(formik.values.ocupantContactNumber)}
                    placeholder={"Enter Occupant Contact Number"}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    onChange={handleChange}
                    fullWidth
                    name="relationshipWithCoOccupant"
                    label="Relationship with Occupant"
                    value={formik.values.relationshipWithCoOccupant}
                    placeholder={"Enter Relationship with Occupant"}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </div>
          <div className="mt-5 mb-5">
            <CollapseWithTitle
              title={"Kin details"}
              open={collapaseKinDetails}
              setOpen={setCollapaseKinDetails}
            />
            <Collapse in={collapaseKinDetails}>
              <Grid container spacing={4} className="mt-2">
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    onChange={handleChange}
                    name="kinFirstName"
                    fullWidth
                    label="First Name"
                    value={formik.values.kinFirstName}
                    placeholder={"Enter first  name"}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    onChange={handleChange}
                    name="kinMiddleName"
                    fullWidth
                    label="Middle Name"
                    value={formik.values.kinMiddleName}
                    placeholder={"Enter first  name"}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    onChange={handleChange}
                    name="kinLastName"
                    fullWidth
                    label="Last Name"
                    value={formik.values.kinLastName}
                    placeholder={"Enter last  name"}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    onChange={handleChange}
                    name="kinContactNumber"
                    fullWidth
                    label="Contact Number"
                    value={onChangeToNumber(formik.values.kinContactNumber)}
                    placeholder={"Enter Number"}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <CustomTextField
                    onChange={handleChange}
                    name="kinEmail"
                    value={formik.values.kinEmail}
                    fullWidth
                    label="Email"
                    placeholder={"Enter Email"}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <CustomTextField
                    onChange={handleChange}
                    value={formik.values.relationshipKin}
                    fullWidth
                    name="relationshipKin"
                    label="Relationship with Kin"
                    placeholder={"Enter RelationShip"}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </div>
          <div className="mt-5 mb-5">
            <CollapseWithTitle
              title={"Move In information"}
              open={collapaseOtherDetail}
              setOpen={setCollapaseOtherDetail}
            />
            <Collapse in={collapaseOtherDetail}>
              <Grid container spacing={4} className="mt-2">
                <Grid item md={6} xs={12}>
                  <div className="rent_application">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        format="YYYY-MM-DD"
                        name="checkinDate"
                        value={formik.values.checkinDate}
                        onChange={(value) =>
                          formik.setFieldValue("checkinDate", value)
                        }
                        fullWidth
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            fullWidth
                            label="Check in Date"
                          />
                        )}
                        label="Proposed Move in date"
                      />
                    </LocalizationProvider>
                  </div>
                </Grid>
                {formik.values.propertyCategory ===
                PROPERTY_CATEGORY.shortStay ? (
                  <Grid item md={6} xs={12}>
                    <div className="rent_application">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          format="YYYY-MM-DD"
                          name="checkOutDate"
                          value={dayjs(formik.values.checkOutDate)}
                          fullWidth
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              fullWidth
                              label="Check Out Date"
                            />
                          )}
                          label="Expected length of stay"
                          disabled
                        />
                      </LocalizationProvider>
                    </div>
                  </Grid>
                ) : null}
                {formik.values.propertyCategory ===
                  PROPERTY_CATEGORY.commerical ||
                formik.values.propertyCategory ===
                  PROPERTY_CATEGORY.resisdential ? (
                  <>
                    <Grid item md={6} xs={12}>
                      <CustomTextField
                        onChange={handleChange}
                        fullWidth
                        label="Expected length of stay"
                        value={onChangeToNumber(formik.values.expectedStays)}
                        name="expectedStays"
                        placeholder={"Enter Expected length of stay"}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <CustomSelectBox
                        onChange={handleChange}
                        fullWidth
                        label="select the stay duration"
                        value={formik.values.expectedStaysDurationType}
                        name="expectedStaysDurationType"
                        menu={EXPECTEDSTAY_TYPE}
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </Collapse>
          </div>
          {formik.values.propertyCategory === "commercial" ? (
            <>
              <div className="mt-5 mb-5">
                <CollapseWithTitle
                  title={"Business Information"}
                  open={businessInfoColl}
                  setOpen={setBusinessInfoColl}
                />
                <Collapse in={businessInfoColl}>
                  <Grid container spacing={4} className="mt-2">
                    <>
                      <Grid item md={6} xs={12}>
                        <CustomTextField
                          onChange={handleChange}
                          fullWidth
                          label="Name of Business"
                          value={formik.values.nameofBusiness}
                          name="nameofBusiness"
                          placeholder={"Enter Name of Business"}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <CustomSelectBox
                          onChange={handleChange}
                          fullWidth
                          label="Type of Business"
                          value={formik.values.typeOfBusiness}
                          name="typeOfBusiness"
                          placeholder={"Enter Name of Business"}
                          menu={BUSINESS_NAME_DATA}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <CustomTextField
                          onChange={handleChange}
                          name="numberOfEmployees"
                          value={onChangeToNumber(
                            formik.values.numberOfEmployees
                          )}
                          label={"Number Of Employees"}
                          fullWidth
                        />
                      </Grid>
                    </>
                  </Grid>
                </Collapse>
              </div>
              <div className="mt-5 mb-5">
                <CollapseWithTitle
                  title={"Floor Preference"}
                  open={floorPerfrenceColl}
                  setOpen={setFloorPerfernceColl}
                />
                <Collapse in={floorPerfrenceColl}>
                  <Grid container spacing={4} className="mt-2">
                    <Grid item md={6} xs={12}>
                      <CustomSelectBox
                        onChange={handleChange}
                        placeholder={"Select"}
                        name={"preferredFloor"}
                        value={formik.values.preferredFloor}
                        label={"Preferred Floor"}
                        menu={[1, 2, 3, 4, 5, 6].map((item) => {
                          return {
                            label: item,
                            value: item,
                          };
                        })}
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              </div>
            </>
          ) : null}
          <div className="mt-5 mb-5">
            <CollapseWithTitle
              title={"Permanent Address"}
              open={previousLandlordColl}
              setOpen={setPreviousLandlordColl}
            />
            <Collapse in={previousLandlordColl}>
              <Grid container spacing={4} className="mt-2">
                <Grid item md={6} xs={12}>
                  <PlacesAutoComplete
                    setLatLng={() => {}}
                    value={formik?.values?.address}
                    setFieldValue={(key, value) =>
                      formik.setFieldValue(key, value)
                    }
                    key={"autoComplete"}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    value={onChangeToNumber(formik.values.permanentZipcode)}
                    onChange={handleChange}
                    name="permanentZipcode"
                    label={"ZipCode"}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Collapse>
          </div>
          <div className="mt-5 mb-5">
            <CollapseWithTitle
              title={"Previous landlord information"}
              open={collapasePersonalDetail}
              setOpen={setCollapasePersonalDetail}
            />
            <Collapse in={collapasePersonalDetail}>
              <Grid container spacing={4} className="mt-2">
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    onChange={handleChange}
                    name="previousLandlordName"
                    value={formik.values.previousLandlordName}
                    label={"Previous Landlord Name"}
                    fullWidth
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    onChange={handleChange}
                    name="previousLandlordAddress"
                    value={formik.values.previousLandlordAddress}
                    label={"Previous Landlord Address"}
                    fullWidth
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    onChange={handleChange}
                    name="previousLandlordContactNumber"
                    value={onChangeToNumber(
                      formik.values.previousLandlordContactNumber
                    )}
                    label={"Previous Landlord Contact Number"}
                    fullWidth
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    onChange={handleChange}
                    name="previousLandlordReasonToLeave"
                    value={formik.values.previousLandlordReasonToLeave}
                    label={"Reason For Leaving"}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Typography fontSize={14} fontWeight={400} color={"#07262E"}>
                <Checkbox
                  checked={formik.values.rentNowPayLater}
                  name="rentNowPayLater"
                  onChange={handleChange}
                />{" "}
                Rent now, pay later
              </Typography>
              <Typography fontSize={14} fontWeight={400} color={"#07262E"}>
                <Checkbox /> By clicking, I agree to{" "}
                <span
                  style={{
                    color: "#C8D736",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  <Link
                    style={{ color: "#C8D736" }}
                    to="/rent-insurance-policy"
                  >
                    Rent Insurance Policy.
                  </Link>
                </span>
              </Typography>
            </Collapse>
          </div>
          <div className="mb-5">
            <ButtonComponent
              loading={loading}
              disabled={loading}
              title={id ? "Update" : "Submit"}
              onClick={formik.handleSubmit}
            />
          </div>
        </Form>
      </FormikProvider>

      <RentApplicationModal
        open={modal}
        setOpen={setModal}
        data={{ propertyName: formik?.values?.property_info?.propertyName }}
      />
    </Container>
  );
}
