import { Card, Typography } from "@mui/material";
import {
  addDays,
  addMonths,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  isSameMonth,
  startOfMonth,
  startOfWeek,
  subMonths,
} from "date-fns";
import React from "react";
import { NotificationManager } from "react-notifications";
import { compareDate } from "../../utils/format-time";

const CustomCalendar = ({
  setSelectedDate,
  selectedDate,
  setCurrentMonth,
  currentMonth,
  fetchedDates = [],
}) => {
  const renderHeader = () => {
    const dateFormat = "MMMM yyyy";

    return (
      <div className="d-flex justify-content-between align-items-center">
        <Typography fontSize={18} color="text.secondary" fontWeight={"bold"}>
          Available dates* 
        </Typography>
        <div className="d-flex justify-content-between align-items-center">
          <div className="col col-start">
            <div className="icon" onClick={prevMonth}>
              &lt;
            </div>
          </div>

          <Typography fontSize={18} color="text.secondary" fontWeight={500}>
            {format(currentMonth, dateFormat)}
          </Typography>

          <div className="col col-end" onClick={nextMonth}>
            <div className="icon">&gt;</div>
          </div>
        </div>
      </div>
    );
  };

  const renderDays = () => {
    const dateFormat = "EE";
    const days = [];

    let startDate = startOfWeek(currentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(
        <div
          className="d-flex justify-content-center align-items-center col-border col-center"
          key={i}
        >
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }
    return (
      <Typography
        className="days row"
        color="#13556D"
        fontSize={16}
        fontWeight={600}
      >
        <div className="row w-100 px-0">{days}</div>
      </Typography>
    );
  };

  const renderCells = () => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);

    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";

    // Create a Set for quick lookup of fetched dates
    const fetchedDatesSet = new Set(
      fetchedDates?.map((date) => new Date(date).toDateString())
    );
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;

        // Check if the day is in fetchedDatesSet
        const isFetchedDate = fetchedDatesSet.has(day.toDateString());
        days.push(
          <div
            className={`col-border cell ${
              !isSameMonth(day, monthStart)
                ? "disabled"
                :  isSameDay(day, selectedDate)
                ?   "selected"
                : isSameDay(day, new Date())
                ? isFetchedDate ? "fetch-date"  : "current-date"
                : isFetchedDate
                ? "fetch-date"
                : ""
            }`}
            key={day}
            onClick={() => onDateClick(cloneDay)}
          >
            <Typography
              color={
                isSameDay(day, selectedDate) || isFetchedDate
                  ? "white"
                  : "text.secondary"
              }
              fontSize={16}
              fontWeight={600}
              className="number"
            >
              {formattedDate}
            </Typography>
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className="row w-100" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };

  const onDateClick = (day, i) => {
    const dateCompare = compareDate(day);
    console.log("datecompare",dateCompare)
    if (dateCompare) {
      return NotificationManager.error(
        "",
        "Please don’t set any date earlier than today."
      );
    }
    setSelectedDate(day);
  };

  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const prevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };
  return (
    <div className="calendar-left">
      {renderHeader()}

      <Card
        className="p-3 calender_card"
        elevation={0}
        sx={{
          border: "1px solid #DBDBDB",
          borderRadius: 2,
        }}
      >
        {renderDays()}
        {renderCells()}
      </Card>
    </div>
  );
};

export default CustomCalendar;
