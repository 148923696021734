import React from "react";
import { RWebShare } from "react-web-share";
import share from "../assets/images/share.svg";
import { ShareIcon } from "../assets/icons";
export default function ShareComponent({
  shareData = {
    shareWhite: true,
    data: {},
  },
}) {
  console.log("data", shareData);
  return (
    <RWebShare
      data={{
        text: `Your shared proeperty is ${shareData?.propertyName}`,
        url: `https://rentranzact.com/property-detail/${shareData?.data?.id}`,
        title: shareData?.propertyName,
      }}
      onClick={(e) => {
        console.log("shared successfully!");
      }}
    >
      <button
        onClick={(e) => {
          e.stopPropagation();
          console.log("shared successfully!");
        }}
        style={{ background: "transparent", border: "0" }}
      >
        {shareData?.shareWhite ? <ShareIcon /> : <img src={share} />}
      </button>
    </RWebShare>
  );
}
