import {
  Box,
  Card,
  CardActions,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "../../assets/theme";
import ButtonComponent from "../../components/Button";
import careersBanner from "../../assets/images/careersBanner.png";
import { NextIcon, PrevIcon } from "../../assets/icons";
import vision from "../../assets/images/vision.png";
import axiosInstance from "../../utils/axios";
import Loader from "../../components/loader";
import { getSkillsExperienceList } from "../../services/admin/adminCmsServices";

const Careers = () => {
  // local state
  const [localState, setLocalState] = useState({
    data: [],
    skillsAndExperienceData: null,
    totalJobOpening: 0,
    page: 1,
    total: 1,
    loading: false,
  });

  const [formData, setFormData] = useState({
    skill: "",
    experience: "",
    apiFilterFlag: false,
  });

  // get career data
  const getCareerData = async () => {
    try {
      setLocalState((prev) => ({
        ...prev,
        loading: true,
      }));
      const response = await axiosInstance.get(
        `/admin/careers?page=${localState.page}&count=10&search&skills=${
          formData.apiFilterFlag ? formData.skill : ""
        }&experience=${formData.apiFilterFlag ? formData.experience : ""}`
      );
      if (response?.status === 200) {
        setLocalState((prev) => ({
          ...prev,
          data: response?.data?.data[0]?.data,
          totalJobOpening:
            response?.data?.data[0]?.total_openings[0]?.total_openings,
          page: response?.data?.data[0]?.pagination[0]?.page,
          total: Math.ceil(response?.data?.data[0]?.pagination[0]?.total / 10),
          loading: false,
        }));
      }
    } catch (error) {
      console.log(error);
      setLocalState((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };
  // get skils and experience filter data

  const getSkilsExperienceData = async () => {
    setLocalState((prev) => ({
      ...prev,
      loading: true,
    }));
    const res = await getSkillsExperienceList();
    if (res?.status) {
      setLocalState((prev) => ({
        ...prev,
        skillsAndExperienceData: res?.data[0],
        loading: false,
      }));
    }
  };

  //  handle select filter

  const handleFilterSelect = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submitSkillAndExperinceFilter = (e) => {
    e.preventDefault();
    if (formData.skill && formData.experience) {
      setFormData((prev) => ({
        ...prev,
        apiFilterFlag: true,
      }));
    }
  };

  // effect for get carrer data
  useEffect(() => {
    getCareerData();
  }, [localState.page, formData.apiFilterFlag]);

  // effect for get skills and experience filter data
  useEffect(() => {
    getSkilsExperienceData();
  }, []);

  if (localState?.loading) {
    return <Loader />;
  }
  return (
    <>
      <div className="outer-page">
        <Box
          display={"flex"}
          // justifyContent={"flex-start"}
          px={6}
          alignItems={"center"}
          sx={{
            backgroundImage: `url(${careersBanner})`,
            width: "100%",
            backgroundSize: "cover",
            height: 497,
            localState,
            '@media (max-width: 600px)': {
          height: '320px',  // 
        },
          }}
        >
          <div>
            <Typography
              fontSize={56}
              fontWeight={"bold"}
              color={theme.palette.text.primary}
            >
              Career
            </Typography>
            <Typography fontSize={24} color={theme.palette.text.secondary}>
              Join our family as we  revolutionize the real estate space.
            </Typography>
          </div>
        </Box>
        <div
          style={{ width: "100%" }}
          className="d-flex justify-content-center  text-center mt-3"
        >
          <Card elevation={2} sx={{ maxWidth: 990, width: "100%", mt: -7 }}>
            <div className="row d-flex justify-content-center align-items-center p-3">
              <div className="col-md-5 mb-2">
                <FormControl fullWidth>
                  <InputLabel>Select you skill</InputLabel>
                  <Select
                    sx={{ borderColor: "#DBDBDB" }}
                    fullWidth
                    placeholder="Select you skill"
                    label="Select you skill"
                    name="skill"
                    value={formData.skill}
                    onChange={handleFilterSelect}
                  >
                    {localState?.skillsAndExperienceData?.skills?.map(
                      (curElm, index) => (
                        <MenuItem value={curElm} key={index}>
                          {curElm}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-5 mb-2">
                <FormControl fullWidth>
                  <InputLabel>Select experience</InputLabel>
                  <Select
                    sx={{ borderColor: "#DBDBDB" }}
                    fullWidth
                    placeholder="Select experience"
                    label="Select experience"
                    name="experience"
                    value={formData.experience}
                    onChange={handleFilterSelect}
                  >
                    {localState?.skillsAndExperienceData?.experience?.map(
                      (curElm, index) => (
                        <MenuItem value={curElm} key={index}>
                          {curElm}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-2 mb-2">
                <ButtonComponent
                  title={"Search"}
                  onClick={submitSkillAndExperinceFilter}
                />
              </div>
            </div>
          </Card>
        </div>
        <Container maxWidth="xl">
          {localState.data.length > 0 ? (
            <div>
              {" "}
              <div className="d-flex justify-content-center align-items-center mt-4">
                <Typography
                  fontSize={32}
                  fontWeight={500}
                  color="text.secondary"
                >
                  Currently we have {localState?.totalJobOpening} openings
                </Typography>
              </div>
              <Grid container spacing={4} my={2}>
                {localState?.data &&
                  localState?.data?.map((curElm) => (
                    <Grid className="opening_card" item md={4} key={curElm._id}>
                      <Card
                        elevation={0}
                        sx={{
                          border: "1px solid #DBDBDB",
                          "&:hover": {
                            cursor: "pointer",
                            backgroundColor: theme.palette.secondary.main,
                            color: "white",
                            "& .MuiTypography-root": {
                              color: "white",
                            },
                            "& .apply-now": {
                              color: "#C8D736",
                            },
                          },
                        }}
                      >
                        <CardContent>
                          <Typography
                            className="hyyyy"
                            fontSize={24}
                            component="div"
                            color={"text.primary"}
                            fontWeight={500}
                          >
                            {curElm?.title}
                          </Typography>
                          <Typography
                            className="mt-2"
                            variant="body2"
                            color="#596E73"
                            fontSize={18}
                            fontWeight={300}
                          >
                            {curElm?.opening_count} open positions
                          </Typography>
                          <Typography
                            fontSize={16}
                            fontWeight={400}
                            color={"text.secondary"}
                          >
                            {curElm?.description}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <div className="w-100 d-flex justify-content-between align-items-center px-2">
                            <Typography
                              color={"#8D9B9F"}
                              fontSize={16}
                              fontWeight={200}
                            >
                              {new Date(curElm.createdAt).toLocaleDateString()}
                            </Typography>
                            <Typography
                              className="apply-now"
                              color={"#009DA6"}
                              fontSize={18}
                              fontWeight={500}
                            >
                              Apply Now
                            </Typography>
                          </div>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
              <div className="d-flex justify-content-center align-items-center mb-3">
                <Pagination
                  size="large"
                  renderItem={(item) => {
                    return (
                      <PaginationItem
                        slots={{
                          previous: () => <PrevIcon />,
                          next: () => <NextIcon />,
                        }}
                        {...item}
                        sx={{
                          borderColor: "#F1F1F1",
                          "&.Mui-selected": {
                            background: "#009DA6",
                            color: "white", // Remove border when the button is selected
                          },
                          "&:hover": {
                            borderColor: "transparent", // Remove border on hover
                          },
                        }}
                      />
                    );
                  }}
                  count={localState.total}
                  variant="outlined"
                  shape="rounded"
                  onChange={(e, value) => {
                    setLocalState((prev) => ({
                      ...prev,
                      page: value,
                    }));
                  }}
                  page={localState.page}
                />
              </div>{" "}
            </div>
          ) : (
            <Typography
              fontSize={32}
              fontWeight={500}
              color="text.secondary"
              className="text-center mt-3"
            >
              No Career found
            </Typography>
          )}

          <Grid container spacing={4} mt={3} mb={5}>
            <Grid item md={4}>
              <img className="vision_img" src={vision} style={{ height: 660 }} />
            </Grid>
            <Grid item md={8}>
              <div className="d-flex align-items-center gap-2">
                <hr style={{ border: "1px solid #008F97", width: 40 }} />
                <Typography color="#009DA6" fontSize={24}>
                  Our Approach
                </Typography>
              </div>

              <Typography
                fontSize={40}
                fontWeight={500}
                color={theme.palette.text.primary}
              >
                Vision & Mission
              </Typography>
              <Typography
                component={"p"}
                mt={3}
                fontSize={16}
                color={"#4A4A4A"}
              >
                At Rentranzact, we believe that managing rental properties
                should be simple, streamlined, and stress-free. That's why we've
                developed an approach that prioritizes ease of use, automation,
                and personalized support.
              </Typography>
              <Typography
                component={"p"}
                mt={3}
                fontSize={16}
                color={"#4A4A4A"}
              >
                Our approach begins with our user-friendly platform, which is
                designed to be intuitive and easy to navigate. We've eliminated
                the need for complicated spreadsheets or time-consuming manual
                processes, replacing them with a simple and streamlined platform
                that puts all your rental management tools in one place.
              </Typography>
              <Typography
                component={"p"}
                mt={3}
                fontSize={16}
                color={"#4A4A4A"}
              >
                We also understand the importance of automation when it comes to
                rental management. That's why we've developed a platform that
                automates many of the most time-consuming tasks such as rent
                collection and renter communication. Our platform frees up your
                time, allowing you to focus on growing your business.
              </Typography>
              <Typography
                component={"p"}
                mt={3}
                fontSize={16}
                color={"#4A4A4A"}
              >
                At the same time, we know that sometimes you need a human touch.
                That's why we provide personalized support to all of our users.
                Our team of experts are available to answer any questions you
                may have, troubleshoot any issues that arise, and help you get
                the most out of our platform.
              </Typography>
              <Typography
                component={"p"}
                mt={3}
                fontSize={16}
                color={"#4A4A4A"}
              >
                Finally, we understand that every rental property is unique.
                That's why our approach is flexible, allowing you to customize
                our platform to meet your specific needs. Whether you manage a
                single rental property or dozens, our platform can be tailored
                to fit your requirements, ensuring that you get the tools you
                need to manage your properties effectively.
              </Typography>
              <Typography
                component={"p"}
                mt={3}
                fontSize={16}
                color={"#4A4A4A"}
              >
                At Rentranzact, we're committed to making rental management
                easy, streamlined, and stress-free. Our approach puts you at the
                centre of everything we do, providing you with the tools and
                support you need to succeed.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Careers;
