import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ChatIcon, LocationIcon, RatingIcon } from "../../../assets/icons";
import ButtonComponent from "../../../components/Button";
import Loader from "../../../components/loader";
import { API_Endpoints } from "../../../services/API_Endpoints";
import { GetPropertyDetailService } from "../../../services/properties.services";
import { getReview } from "../../../services/reviewServices";
import { RetrieveLocalUser } from "../../../utils/authService";
import { contentMaintenance } from "../../../utils/CONSTANTS";
import { changeNumeicFormatDateToStandardDateFromatForSingle } from "../../../utils/format-time";
import {
  differenceInDays,
  replaceHttpImgUrl,
} from "../../../utils/helperFunctionForValidte";
import SendRequestModal from "../maintaince-request/sendRequestModal";
import LeavePropertyModal from "./leavePropertyModal";
import ReviewPropertiesModal from "./reviewPropertiesModal";

const RentedPropertyDetail = () => {
  const user = RetrieveLocalUser("user");
  const [openLeaveDialog, setOpenLeaveDialog] = useState(false);
  const [raiseQueryModalRenter, setRaiseQueryModalRenter] = useState(false);
  const [propertyDetail, setPropertyDetail] = useState(null);
  const [reviewPropertiesModal, setReviewPropertiesModal] = useState(false);
  const [review, setReview] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const GetPropertyDetailById = async () => {
    setLoading(true);
    const response = await GetPropertyDetailService(id);
    if (response.status) {
      setPropertyDetail(response.data);
    }
    setLoading(false);
  };

  const getReviewForProperty = async () => {
    setLoading(true);
    const getData = await getReview(
      `${API_Endpoints.review.GET_REVIEW}?property_id=${id}&status=accepted`
    );
    console.log("getData", getData);
    setLoading(false);
    if (getData?.status) {
      setReview(getData?.data[0]?.data);
    }
  };

  useEffect(() => {
    if (!reviewPropertiesModal) {
      GetPropertyDetailById();
      getReviewForProperty();
    }
  }, [reviewPropertiesModal]);
  console.log("proertyDETAO;", propertyDetail);
  console.log("review", review);

  return (
    <Container maxWidth="xl" className="my-5">
      {loading ? (
        <Loader />
      ) : (
        <>
          {" "}
          <div className="my-1">
            <Typography
              component={"h2"}
              color={"text.primary"}
              fontWeight={800}
              fontSize={40}
              className="phone-heading-30"
            >
              Currently Rented Properties
            </Typography>
          </div>
          <Grid container spacing={2} my={3}>
            <Grid item md={12}>
              <Card
                sx={{
                  width: "100%",
                  borderRadius: 3,
                  boxShadow: "5px 5px 19px 4px #0000000d",
                }}
                className="p-3"
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex justify-content-center align-items-center gap-2 flex-wrap">
                    <img
                      style={{ width: 127, height: 63, borderRadius: 10 }}
                      src={replaceHttpImgUrl(
                        propertyDetail?.propertyData?.images[0]?.url
                      )}
                    />
                    <div>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "1.2rem",
                            sm: "1.3rem",
                            md: "1.4rem",
                            lg: "1.5rem",
                            xl: "1.5rem",
                          },
                        }}
                        color="#031013"
                        fontWeight={"bold"}
                      >
                        {propertyDetail?.propertyData?.propertyName}
                      </Typography>
                      <div className="d-flex gap-1 align-items-center">
                        <LocationIcon />
                        <Typography color="#8D9B9F" fontWeight={200}>
                          {propertyDetail?.propertyData?.city} ,{" "}
                          {propertyDetail?.propertyData?.address?.addressText}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-center gap-1">
                    {review[0]?.rating === 0 || review[0]?.rating === "" ? (
                      <Typography
                        fontSize={18}
                        color="#031013"
                        fontWeight={"bold"}
                      >
                        No Review
                      </Typography>
                    ) : (
                      <>
                        {Array(review[0]?.rating)
                          .fill()
                          .map(() => (
                            <RatingIcon />
                          ))}
                        <Typography
                          fontSize={18}
                          color="#031013"
                          fontWeight={"bold"}
                        >
                          {review[0]?.rating}
                        </Typography>
                      </>
                    )}
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item md={7} sm={12}>
              <Grid container spacing={4}>
                <Grid item md={12} className=" property-100">
                  <Card
                    elevation={0}
                    sx={{ border: "1px solid #F1F1F1", p: 3 }}
                  >
                    <div>
                      <Typography
                        fontSize={18}
                        color="#07262E"
                        fontWeight={500}
                      >
                        {propertyDetail?.property_manager?.fullName ||
                          propertyDetail?.landlord?.fullName}
                      </Typography>
                      <Typography
                        fontSize={16}
                        color="#07262E"
                        fontWeight={500}
                      >
                        {propertyDetail?.property_manager?.role ||
                          propertyDetail?.landlord?.role}
                      </Typography>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <Typography
                        fontSize={18}
                        color="#07262E"
                        fontWeight={500}
                      >
                        {propertyDetail?.landlord?.phone ||
                          propertyDetail?.property_manager?.phone}
                      </Typography>
                      <button
                        style={{
                          background: "transparent",
                          border: "none",
                        }}
                        to="/chat"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate("/chat", {
                            state:
                              propertyDetail?.landlord?._id ??
                              propertyDetail?.property_manager?._id,
                          });
                        }}
                      >
                        <ChatIcon />
                      </button>
                    </div>
                  </Card>
                </Grid>
                <Grid item md={12}>
                  <Card
                    elevation={0}
                    sx={{ border: "1px solid #F1F1F1", p: 3 }}
                  >
                    <div className="d-flex justify-content-between align-items-center py-3 property-100">
                      <div>
                        <Typography
                          fontSize={14}
                          color="#009DA6"
                          fontWeight={500}
                        >
                          Rented on
                        </Typography>
                        <Typography color="#07262E" fontWeight={500}>
                          {changeNumeicFormatDateToStandardDateFromatForSingle(
                            propertyDetail?.propertyData?.rent_period_start
                          )}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          fontSize={14}
                          color="#009DA6"
                          fontWeight={500}
                        >
                          Rent Type
                        </Typography>
                        <Typography color="#07262E" fontWeight={500}>
                          {propertyDetail?.propertyData?.rentType}
                        </Typography>
                      </div>
                      <div></div>
                    </div>

                    <Divider
                      component={"hr"}
                      orientation="horizontal"
                      sx={{ color: "#F4F4F4" }}
                    />
                    <div className="d-flex justify-content-between align-items-center py-3 flex-wrap">
                      <div>
                        <Typography
                          fontSize={14}
                          color="#009DA6"
                          fontWeight={500}
                        >
                          Rent due on
                        </Typography>
                        <Typography color="#07262E" fontWeight={500}>
                          {changeNumeicFormatDateToStandardDateFromatForSingle(
                            propertyDetail?.propertyData?.rent_period_due
                          )}
                          <b>
                            {" "}
                            (Due in{" "}
                            {differenceInDays(
                              propertyDetail?.propertyData?.rent_period_due
                            )}{" "}
                            days)
                          </b>
                        </Typography>
                      </div>
                      <div>
                        <Button
                          onClick={() => {
                            navigate("/payment-method", {
                              state: {
                                amount: propertyDetail?.propertyData?.rent,
                                propertyID: propertyDetail?.propertyData?._id,
                                wallet: false,
                              },
                            });
                          }}
                        >
                          {" "}
                          <Typography
                            color={"#C8D736"}
                            fontSize={18}
                            fontWeight={"bold"}
                          >
                            Pay Rent
                          </Typography>
                        </Button>
                      </div>
                    </div>
                    <Divider
                      component={"hr"}
                      orientation="horizontal"
                      sx={{ color: "#F4F4F4" }}
                    />
                    <div className="d-flex justify-content-between align-items-center gap-5 py-3 flex-wrap">
                      <div>
                        <Typography
                          fontSize={14}
                          color="#009DA6"
                          fontWeight={500}
                        >
                          Rent will end on
                        </Typography>
                        <Typography color="#07262E" fontWeight={500}>
                          {changeNumeicFormatDateToStandardDateFromatForSingle(
                            propertyDetail?.propertyData?.rent_period_end
                          )}
                          <b>
                            {" "}
                            ({" "}
                            {differenceInDays(
                              propertyDetail?.propertyData?.rent_period_end
                            )}{" "}
                            Days )
                          </b>
                        </Typography>
                      </div>
                      <div className="extend-text">
                        <Typography
                          color={"#C8D736"}
                          fontSize={18}
                          fontWeight={"bold"}
                        >
                          Extend Period
                        </Typography>
                      </div>
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={5} sm={12}>
              <ButtonComponent
                color={"primary"}
                title={"Raise a query"}
                sx={{ width: "100%", height: 64, borderRadius: 2 }}
                onClick={() => {
                  setRaiseQueryModalRenter(true);
                }}
              />
              <Box
                className="d-flex justify-content-between align-items-center flex-wrap"
                bgcolor={"#009DA6"}
                px={1}
                mt={3}
                borderRadius={3}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "1.2rem",
                      sm: "1.3rem",
                      md: "1.4rem",
                      lg: "1.5rem",
                      xl: "1.5rem",
                    },
                  }}
                  fontWeight={"bold"}
                  color="white"
                  p={2}
                >
                  Do you want to leave <br />
                  this Property
                </Typography>
                <div className="leave-property-btn">
                  <Button
                    sx={{
                      background: "transparent",
                      border: "1px solid white",
                      color: "white",
                      height: 64,
                      width: 91,
                      borderRadius: 2,
                    }}
                    onClick={() => navigate(-1)}
                  >
                    No
                  </Button>
                  &nbsp; &nbsp; &nbsp;
                  <ButtonComponent
                    sx={{ height: 64, width: 91, borderRadius: 2 }}
                    color={"primary"}
                    onClick={() => {
                      // setOpenLeaveDialog(true);
                      if (review && review[0]?.rating > 0) {
                        setOpenLeaveDialog(true);
                      } else {
                        setReviewPropertiesModal(true);
                      }
                    }}
                    title="Yes"
                  />
                </div>
              </Box>
            </Grid>
          </Grid>
          {openLeaveDialog && (
            <LeavePropertyModal
              open={openLeaveDialog}
              setOpen={setOpenLeaveDialog}
              modalData={propertyDetail}
            />
          )}
          {raiseQueryModalRenter && (
            <SendRequestModal
              open={raiseQueryModalRenter}
              setOpen={setRaiseQueryModalRenter}
              headingContent={contentMaintenance?.maintenanceRequest}
              propertyData={propertyDetail?.propertyData}
            />
          )}
          {reviewPropertiesModal && (
            <ReviewPropertiesModal
              open={reviewPropertiesModal}
              setOpen={setReviewPropertiesModal}
              modalData={propertyDetail?.propertyData}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default RentedPropertyDetail;
