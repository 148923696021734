import {
  Box,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { eyeViewIcon, removeIcon, uploadIcon } from "../../../assets/icons";
import uploadDots from "../../../assets/icons/menuDot.png";
import uploadPdf from "../../../assets/images/uploadpdf.png";
import { theme } from "../../../assets/theme";
import ButtonComponent from "../../../components/Button";
import { NotificationManager } from "react-notifications";
import "./index.css";
import {
  deleteUploadLeaseAggrement,
  getUploadLeaseAggrement,
  uploadLeaseAggrement,
} from "../../../services/leaseAggrimentService";
import { RetrieveLocalUser } from "../../../utils/authService";
import { GetRentProperties } from "../../../services/rentapplications.service";
import { ROLES } from "../../../utils/CONSTANTS";
import Loader from "../../../components/loader";
import { API_Endpoints } from "../../../services/API_Endpoints";
import ViewPdfModal from "../../../components/viewModal";

export default function LeaseAgrement() {
  const user = RetrieveLocalUser("user");
  const [modalData, setModalData] = useState({});
  const [viewModal, setViewModal] = useState(false);
  const [propertyData, setPropertyData] = useState([]);
  const [uploadLeaseAggrementData, setUploadLeaseAggrementData] = useState([]);
  const [loading, setLoading] = useState({
    globalLoader: false,
    buttonLoader: false,
    deleteBtnLoader: false,
  });
  //  state  of the component to manage the uploaded file and selected property
  const [formDataValue, setFormDataValue] = useState({
    property: {
      value: "",
      warning: false,
      id: "",
    },
    file: {
      value: "",
      warning: false,
    },
  });

  // get the propertylist data from the backend
  const getThePropertyList = async () => {
    setLoading((prev) => ({
      ...prev,
      globalLoader: true,
    }));
    const getData = await GetRentProperties(
      API_Endpoints.rentApplications.GET_PROPERTIES
    );
    if (getData?.status) {
      // extract the property name and the property id to show in dropdown of property
      const data = getData?.data
        ?.filter((item) => item.rented)
        .map((item) => {
          return {
            label: item.propertyName,
            value: item._id,
          };
        });
      setPropertyData(data);
    } else {
      console.log("getDta", getData);
    }
    setLoading((prev) => ({
      ...prev,
      globalLoader: false,
    }));
  };
  //Onchange function to upload the pdf file and for another form values
  const onChange = (e) => {
    if (e.target.name === "file") {
      if (e.target.files.length) {
        const { type } = e.target.files[0];
        if (type && type?.includes("/pdf")) {
          setFormDataValue((prev) => ({
            ...prev,
            file: { value: e.target.files[0], warning: !type.includes("/pdf") },
          }));
        } else {
          NotificationManager.error("Upload only pdf file");
        }
      }
    } else {
      setFormDataValue((prev) => ({
        ...prev,
        [e.target.name]: {
          value: e.target.value,
          warning: e.target.value === "",
        },
      }));
    }
  };

  // validate the data before upload the doc
  const uploadtheDocToBackend = (e) => {
    e.preventDefault();
    let data = { ...formDataValue };
    data = {
      ...data,
      property: {
        ...data.property,
        warning: data.property.value === "",
      },
      file: {
        ...data.file,
        warning: data.file.value === "",
      },
    };
    setFormDataValue({ ...data });
    if (!data?.file?.warning && !data?.property?.warning) {
      submitLeaseAggrement();
    }
  };
  console.log("uploadLeaseAggrementData", uploadLeaseAggrementData);
  // upload lease aggrement service call to submit the lease aggrement
  const submitLeaseAggrement = async () => {
    let isTrue = false;
    uploadLeaseAggrementData.forEach((insel) => {
      if (formDataValue?.property?.value == insel.propertyID) {
        isTrue = true;
      }
    });
    if (isTrue) {
      return NotificationManager.error(
        "",
        "One Lease aggrement for one property"
      );
    }
    try {
      setLoading((prev) => ({
        ...prev,
        buttonLoader: true,
      }));
      const body = {
        document: formDataValue.file.value,
        propertyID: formDataValue.property.value,
      };
      const postData = await uploadLeaseAggrement(body);
      console.log("postdata", postData);
      if (postData?.status) {
        // NotificationManager.success("", "LeaseAggrement Upload");
      } else {
        NotificationManager.error("", "SomeThing Went wrong");
      }
      setLoading((prev) => ({
        ...prev,
        buttonLoader: false,
      }));
      setFormDataValue({
        property: {
          value: "",
          warning: false,
        },
        file: {
          value: "",
          warning: false,
        },
      });
    } catch (error) {
      console.log("error", error);
    }
  };
  console.log("peropt", formDataValue, propertyData);

  // get the upload lease aggrement from the backend
  const getTheUploadLeaseAggrement = async () => {
    try {
      setLoading((prev) => ({
        ...prev,
        globalLoader: true,
      }));
      const getData = await getUploadLeaseAggrement();
      if (getData?.status) {
        // add the dropdown show key after get the response from the backend so with this we can manage the dropdown in frontend
        const data = getData?.data?.map((item) => {
          return {
            ...item,
            dropdownShow: false,
          };
        });
        setUploadLeaseAggrementData(data);
        setLoading((prev) => ({
          ...prev,
          globalLoader: false,
        }));
      }
    } catch (error) {
      console.log("expection", error);
    }
  };

  // delete the upload lease aggrement as per the call of  backend service
  /**
   * 
   * @param {string} id used to delete the particular item
   */
  const deleteUploadLeaseAggrements = async (id) => {
    setLoading((prev) => ({
      ...prev,
      deleteBtnLoader: true,
    }));
    const deleteData = await deleteUploadLeaseAggrement(id);
    if (deleteData?.status) {
      console.log("dletedata", deleteData);
    } else {
      NotificationManager.error(deleteData?.message);
    }
    setLoading((prev) => ({
      ...prev,
      deleteBtnLoader: false,
    }));
    setFormDataValue({
      property: {
        value: "",
        warning: false,
        id: "",
      },
      file: {
        value: "",
        warning: false,
      },
    });
  };

  useEffect(() => {
    if (!loading.buttonLoader) getTheUploadLeaseAggrement();
  }, [loading.buttonLoader, loading.deleteBtnLoader]);
  useEffect(() => {
    if (!loading.globalLoader) getThePropertyList();
  }, []);
  return (
    <Container maxWidth={"xl"} className="mt-3">
      <div className="py-4">
        <Box>
          <Typography
            className="phone-heading-30"
            fontSize={40}
            fontWeight={700}
            color={theme.palette.text.primary}
          >
            Lease Agreement
          </Typography>
          <Typography fontSize={20} color={"text.secondary"}>
            {/* There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration  */}
          </Typography>
        </Box>
        <Box my={5}>
          <Grid container>
            <Grid item md={6} marginBottom={"24px"}>
              <Typography
                fontSize={24}
                fontWeight={600}
                color={theme.palette.text.secondary}
                marginBottom={"10px"}
              >
                Upload file
              </Typography>
              <FormControl style={{ width: "60%", marginBottom: "10px" }}>
                <InputLabel className="uilabel">Select Property</InputLabel>
                <Select
                  value={formDataValue.property.value}
                  name="property"
                  onChange={onChange}
                >
                  {propertyData?.map((item) => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  ))}
                </Select>
                {formDataValue.property.warning && (
                  <span className="text-danger">Select Property</span>
                )}
              </FormControl>
              <Box
                bgcolor={"#13556D"}
                borderRadius={"10px"}
                className="text-center px-0 py-4"
              >
                <Typography fontSize={20} fontWeight={500} color={"#FFFFFF"}>
                  Upload Agreement form
                </Typography>
                <div className="upload_area">
                  <label className="uploadImg" htmlFor="upload">
                    {uploadIcon()}
                    <Typography
                      marginTop={"10px"}
                      marginBottom={"10px"}
                      fontSize={14}
                      fontWeight={400}
                      color={"#596E73"}
                    >
                      {formDataValue.file.value
                        ? formDataValue?.file?.value?.name
                        : "Upload the document only Upload the Pdf file"}
                    </Typography>
                  </label>
                  {formDataValue.file.warning && (
                    <span className="ps-2 pt-2 d-block  text-danger text-start">
                      Upload only pdf file
                    </span>
                  )}
                  <input
                    style={{ visibility: "hidden" }}
                    id="upload"
                    type="file"
                    accept=".pdf"
                    onChange={onChange}
                    name="file"
                    key={"upload"}
                  />
                </div>
                <ButtonComponent
                  onClick={(e) => uploadtheDocToBackend(e)}
                  title={"Upload"}
                  sx={{ width: "115px" }}
                  loading={loading.buttonLoader}
                  disabled={loading.buttonLoader}
                />
              </Box>
            </Grid>
            <Grid
              className="phone-padding-0"
              item
              md={6}
              marginBottom={"24px"}
              style={{ paddingLeft: "37px" }}
            >
              <Typography
                fontSize={24}
                fontWeight={600}
                color={theme.palette.text.secondary}
                marginBottom={"10px"}
              >
                Uploaded Files
              </Typography>
              {loading?.globalLoader ? (
                <Loader />
              ) : (
                <Box className="text-start row">
                  {uploadLeaseAggrementData.length === 0 ? (
                    <h2>NO file uploaded</h2>
                  ) : (
                    uploadLeaseAggrementData?.map((item, index) => (
                      <div className="position-relative col-md-4  mb-3">
                        <div className="shadow p-3">
                          <img src={uploadPdf} alt="pdfsimage" />
                          <div
                            className="d-inline"
                            style={{
                              position: "absolute",
                              top: "20px",
                              right: "35px",
                            }}
                          >
                            <img
                              src={uploadDots}
                              alt="dotsimage"
                              onClick={() => {
                                let data = [...uploadLeaseAggrementData];
                                data.splice(index, 1, {
                                  ...data[index],
                                  dropdownShow: !data[index]?.dropdownShow,
                                });
                                setUploadLeaseAggrementData(data);
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                          {item?.dropdownShow ? (
                            <div
                              className="border position-absolute rounded d-inline-block p-2"
                              style={{
                                top: "43px",
                                right: "20px",
                                background: "#fff",
                              }}
                            >
                              <a
                                onClick={() => {
                                  setModalData(item);
                                  setViewModal(true);
                                }}
                                style={{ cursor: "pointer" }}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="d-block bg-white spanImg view_link"
                              >
                                {" "}
                                {eyeViewIcon()}View
                              </a>
                              {/* <iframe
                                src={item?.url}
                                width="100%"
                                height="500px"
                              /> */}

                              <button
                                disabled={loading.buttonLoader}
                                onClick={() => {
                                  deleteUploadLeaseAggrements(item?._id);
                                }}
                                className="d-block bg-white spanImg"
                              >
                                {removeIcon()} Remove
                              </button>
                            </div>
                          ) : null}
                          <Typography
                            fontSize={14}
                            fontWeight={400}
                            color={"#395158"}
                            marginTop={"10px"}
                          >
                            {item?.propertyName}.pdf
                          </Typography>
                        </div>
                      </div>
                    ))
                  )}
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
        <ViewPdfModal
          open={viewModal}
          setOpen={setViewModal}
          data={modalData}
        />
      </div>
    </Container>
  );
}
