import { Box, Card, Container, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LocationIcon } from "../../../assets/icons";
import inspectionHouse from "../../../assets/images/inspectionsHouse.png";
import { theme } from "../../../assets/theme";
import PaginationComponent from "../../../components/Pagination";
import { getCommissionList } from "../../../services/commisionServices";
import {
  formatCurrency,
  replaceHttpImgUrl,
} from "../../../utils/helperFunctionForValidte";
import { fDate } from "../../../utils/format-time";
import Loader from "../../../components/loader";

export default function Commission() {
  const [pagination, setPagination] = useState(1);
  const [loader, setLoader] = useState(false);
  const [commissionData, setCommsionData] = useState({
    data: [],
    pagination: {},
  });

  // get the commision list from the backend
  const getTheCommisionList = async () => {
    setLoader(true);
    const params = {
      page: pagination
    }
    const getData = await getCommissionList(params);
    console.log("getData", getData);
    if (getData?.status) {
      setCommsionData({
        data: getData?.data[0]?.data,
        pagination: getData?.data[0]?.pagination[0],
      });
    }
    setLoader(false);
  };
  useEffect(() => {
    getTheCommisionList();
  }, []);
  console.log("pagination", commissionData);
  return (
    <Container maxWidth="xl">
      <Typography
        fontSize={40}
        fontWeight={700}
        color={theme.palette.text.primary}
        // marginBottom={"20px"}
        className="title_page pt-4"
      >
        Commission
      </Typography>

      {loader ? (
        <Loader />
      ) : (
        <Grid container spacing={3} className="mt-1 mb-5">
          {commissionData?.data && commissionData?.data?.length === 0 ? (
            <h2 className="property_not_found w-100">No Commission Found</h2>
          ) : (
            commissionData?.data?.map((item) => (
              <Grid item md={4} xs={12} sm={6}>
                <Card
                  className="p-4"
                  sx={{
                    borderRadius: 3,
                    boxShadow: "5px 5px 19px 4px #0000000d",
                  }}
                >
                  <Box className="d-flex gap-4">
                    <Grid item md={2}>
                      <img
                        style={{ height: 75, width: 80 }}
                        src={replaceHttpImgUrl(item?.property_images[0]?.url)}
                      />
                    </Grid>
                    <div className="col-md-10 align-items-center d-flex ">
                      <div className="">
                        <Typography
                          fontSize={16}
                          fontWeight={700}
                          textTransform={"uppercase"}
                          color={theme.palette.text.primary}
                        >
                          {item?.property_name}
                        </Typography>
                        <Typography
                          fontSize={12}
                          fontWeight={400}
                          color={"#8D9B9F"}
                          textTransform={"uppercase"}
                          letterSpacing={2}
                        >
                          {LocationIcon()} {item?.property_address}
                        </Typography>
                      </div>
                    </div>
                  </Box>

                  <Divider className="mt-3" />
                  <div className="d-flex justify-content-between mt-3">
                    <Typography
                      fontWeight={400}
                      fontSize={16}
                      color={"#07262E"}
                    >
                      Date
                    </Typography>
                    <Typography
                      fontWeight={400}
                      fontSize={16}
                      color={"#07262E"}
                    >
                      {fDate(item?.createdAt)}
                    </Typography>
                  </div>
                  <div className="d-flex justify-content-between mt-2 mb-2">
                    <Typography
                      fontWeight={400}
                      fontSize={16}
                      color={"#07262E"}
                    >
                      Rent Amount
                    </Typography>
                    <Typography
                      fontWeight={400}
                      fontSize={16}
                      color={"#07262E"}
                    >
                      {formatCurrency(item?.rent)}
                    </Typography>
                  </div>
                  <div className="d-flex justify-content-between">
                    <Typography
                      fontWeight={700}
                      fontSize={16}
                      color={"#1B1B1B"}
                    >
                      Commission Amount
                    </Typography>
                    <Typography
                      fontWeight={700}
                      fontSize={16}
                      color={"#1B1B1B"}
                    >
                      {formatCurrency(item?.commission)}
                    </Typography>
                  </div>
                </Card>
              </Grid>
            ))
          )}
        </Grid>
      )}
      <div className="d-flex justify-content-center py-5">
        <PaginationComponent
          page={{
            pagination: pagination,
            setPagination: setPagination,
          }}
          count={commissionData?.pagination?.total}
        />
      </div>
    </Container>
  );
}
