import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "../../../assets/theme";

import Loader from "../../../components/loader";
import { GetTheRenter } from "../../../services/renterService";
import RenterCard from "./renterCard";
export default function MyRenters() {
  const [renterData, setRenterData] = useState({
    activeRenter: [],
    pastRenter: [],
  });
  const [loading, setLoading] = useState(false);
  //**Get the data of renter as per the backend **//
  const getTheRenterListFromTHeBackendActive = async () => {
    try {
      setLoading(true);
      const getData = await GetTheRenter("active");
      if (getData?.status) {
        setRenterData((prev) => ({
          ...prev,
          activeRenter: getData?.data[0]?.renters,
        }));
      }
      console.log("getDtaa", getData);
      setLoading(false);
    } catch (error) {}
  };
  const getTheRenterListFromTHePast = async () => {
    try {
      const getData = await GetTheRenter("past");
      if (getData?.status) {
        setRenterData((prev) => ({
          ...prev,
          pastRenter: getData?.data[0]?.renters,
        }));
      }
      console.log("getDtaa", getData);
    } catch (error) {}
  };

  useEffect(() => {
    getTheRenterListFromTHeBackendActive();
    getTheRenterListFromTHePast();
  }, []);
  console.log("renterlist", renterData);
  return (
    <Container maxWidth="xl" className="my-4">
      <Box marginTop={"23px"}>
        <Typography
          className="phone-heading-30"
          fontSize={40}
          fontWeight={700}
          color={theme.palette.text.primary}
          marginBottom={"20px"}
        >
          My Renters
        </Typography>
      </Box>
      <Box>
        <Typography
          marginBottom={"29px"}
          fontSize={24}
          fontWeight={700}
          color={"#07262E"}
        >
          Active Renters
        </Typography>
        <Grid container spacing={4} sx={{  '@media (max-width: 600px)': {
          marginLeft: '0px !important',width:"100%"}}}>
          {loading ? (
            <Loader />
          ) : renterData?.activeRenter?.length === 0 ? (
            <Typography
              className="phone-heading-30"
              fontSize={40}
              fontWeight={500}
              color={theme.palette.text.primary}
              marginBottom={"20px"}
              textAlign={"center"}
              sx={{ width: "100%",
                '@media (max-width: 600px)': {
          textAlign: 'left !important',}
              }}
            >
              No Active Renter Found
            </Typography>
          ) : (
            renterData?.activeRenter?.map((item) => (
              <RenterCard cardData={item} />
            ))
          )}
        </Grid>
      </Box>
      <Box marginTop={"37px"} marginBottom={"27px"}>
        <Typography
          marginBottom={"29px"}
          fontSize={24}
          fontWeight={700}
          color={"#07262E"}
        >
          Past Renters
        </Typography>
        <Grid container spacing={4} sx={{  '@media (max-width: 600px)': {
          marginLeft: '0px !important',width:"100%"}}}>
          {renterData?.pastRenter?.length === 0 ? (
            <Typography
              className="phone-heading-30"
              fontSize={40}
              fontWeight={500}
              color={theme.palette.text.primary}
              marginBottom={"20px"}
              textAlign={"center"}
              sx={{ width: "100%" ,
                '@media (max-width: 600px)': {
          textAlign: 'left !important',}
              }}
            >
              No Past Renter Found
            </Typography>
          ) : (
            renterData?.pastRenter?.map((item) => (
              <RenterCard pastRenter = {true} cardData={item} />
            ))
          )}
        </Grid>
      </Box>
    </Container>
  );
}
