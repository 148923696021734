import { Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getAdminPrivacy } from "../../services/admin/adminCmsServices";
import Loader from "../../components/loader";

const DataProtection = () => {
  //  local state
  const [localState, setLocalState] = useState({
    data: null,
    loading: false,
  });
  // get api data
  const getDataProtection = async () => {
    setLocalState((prev) => ({
      ...prev,
      loading: true,
    }));
    const res = await getAdminPrivacy("data-protection");
    if (res?.status) {
      setLocalState((prev) => ({
        ...prev,
        data: res?.data,
        loading: false,
      }));
    }
  };
  // effect for get api data

  useEffect(() => {
    getDataProtection();
  }, []);
  // show loading

  if (localState.loading) {
    return <Loader />;
  }
  return (
    <Container maxWidth="xl">
      <div className="my-5">
        <Typography color="text.secondary" fontSize={18} fontWeight={500}>
          DATA PROTECTION
        </Typography>
        <p
          className="mt-2"
          dangerouslySetInnerHTML={{
            __html: localState.data && localState?.data?.content,
          }}
        ></p>
      </div>
    </Container>
  );
};

export default DataProtection;
