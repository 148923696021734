import { Grid, Rating, Typography } from "@mui/material";
import React from "react";
import reviwImg from "../../../assets/images/reviewPic.png";
import { replaceHttpImgUrl } from "../../../utils/helperFunctionForValidte";
import moment from "moment";

export default function Ratingreview({ detailData }) {
  console.log("detial", detailData);
  return (
    <Grid container className="py-4 border-bottom">
      <Grid md={2}>
        <img
          style={{
            borderRadius: "50%",
            height: "120px",
            width: "120px",
          }}
          src={replaceHttpImgUrl(detailData?.user_image)}
        />
      </Grid>
      <Grid md={10}>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <Typography color={"#07262E"} fontWeight={700} fontSize={18}>
            {detailData?.user_name}
          </Typography>
          <Typography
            alignItems={"center"}
            justifyContent={"space-between"}
            color={"#596E73"}
            fontWeight={400}
            fontSize={11}
          >
            {moment(detailData?.createdAt)?.fromNow(true)} ago
          </Typography>
        </div>
        <Rating onChange={()=>{}} value={detailData?.rating} />
        <Typography
          className="mt-1"
          color={"#596E73"}
          fontWeight={400}
          fontSize={16}
        >
         {detailData?.review}
        </Typography>
      </Grid>
    </Grid>
  );
}
