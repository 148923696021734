import { Box, Container, Grid, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "../../../assets/theme";
import "./style.css";
import phnIcon from "../../../assets/icons/call.png";
import mailIcon from "../../../assets/icons/mail.png";
import appReviewer from "../../../assets/images/app_reviewer.png";
import { useNavigate } from "react-router-dom";
import { GetMangerListService } from "../../../services/landlorPropertyMangerService";
import Loader from "../../../components/loader";
import { sortingFilterForBlog } from "../../../utils/CONSTANTS";

const PropertyManager = () => {
  const navigate = useNavigate();
  const [filterValues,setFilterValues]=useState({
    sortBy:""
  }) 
  const [loading, setLoading] = useState({
    globalLoder: false,
  });
  const [propertyManagerData, setPropertyManagerData] = useState([]);

  // get the property manager data
  const getPropertyMangerData = async () => {
    setLoading((prev) => ({
      ...prev,
      globalLoder: true,
    }));
    const getData = await GetMangerListService();
    console.log("getDTa", getData);
    if (getData?.status) {
      setPropertyManagerData(getData?.data);
    }
    setLoading((prev) => ({
      ...prev,
      globalLoder: false,
    }));
  };

  useEffect(() => {
    getPropertyMangerData();
  }, []);
  console.log("++",filterValues)

  return (
    <div>
      {loading.globalLoder ? (
        <Loader />
      ) : (
        <Container maxWidth={"xl"} className="mt-4">
          <Box>
            <Typography
              fontSize={40}
              fontWeight={700}
              color={theme.palette.text.primary}
              marginBottom={"20px"}
              className="title_page pt-4 phone-heading-30"
            >
              Property Manager
            </Typography>
          </Box>

          {/* <Box>
            <Grid container className="top_row">
              <Grid md={8}>
                <h5 className="h5_title">List of property manager</h5>
              </Grid>
              <Grid md={4} className="select_box_wrapper">
                <div className="select_boxes p-select-box">
                  <select
                    onChange={(e)=>setFilterValues((prev)=>({
                      ...prev,
                      sortBy: e.target.value
                    }))}
                    value={filterValues?.sortBy}
                  >
                    {sortingFilterForBlog.map((curElm) => (
                      <option value={curElm.id} key={curElm?.id}>
                        {curElm?.label}{" "}
                      </option>
                    ))}
                  </select>
                  <select>
                    <option>Location</option>
                  </select>
                </div>
              </Grid>
            </Grid>
          </Box> */}

          <Box>
            <Grid className="">
              <div className="row pt-5">
                {propertyManagerData.map((item) => (
                  <div className="col-lg-4 col-md-6 col-sm-12 ">
                    <div className="manager_card">
                      <div className="manager_details">
                        <div className="img_block">
                          <img
                            onClick={() =>
                              navigate(`/manager-details/${item.id}`)
                            }
                            src={item?.pic}
                            alt=""
                          />
                        </div>
                        <div className="manager_about">
                          <h3>{item?.name}</h3>
                          <p>
                            <img src={phnIcon} alt="" />
                            {item?.phone}
                          </p>
                          <p>
                            <img src={mailIcon} alt="" />
                            {item?.email}
                          </p>
                        </div>
                      </div>
                      {/* <div className="text-center pt-4">
                        <button
                          onClick={handleButtonClick}
                          className="btn_common"
                        >
                          Terminate
                        </button>
                      </div> */}
                    </div>
                  </div>
                ))}
              </div>
            </Grid>
          </Box>
        </Container>
      )}
    </div>
  );
};

export default PropertyManager;
