// src/Calendar.js
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Checkbox,
  Container,
  Fade,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import ButtonComponent from "../../../components/Button";
import CustomCalendar from "../../../components/calendar";
import { API_Endpoints } from "../../../services/API_Endpoints";
import {
  addManageMultipleCalenderSlots,
  getTheCalenderSlot,
  getTheTimeSlotAsPerTheParticularDate,
} from "../../../services/manageCalenderSlotServices";
import { RetrieveLocalUser } from "../../../utils/authService";
import { SlotListManageCalender } from "../../../utils/CONSTANTS";
import { fDate, fDateTime } from "../../../utils/format-time";
import "./index.css";
import moment from "moment";
import Loader from "../../../components/loader";
import SuccessFullySubmitModal from "../../../components/successFullySubmittedModal";

const expandStyle = (toggle) => {
  return {
    "& .MuiAccordion-region": {
      height: toggle ? "auto" : 0,
    },
    "& .MuiAccordionDetails-root": {
      display: toggle ? "block" : "none",
    },
  };
};
const Calendar = () => {
  const [successModal,setSuccessModal] = useState(false)
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState({
    btnLoader: false,
    globalLoader: false,
  });
  const user = RetrieveLocalUser("user");
  const [getDates, setGetDates] = useState([]);
  const [morningSlot, setMorningSlot] = React.useState(true);
  const [afternoonSlot, setAfternoonSlot] = React.useState(true);
  const [selectedSlot, setSelectedSlot] = useState({
    id: "",
    time: "",
    checked: false,
    fullDayNotAvailable: false,
  });
  const [eveningSlot, setEveningSlot] = React.useState(true);
  const [slotManageArr, setSlotManageArr] = useState(SlotListManageCalender);

  // manage calender slot api intigration
  const manageCalenderSlotBook = async (e) => {
    e.preventDefault();
    if (
      !selectedSlot.fullDayNotAvailable &&
      Object.keys(slotManageArr)
        ?.map((item) => slotManageArr[item].filter((it) => it.checked))
        .flat().length === 0
    ) {
      return NotificationManager.error("", "Select the time slot");
    }
    setLoading((prev) => ({
      ...prev,
      btnLoader: true,
    }));

    // confitionally set the payload as per user selected the full day check box
    const body = selectedSlot.fullDayNotAvailable
      ? {
          slots: [
            {
              id: "",
              date: moment(selectedDate).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
              time: "",
              fullDay: selectedSlot.fullDayNotAvailable,
            },
          ],
          slots_to_delete: Object.keys(slotManageArr)
            ?.map((item) => slotManageArr[item].filter((it) => it.checked))
            .flat()
            .map((item) => item._id),
        }
      : {
          slots: Object.keys(slotManageArr)
            ?.map((item) => slotManageArr[item].filter((it) => it.checked))
            .flat()
            .map((item) => {
              return {
                id: item.id,
                date: moment(selectedDate).format(
                  "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
                ),
                time: item.time,
                fullDay: false,
                _id: item._id === undefined ? "" : item._id,
              };
            }),
          slots_to_delete: Object.keys(slotManageArr)
            ?.map((item) =>
              slotManageArr[item].filter((it) => !it.checked && it._id)
            )
            .flat()
            .map((item) => item._id),
        };

    console.log("body", body);
    const postData = await addManageMultipleCalenderSlots(body);
    console.log("___postData", postData);
    if(postData?.status){
      getTheSlotFromTheBackend()
      setSuccessModal(true)
    }
    setLoading((prev) => ({
      ...prev,
      btnLoader: false,
    }));
  };

  console.log("slotManageArr", slotManageArr);

  //get the particular time slot as per the date
  const getTheSlotFromTheBackend = async () => {
    const data = fDate(selectedDate, "yyyy-MM-dd").split("-");
    const params = {
      year: Number(data[0]),
      month: Number(data[1]),
      day: Number(data[2]),
      landlordID: user._id,
    };
    const findDate = getDates?.find(
      (item) =>
        fDate(item?.date || item?.inspectionDate || selectedDate, "yyyy-MM-dd") ==
        fDate(selectedDate, "yyyy-MM-dd")
    );
    if (findDate) {
      setLoading((prev) => ({
        ...prev,
        globalLoader: true,
      }));
      const getData = await getTheTimeSlotAsPerTheParticularDate(params);
      console.log("getdata", getData);
      const getTimeslot = filterTimeSlotBooking(
        getData.data[0]?.data[0]?.blocked_dates
      );
      setSlotManageArr(getTimeslot);
      console.log("slt", getTimeslot);
    } else {
      const getTimeslot = filterTimeSlotBooking();
      setSlotManageArr(getTimeslot);
    }

    setLoading((prev) => ({
      ...prev,
      globalLoader: false,
    }));
  };

  /**
   *
   * @param  filterArr
   * @returns a sloted array which is filter as per the requirement
   */
  const filterTimeSlotBooking = (filterArr) => {
    let slotData = { ...slotManageArr };
    if (filterArr) {
      //convert the object key into array  and that particular key contain an array
      Object.keys(slotData).forEach((keyName) => {
        slotData[keyName].forEach((el) => {
          filterArr?.forEach((inel) => {
            if (inel.fullDay) {
              setSelectedSlot((prev) => ({
                ...prev,
                fullDayNotAvailable: true,
              }));
              el.checked = false;
              el._id = inel._id;
            } else if (el.id === inel.id) {
              el.checked = true;
              setSelectedSlot((prev) => ({
                ...prev,
                fullDayNotAvailable: false,
              }));
              el._id = inel._id;
            }
          });
        });
      });
    } else {
      Object.keys(slotData).forEach((keyName) => {
        slotData[keyName].forEach((el) => {
          el.checked = false;
          delete el._id;
        });
      });
    }
    return slotData;
  };

  /**
   * Updates the specified key in the key array based on the event.
   *
   * @param e     The event that triggers the update.
   * @param key   The key for the object to be updated.
   * @param index The index in the key array to be updated.
   */
  const selectMultipleSlot = (e, key, index) => {
    const { checked } = e.target;
    let data = { ...slotManageArr };
    data[key][index].checked = checked;
    setSlotManageArr(data);
  };
  // const get the calender slot of the particular date
  const getTheCalenderSlotasPerDate = async () => {
    const getData = await getTheCalenderSlot(
      API_Endpoints.manageCalenderSlot.addMangeCalenderSlot
    );
    if (getData?.status) {
      setGetDates(getData?.data);
      console.log("gtdata", getData);
     
    } else {
      console.log("getdaty", getData);
    }
  };

  console.log("--===", selectedDate);

  useEffect(() => {
    if (!loading.btnLoader) getTheCalenderSlotasPerDate();
  }, [loading.btnLoader]);
  useEffect(() => {
    getTheSlotFromTheBackend();
    setSelectedSlot((prev) => ({
      ...prev,
      fullDayNotAvailable: false,
    }));
  }, [selectedDate]);
  return loading.globalLoader ? (
    <Loader />
  ) : (
    <Container maxWidth="xl">
      <div className="py-5 manage_calender">
        <Typography
          className="phone-heading-30"
          fontSize={40}
          fontWeight={700}
          color={"text.primary"}
          marginBottom={"20px"}
        >
          Manage Calendar
        </Typography>
        <Grid container spacing={4}>
          <Grid item md={6} sx={{
            '@media (max-width: 600px)': {
         width:"100% !important"
        },
          }}>
            <CustomCalendar
              setSelectedDate={setSelectedDate}
              selectedDate={selectedDate}
              setCurrentMonth={setCurrentMonth}
              currentMonth={currentMonth}
              fetchedDates={
                getDates &&
                getDates?.map((item) =>
                  fDate(item?.date || item?.inspectionDate, "yyyy-MM-dd")
                )
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Card
              className="py-3 phone-padding-0"
              elevation={0}
              sx={{
                pr: 3,
                border: "1px solid #DBDBDB",
                borderRadius: 2,
              }}
            >
              <div className="calendar-right">
                <Typography
                  color={"text.secondary"}
                  fontSize={18}
                  fontWeight={"bold"}
                >
                  Time Slot
                </Typography>
                <div className="time-slot-options py-3">
                  <input
                    checked={selectedSlot.fullDayNotAvailable}
                    onChange={(e) => {
                      // if user click on the fulldaynotavailable empty there slot value 's
                      if (e.target.checked) {
                        const getTimeslot = filterTimeSlotBooking();
                        setSlotManageArr(getTimeslot);
                      }

                      setSelectedSlot((prev) => ({
                        ...prev,
                        id: "",
                        time: "",
                        checked: false,
                        fullDayNotAvailable: e.target.checked,
                      }));
                    }}
                    type="checkbox"
                    id="fullDay"
                  />
                  <label htmlFor="fullDay"> Full day not available</label>
                  <Card
                    elevation={0}
                    className="mt-2"
                    sx={{ border: "1px solid #DBDBDB", borderRadius: 2 }}
                  >
                    <Accordion
                      elevation={0}
                      expanded={morningSlot}
                      onChange={() =>
                        setMorningSlot((prevExpanded) => !prevExpanded)
                      }
                      slots={{ transition: Fade }}
                      slotProps={{ transition: { timeout: 400 } }}
                      sx={expandStyle(morningSlot)}
                    >
                      <AccordionSummary
                        expandIcon={<>{">"}</>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography
                          color={"text.secondary"}
                          fontSize={18}
                          fontWeight={"bold"}
                        >
                          Morning
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="time-buttons">
                          {slotManageArr.morningSlot.map((item, index) => (
                            <>
                              {" "}
                              <label
                                htmlFor={item.id}
                                key={item.id}
                                className={
                                  item.checked
                                    ? "time-button label_selected"
                                    : "time-button"
                                }
                              >
                                {item.time}
                              </label>
                              <Checkbox
                                name="radio"
                                key={item.id}
                                checked={item.checked}
                                className="d-none"
                                id={item.id}
                                disabled={selectedSlot.fullDayNotAvailable}
                                onChange={(e) => {
                                  selectMultipleSlot(e, "morningSlot", index);
                                }}
                              />
                            </>
                          ))}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </Card>

                  <Card
                    className="mt-2"
                    elevation={0}
                    sx={{ border: "1px solid #DBDBDB", borderRadius: 2 }}
                  >
                    <Accordion
                      elevation={0}
                      expanded={afternoonSlot}
                      onChange={() =>
                        setAfternoonSlot((prevExpanded) => !prevExpanded)
                      }
                      slots={{ transition: Fade }}
                      slotProps={{ transition: { timeout: 400 } }}
                      sx={expandStyle(afternoonSlot)}
                    >
                      <AccordionSummary
                        expandIcon={<>{">"}</>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography
                          color={"text.secondary"}
                          fontSize={18}
                          fontWeight={"bold"}
                        >
                          Afternoon
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="time-buttons">
                          {slotManageArr.afterNoonSlot.map((item, index) => (
                            <>
                              {" "}
                              <label
                                htmlFor={item.id}
                                key={item.id}
                                className={
                                  item?.checked
                                    ? "time-button label_selected"
                                    : "time-button"
                                }
                              >
                                {item.time}
                              </label>
                              <Checkbox
                                key={item.id}
                                disabled={selectedSlot.fullDayNotAvailable}
                                name="radio"
                                className="d-none"
                                id={item.id}
                                checked={item?.checked}
                                onChange={(e) =>
                                  selectMultipleSlot(e, "afterNoonSlot", index)
                                }
                              />
                            </>
                          ))}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </Card>
                  <Card
                    className="mt-2"
                    elevation={0}
                    sx={{ border: "1px solid #DBDBDB", borderRadius: 2 }}
                  >
                    {" "}
                    <Accordion
                      elevation={0}
                      expanded={eveningSlot}
                      onChange={() =>
                        setEveningSlot((prevExpanded) => !prevExpanded)
                      }
                      slots={{ transition: Fade }}
                      slotProps={{ transition: { timeout: 400 } }}
                      sx={expandStyle(eveningSlot)}
                    >
                      <AccordionSummary
                        expandIcon={<>{">"}</>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography
                          color={"text.secondary"}
                          fontSize={18}
                          fontWeight={"bold"}
                        >
                          Evening
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="time-buttons">
                          {slotManageArr.eveningSlot.map((item, index) => (
                            <>
                              {" "}
                              <label
                                htmlFor={item.id}
                                key={item.id}
                                className={
                                  item?.checked
                                    ? "time-button label_selected"
                                    : "time-button"
                                }
                              >
                                {item.time}
                              </label>
                              <Checkbox
                                key={item.id}
                                disabled={selectedSlot.fullDayNotAvailable}
                                name="radio"
                                checked={item?.checked}
                                className="d-none"
                                id={item.id}
                                onChange={(e) =>
                                  selectMultipleSlot(e, "eveningSlot", index)
                                }
                              />
                            </>
                          ))}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </Card>
                </div>

                <div className={"d-flex flex-row-reverse"}>
                  <ButtonComponent
                    title={"Update Calendar"}
                    sx={{ height: 64, width: 194, borderRadius: 2,'@media (max-width: 600px)': {
          height: '50px',  // 
        }, }}
                    onClick={(e) => manageCalenderSlotBook(e)}
                    loading={loading.btnLoader}
                    disabled={loading.btnLoader}
                  />
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
        <SuccessFullySubmitModal open={successModal} setOpen={setSuccessModal} headingAndText={ {
          mainHeading:"Your Slot is updated successfully"
        }}
        btnText="Close"
        onCLick={()=>{
          setSuccessModal(false)
        }}
        />
      </div>
    </Container>
  );
};

export default Calendar;
