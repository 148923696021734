import {
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import { useLocation, useNavigate } from "react-router-dom";
import { DownArrow, UpArrow } from "../../assets/svg";
import { routesConfig } from "../../routes/routesConfig";
import { switchTheUserRole } from "../../services/switchServices";
import { RetrieveLocalUser, setLocalUser } from "../../utils/authService";
import { ROLES } from "../../utils/CONSTANTS";
import SidebarFooter from "./SidebarFooter";
const Sidebar = ({ open, setOpen, setLogoutDialog }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openSubSidebar, setOpenSubSidebar] = useState({});
  const user = RetrieveLocalUser("user");
  console.log(routesConfig, "routesConfig");

  /**
   *switch the user role  if user is renter then it will switch to landlord or property manager and vice-versa
   *
   * @param {object} body - object contain key of [role] of the current user
   */
  const switchTheUserROLES = async (body) => {
    const postData = await switchTheUserRole(body);
    console.log("post", postData);
    if (postData?.status) {
      // RetrieveLocalUser("persist:root")
      setLocalUser({
        ...postData?.data,
        accessToken: postData?.accessToken,
      });
      window.location.reload(); // reload this because the new token and user state will updated on localstorage
    } else {
      NotificationManager.error("", postData?.message);
    }
  };
  return (
    <Drawer
      PaperProps={{
        sx: {
          width: 415,
          zIndex: 0,
        },
      }}
      anchor="right"
      open={open}
      onClose={() => setOpen(false)}
    >
      <List
        className="main-sidebar"
        sx={{
          height: "100vh",
          display: "flex",
          marginTop: 10,
          flexDirection: "column",
        }}
      >
        {routesConfig.map((item, index) => {
          if (!user && item.role[0] === "public") {
            return (
              <ListItem
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(item.link);
                  setOpen(false);
                }}
                className={
                  item.link === "/blogs" ||
                  item.link === "/property-management" ||
                  item.link === "/login"
                    ? "d-none phone-show"
                    : ""
                }
                style={{
                  marginTop: "10px",
                  marginLeft: "20px",
                  color: "#000",
                }}
              >
                <Typography fontWeight={400}> {item?.title}</Typography>
              </ListItem>
            );
          } else {
            const getBoldRoleTitle = (title) => {
              if (title.startsWith("Switch to")) {
                const [prefix, role] = item.title.split(" to ");
                return (
                  <div className="d-flex align-items-center">
                    <Typography width={"100%"}>
                      {prefix} to<b> {role}</b>
                    </Typography>
                  </div>
                );
              }
              return title;
            };
            return (
              item.role.includes(user?.role) && (
                <>
                  <ListItem
                    // LinkComponent={"a"}
                    onClick={(e) => {
                      if (item?.children?.length > 0) {
                        setOpenSubSidebar((prevState) => ({
                          ...prevState,
                          [index]: !prevState[index],
                        }));
                      }
                      if (item?.isLoggedOutItem) {
                        setLogoutDialog(true);
                      }
                      if (item?.link === "/property-manager") {
                        switchTheUserROLES({
                          role: ROLES.propertyManager,
                        });
                      } else if (item?.link === "/renter") {
                        switchTheUserROLES({
                          role: ROLES.renter,
                        });
                      } else if (item?.link === "/landlord-dashboard") {
                        switchTheUserROLES({
                          role: ROLES.landlord,
                        });
                      }
                      if (!item?.isLoggedOutItem && item?.link) {
                        navigate(item?.link);
                      }
                      // open the sidebar as per the main values doesnot have the children array to expand
                      if (!item?.children?.length > 0) {
                        setOpen(false);
                      }
                    }}
                    className=" d-flex justify-content-between "
                    sx={{ cursor: "pointer" }}
                    key={index}
                  >
                    <div className="d-flex align-items-center w-100 px-5">
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText
                        sx={{
                          fontSize: 18,
                          color:
                            location.pathname === item.link
                              ? "#009DA6"
                              : "#07262E",
                          fontWeight:
                            location.pathname === item.link ? "bold" : "",
                        }}
                        primary={getBoldRoleTitle(item.title)}
                      />
                      {item?.children?.length > 0 ? (
                        <>
                          {openSubSidebar[index] ? <DownArrow /> : <UpArrow />}
                        </>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </ListItem>
                  <Collapse in={openSubSidebar[index]}>
                    <List
                      sx={{ overflow: "auto !important", height: "auto" }}
                      component="div"
                    >
                      {item?.children &&
                        item?.children?.length > 0 &&
                        item.children.map((subMenu, i) => {
                          return (
                            subMenu.role.includes(user?.role) && (
                              <ListItem
                                sx={{ pl: "20%", cursor: "pointer" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate(subMenu?.link);
                                  setOpen(false);
                                }}
                                key={i}
                              >
                                <ListItemIcon>{subMenu?.icon}</ListItemIcon>
                                <ListItemText
                                  primary={subMenu?.title}
                                  sx={{
                                    fontSize: 18,
                                    color:
                                      location.pathname === subMenu.link
                                        ? "#009DA6"
                                        : "#07262E",
                                    fontWeight:
                                      location.pathname === subMenu.link
                                        ? "bold"
                                        : "",
                                  }}
                                />
                              </ListItem>
                            )
                          );
                        })}
                    </List>
                  </Collapse>
                </>
              )
            );
          }
        })}
      </List>
      <div style={{ paddingLeft: "20%" }}>
        <SidebarFooter />
      </div>
    </Drawer>
  );
};

export default Sidebar;
