import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Rating,
  Select,
  Typography,
} from "@mui/material";

import { theme } from "../../assets/theme";
import testimonialsBanner from "../../assets/images/testimonialsBanner.png";
import user from "../../assets/images/user.png";
import { getTestimonialList } from "../../services/admin/adminCmsServices";
import Loader from "../../components/loader";
import { getImageUrl } from "../../utils/methods";
import { sortingFilter } from "../../utils/CONSTANTS";

const Testimonials = () => {
  //  local state
  const [localState, setLocalState] = useState({
    data: [],
    sortKey: {
      id: 3,
      value: "name",
      order: "desc",
    },
    loading: false,
  });
  // get api data

  const getTestomonialData = async () => {
    setLocalState((prev) => ({
      ...prev,
      loading: true,
    }));
    const param = {
      page: 1,
      search: "",
      order: localState?.sortKey?.order,
      value: localState?.sortKey?.value,
    };
    const res = await getTestimonialList(param);
    if (res?.status) {
      setLocalState((prev) => ({
        ...prev,
        data: res?.data[0]?.data,
        loading: false,
      }));
    }
  };

  // handle sort select function

  const handleSortSelect = async (e) => {
    setLocalState((prev) => ({
      ...prev,
      sortKey: sortingFilter.find((item) => item.id === e?.target?.value),
    }));
  };
  // effect for get api data
  useEffect(() => {
    getTestomonialData();
  }, [localState?.sortKey]);
  // show loading

  if (localState.loading) {
    return <Loader />;
  }
  return (
    <div>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        px={6}
        alignItems={"center"}
        sx={{
          backgroundImage: `url(${testimonialsBanner})`,
          width: "100%",
          backgroundSize: "cover",
          height: 497,
          '@media (max-width: 600px)': {
          height: '320px',  // 
        },
        }}
      >
        <div className="testiominal_block">
          <Typography className="testimonial_title" fontSize={56} fontWeight={"bold"} color={"white"}>
            Testimonial
          </Typography>
          <Typography fontSize={24} color={"white"}>
            Voices of satisfaction: Real stories, real results
          </Typography>
        </div>
      </Box>

      <Container maxWidth="xl">
        <div className="d-flex justify-content-center flex-column align-items-center">
          <div className="d-flex align-items-center gap-2 mt-5">
            <hr style={{ border: "1px solid #008F97", width: 40 }} />
            <Typography color="#009DA6" fontSize={24}>
              Testimonials
            </Typography>
          </div>
          <Typography
            fontSize={40}
            fontWeight={500}
            color={theme.palette.text.primary}
            className="title_hear_stories"
          >
            The pain points we are solving
          </Typography>
        </div>

        <div className="d-flex recent_top justify-content-between align-items-center  mt-4 mb-3">
          <Typography
            fontSize={24}
            color={theme.palette.text.primary}
            fontWeight={500}
          >
            Most Recent
          </Typography>
          <FormControl>
            <InputLabel>Recent</InputLabel>
            <Select
              sx={{ width: 275, borderColor: "#DBDBDB" }}
              label="Recent"
              placeholder="Recent"
              onChange={handleSortSelect}
              value={localState.sortKey.id}
            >
              {sortingFilter.map((curElm) => (
                <MenuItem value={curElm.id} key={curElm?.id}>
                  {curElm?.label}{" "}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <Grid container spacing={3} mt={3} mb={5}>
          {localState?.data &&
            localState?.data?.map((item) => (
              <Grid className="mobile_100" item md={4} key={item._id}>
                <Card
                  elevation={0}
                  sx={{ border: "1px solid #F1F1F1", p: 3, borderRadius: 2 }}
                  className="testimonial_card testimonial_card_2"
                >
                  <div className="d-flex  gap-3">
                    <img
                      src={getImageUrl(item?.media, "testimonials")}
                      alt="testimonialImage"
                      style={{ height: "100px" }}
                    />
                    <div>
                      <Typography
                        fontSize={24}
                        fontWeight={500}
                        color={"text.secondary"}
                        className="testi_title"
                      >
                        {item?.name}
                      </Typography>
                      <Typography
                        color={"#009DA6"}
                        fontSize={18}
                        fontWeight={500}
                      >
                        {item?.role}
                      </Typography>
                      {/*  <Rating
                        readOnly
                        sx={{
                          "& .MuiRating-iconFilled": {
                            color: "#D3DF5E", // Change the color of the filled stars
                          },
                        }}
                        size="small"
                        value={5}
                      />*/}
                    </div>
                  </div>

                  <Typography
                    className="mt-4"
                    fontSize={14}
                    fontWeight={500}
                    color={"text.primary"}
                    dangerouslySetInnerHTML={{
                      __html: item?.description,
                    }}
                  />
                </Card>
              </Grid>
            ))}
        </Grid>
      </Container>
    </div>
  );
};

export default Testimonials;
