import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { theme } from "../../../assets/theme";
import LandlordCommonModal from "../../../components/landlordCommonModal";
import Loader from "../../../components/loader";
import {
  CancelInspectionRequestByRenterService,
  GetInsepctionsListService,
} from "../../../services/inspections.services";
import { inspectionApplicationRequestStatus } from "../../../utils/appConstants";
import { InspectionStatus } from "../../../utils/CONSTANTS";
import InspectionApplicationRequestModal from "./inspectionRequestModal";
import "./style.css";
import { fDate } from "../../../utils/format-time";
import ButtonComponent from "../../../components/Button";
import { NotificationManager } from "react-notifications";
const InspectionRequest = () => {
  const navigate = useNavigate();
  const [cancelModal, setCancelModal] = useState(false);
  const [apiNotCallOnCancel, setApiNotCallOnCancel] = useState("");
  const [acceptModal, setAcceptModal] = useState(false);
  const [mainApiArray, setMainApiArray] = useState([]);
  const [inspectionListing, setInspectionListing] = useState([]);
  const [loading, setLoading] = useState(false);
  const [btnLoder, setBtnLoader] = useState(false);
  const [filterValue, setFilterValue] = useState(
    inspectionApplicationRequestStatus.pending
  );
  const [modalData, setModalData] = useState({});
  // get the list of the inspection from the backend
  const getTheInspectionListFromBackend = async () => {
    setLoading(true);
    const data = await GetInsepctionsListService();
    console.log("-data", data);
    setMainApiArray(data?.data);
    if (data?.status) {
      const dataFilter = data?.data?.filter(
        (item) => item.inspectionStatus == InspectionStatus?.INITIATED
      );
      setInspectionListing(dataFilter);
    }
    setLoading(false);
  };

  // accept the request api initgration
  const acceptTheRequest = async (inspectionData) => {
    setLoading(true);
    const body = {
      status: inspectionApplicationRequestStatus?.accept,
      inspectionID: inspectionData?._id,
    };
    const acceptData = await CancelInspectionRequestByRenterService(body);
    console.log("dtaa", acceptData);
    if (acceptData?.status) {
      console.log("accept ", acceptData);
      setModalData(inspectionData);

      setAcceptModal(true);
    } else {
      NotificationManager.error("", acceptData?.message);
    }
    setLoading(false);
  };
  // const filter the data
  const filterValueData = (value) => {
    setFilterValue(value);
    let filterArr = [...mainApiArray];
    if (value === inspectionApplicationRequestStatus.accept) {
      const data = filterArr?.filter(
        (item) => item.inspectionStatus === InspectionStatus?.UPCOMING
      );
      setInspectionListing(data);
    } else if (value === inspectionApplicationRequestStatus.pending) {
      const data = filterArr?.filter(
        (item) => item.inspectionStatus === InspectionStatus?.INITIATED
      );
      setInspectionListing(data);
    }
  };
  console.log("-ssssdata", inspectionListing);
  useEffect(() => {
    if (!cancelModal && !acceptModal) {
      // this check is implemented if user click on cancel button and outside of the modal
      if (apiNotCallOnCancel === "cancel") {
        return;
      }
      getTheInspectionListFromBackend();
    }
  }, [cancelModal, acceptModal]);
  console.log("modaldaa", modalData);
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <Container maxWidth={"xl"} className="my-3">
          <Box>
            <Typography
              fontSize={40}
              fontWeight={700}
              color={theme.palette.text.primary}
              marginBottom={"20px"}
              className="title_page pt-4 phone-heading-30"
            >
              Inspection Request
            </Typography>
          </Box>
          <Box>
            <Grid container className="top_row align-items-center">
              <Grid md={8}>
                <h5 className="h5_title ">List of Request</h5>
              </Grid>
              <Grid md={4}>
                <div className="tabs_style">
                  <button
                    onClick={() => {
                      filterValueData(
                        inspectionApplicationRequestStatus.pending
                      );
                    }}
                    className={
                      filterValue === inspectionApplicationRequestStatus.pending
                        ? `btn_tab active`
                        : "btn_tab"
                    }
                  >
                    Pending
                  </button>
                  <button
                    onClick={() => {
                      filterValueData(
                        inspectionApplicationRequestStatus.accept
                      );
                    }}
                    className={
                      filterValue === inspectionApplicationRequestStatus.accept
                        ? `btn_tab active`
                        : "btn_tab"
                    }
                  >
                    Accepted
                  </button>
                </div>
              </Grid>
            </Grid>
          </Box>

          <Box className="mb-5">
            {inspectionListing?.length === 0 ? (
              <h3 className="np_req">No Inspection Request Found</h3>
            ) : (
              inspectionListing &&
              inspectionListing?.map((inspectionData) => (
                <Grid container className="pt-3">
                  <Grid
                    className="applications_card my-1"
                    sx={{
                      borderRadius: 3,
                      boxShadow: "5px 5px 19px 4px #0000000d",
                    }}
                  >
                    <Grid className="left_detail">
                      <div
                        className="img_block"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(
                            `/inspection-requests-detail/${inspectionData?._id}`
                          )
                        }
                      >
                        <img
                          className="img-fluid"
                          style={{
                            width: "120px",
                            height: "120px",
                            borderRadius: "50%",
                          }}
                          src={inspectionData?.RenterDetails?.picture}
                          alt=""
                        />
                      </div>
                      <div className="app_person_details">
                        <h3>{inspectionData?.RenterDetails?.fullName}</h3>
                        <h5>{inspectionData?.message}</h5>
                        <p>{inspectionData?.RenterDetails?.phone}</p>
                        <span>
                          {fDate(inspectionData?.inspectionDate)}
                          {"  "}
                          {inspectionData?.inspectionTime}
                        </span>
                      </div>
                    </Grid>
                    {inspectionData.inspectionStatus ===
                    InspectionStatus.INITIATED ? (
                      <Grid className="right_btns text-end inspection-btns-sec">
                        <button
                          onClick={() => {
                            setCancelModal(true);
                            setModalData(inspectionData);
                          }}
                          className="btn_common reject"
                        >
                          Reject
                        </button>
                        <ButtonComponent
                          onClick={() => acceptTheRequest(inspectionData)}
                          className="btn_common"
                          title={"Accept"}
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              ))
            )}
          </Box>
          <LandlordCommonModal
            open={cancelModal}
            setOpen={setCancelModal}
            heading={"Reason for Rejection"}
            heading2={"Reason for Rejection"}
            detailData={{
              ...modalData,
              property_info: [
                {
                  propertyName: modalData?.propertyName,
                },
              ],
            }}
            apiNotCallOnCancel={setApiNotCallOnCancel}
            notificationText={`A notification has been sent to ${modalData?.RenterDetails?.fullName} regarding his Inspection Request`}
          />
          <InspectionApplicationRequestModal
            open={acceptModal}
            setOpen={setAcceptModal}
            heading={"Request Accepted"}
            heading2={`You have accepted the inspection request of ${
              modalData?.RenterDetails?.fullName
            } for “${
              modalData?.propertyName
            }” on ${fDate(modalData?.inspectionDate)}`}
            Customfunction={() => {
              navigate("/inspection-requests");
              setApiNotCallOnCancel("");
            }}
          />
        </Container>
      )}
    </div>
  );
};

export default InspectionRequest;
