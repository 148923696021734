import { Icon } from "@iconify/react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { ImCross } from "react-icons/im";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from "react-router-dom";
import {
  AttachmentIcon,
  DownloadFromAppleStore,
  DownloadFromGooglePlay,
} from "../../../assets/svg";
import SectionLoader from "../../../components/SectionLoader";
import useSocket from "../../../hooks/useSocket";
import {
  getTheAllContactsOfChat,
  uploadImageAndDoc,
} from "../../../services/socket.services";
import { RetrieveLocalUser } from "../../../utils/authService";
import { ROLES } from "../../../utils/CONSTANTS";
import { fDateTime } from "../../../utils/format-time";
import { baseURL } from "../../../utils/axios";
import { baseurl } from "../../../utils/socketIo";
import ViewPdfModal from "../../../components/viewModal";
import { NotificationManager } from "react-notifications";
const inputTypeForChat = {
  image: "image",
  document: "document",
};
const acceptStringOfInputFile = {
  document: "application/pdf",
  image: "image/png, image/gif, image/jpeg",
  folderName: "chatMedia",
};
const Chat = () => {
  const location = useLocation();
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState({
    imageDocUpload: false,
  });
  const userData = RetrieveLocalUser("user");
  const [showUploadFile, setShowUploadFile] = useState(false);
  const [inputType, setInputType] = useState({
    inputTypeValue: "file",
    mediaType: "text",
    file: {},
    viewPdfData: {},
  });
  const [viewModal, setViewModal] = useState(false);
  const [contactListAndSelectedValue, setContactListAndSelectedValue] =
    useState({
      contactData: [],
      contactSelectedValue: "",
    });
  const {
    isConnected,
    messages,
    sendMessage,
    chatList,
    joinChatHandler,
    selectedChat,
    setSelectedChat,
    isShowDownloadApp,
    setCurrentRoom,
    chatListLoader,
    messageListLoader,
    getAllChats,
    typingStatus,
    onChatSearchHandler,
    fetchMoreData,
    setChatList,
    sendTypingStatus,
    setChatListLoader,
    setCurrentSelectedUser,
  } = useSocket();

  useEffect(() => {
    if (location.state) {
      joinChatHandler(location.state);
    } else {
      getAllChats();
    }
  }, [location.state]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      sendMessage(message.trim(), inputType.mediaType);
      setMessage("");
    }
  };
  console.log("messages", messages);

  // upload the file
  const uploadtheFiles = async () => {
    if (inputType.inputTypeValue == "file") {
      const body = {
        folder: acceptStringOfInputFile.folderName,
        mediaType: inputType.mediaType,
        media: inputType.file,
      };
      setLoader((prev) => ({
        ...prev,
        imageDocUpload: true,
      }));
      const uploadData = await uploadImageAndDoc(body);
      console.log("uploadData", uploadData);
      if (uploadData?.status) {
        setInputType((prev) => ({
          ...prev,
          mediaType: "text",
          inputTypeValue: "text",
          file: {},
        }));
        sendMessage(
          acceptStringOfInputFile.folderName +
            "/" +
            uploadData?.data[0]?.filename,
          inputType.mediaType
        );
      } else {
        NotificationManager.error("", uploadData?.message);
      }
      setLoader((prev) => ({
        ...prev,
        imageDocUpload: false,
      }));
    }
  };

  // start the new chat
  const startTheNewChat = async (data) => {
    setCurrentSelectedUser(data);
  };
  console.log("input", inputType);
  // contact listing
  const getTheContactList = async () => {
    const getData = await getTheAllContactsOfChat(userData?._id);
    console.log("getdata", getData);
    if (getData?.status) {
      setContactListAndSelectedValue((prev) => ({
        ...prev,
        contactData: getData?.data[0]?.data,
      }));
    }
  };

  // onchange
  const onChange = (e) => {
    console.log("Selected files:", e.target.files);
    const filesArray = Array.from(e.target.files);
    setInputType((prev) => ({
      ...prev,
      file: e.target.files[0],
    }));
  };

  /**
   *
   * @param {string} mediaType user click on the image or document
   */
  const inputTypeChange = (mediaType) => {
    setInputType((prev) => ({
      ...prev,
      inputTypeValue: "file",
      mediaType: mediaType,
    }));
  };
  // when user only typing then this useeffect will call
  useEffect(() => {
    sendTypingStatus(true);
    const timer = setTimeout(() => sendTypingStatus(false), 1000);
    return () => clearTimeout(timer);
  }, [message]);

  // when user switch the chat then refresh the message and the typing status
  useEffect(() => {
    sendTypingStatus(false);
    setMessage("");
  }, [selectedChat]);
  console.log("chatList", chatList);

  useEffect(() => {
    getTheContactList();
  }, []);
  return (
    <Grid container mb={3} justifyContent={"unset"} className="mb-5">
      {/* Sidebar */}
      <Grid item md={4} xs={12}>
        <Box
          className="chatsidebar"
          sx={{
            height: "auto",
            width: "100%",
            background: "#F4F4F4",
            borderRadius: 2,
            overflowY: "auto",
          }}
          p={0}
        >
          <Grid container>
            <Grid xs={6}>
              <Typography
                fontSize={40}
                fontWeight={"bold"}
                color="text.primary"
                p={3}
              >
                Chats
              </Typography>{" "}
            </Grid>
            <Grid xs={6} marginTop={5} className="contact_chat">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Contact's</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={contactListAndSelectedValue?.contactSelectedValue}
                  onChange={(e) => {
                    setContactListAndSelectedValue((prev) => ({
                      ...prev,
                      contactSelectedValue: e.target.value,
                    }));
                    startTheNewChat(e.target.value);
                  }}
                >
                  {contactListAndSelectedValue &&
                    contactListAndSelectedValue?.contactData?.map(
                      (item, index) => (
                        <MenuItem value={item?._id}>{item.fullName}</MenuItem>
                      )
                    )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <div className="text-center">
            <OutlinedInput
              onChange={onChatSearchHandler}
              className="mt-0"
              placeholder="Search"
              fullWidth
              sx={{ background: "white", maxWidth: "90%" }}
              startAdornment={
                <InputAdornment position="end" sx={{ mr: 1 }}>
                  <Icon
                    icon="eva:search-fill"
                    sx={{ color: "text.disabled", width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
            />
          </div>

          <List>
            {chatListLoader ? (
              <SectionLoader />
            ) : chatList?.length === 0 ? (
              `Your inbox is currently empty start a conversion with ${
                userData?.role === ROLES.landlord ||
                userData?.role === ROLES.propertyManager
                  ? "renter"
                  : "landlord/property-manager"
              }`
            ) : (
              chatList.length > 0 &&
              chatList.map((item, index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <Typography
                      mb={3}
                      fontSize={14}
                      color="#596E73"
                      fontWeight={300}
                    >
                      {item.user_details.updatedAt}
                    </Typography>
                  }
                  sx={{
                    background:
                      selectedChat === item.user_details ? "#fff" : "",
                    my: 2,
                    py: 2,
                    gap: 2,
                    cursor: "pointer",
                    ml: 0,
                  }}
                  onClick={() => {
                    setSelectedChat(item.user_details);
                    setCurrentRoom(item._id);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar sx={{ height: 84, width: 84, borderRadius: 50 }}>
                      <img
                        src={item.user_details.picture}
                        alt={item.user_details.fullName}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.user_details.fullName}
                    secondary={
                      <>
                        <p>
                          {item?.last_message?.includes("chatMedia/")
                            ? "mediaFile share"
                            : item?.last_message}
                        </p>
                        {item?._id === typingStatus?.room_id &&
                        typingStatus?.isTyping ? (
                          <p>typing...</p>
                        ) : null}
                      </>
                    }
                    secondaryTypographyProps={{
                      fontSize: 16,
                      color: "#596E73",
                      fontWeight: 300,
                    }}
                    primaryTypographyProps={{
                      fontSize: 18,
                      fontWeight: "bold",
                      color: "text.primary",
                    }}
                  />
                </ListItem>
              ))
            )}
          </List>
        </Box>
      </Grid>

      {/* Chat Area */}
      <Grid item md={8} xs={12} className="chat-space">
        {isShowDownloadApp ? (
          <div className="d-flex justify-content-center align-items-center flex-column h-100 chat-section">
            <img
              src={require("../../../assets/images/mobileScreen.png")}
              alt="Mobile App"
            />
            <Typography
              className="mt-3"
              fontSize={24}
              fontWeight={500}
              color="text.primary"
            >
              Get the App
            </Typography>
            <div className="d-flex justify-content-center align-items-center mt-3 gap-3">
              <DownloadFromAppleStore />
              <DownloadFromGooglePlay />
            </div>
          </div>
        ) : (
          <>
            <AppBar
              component="nav"
              elevation={0}
              position="relative"
              sx={{ background: "#F4F4F4", p: 2 }}
            >
              <Toolbar>
                <div className="d-flex gap-3 align-items-center">
                  <Avatar
                    sx={{ height: 64, width: 64 }}
                    src={selectedChat?.picture}
                  />
                  <Typography fontSize={24} fontWeight={"bold"}>
                    {selectedChat?.fullName}
                  </Typography>
                </div>
              </Toolbar>
              <div className="typing-indicators">
                {typingStatus?.isTyping ? <p>typing...</p> : null}
              </div>
            </AppBar>

            {loader?.imageDocUpload ? (
              <h6>Uploading files</h6>
            ) : (
              <div className="main-content px-4 chat-section">
                <div
                  className="messages-container"
                  id="scrollableDiv"
                  style={{
                    height: "calc(100vh - 150px)",
                    overflowY: "auto",
                    position: "relative",
                  }}
                >
                  {messageListLoader ? (
                    <SectionLoader />
                  ) : (
                    <div>
                      <InfiniteScroll
                        dataLength={messages.length}
                        next={fetchMoreData}
                        hasMore={true}
                        loader={
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              textAlign: "center",
                              padding: "10px",
                              width: "100%",
                              background: "#fff",
                              zIndex: 1,
                            }}
                          >
                            Loading...
                          </div>
                        }
                        scrollableTarget="scrollableDiv"
                        inverse={true}
                      >
                        {messages.map((x, i) => (
                          <div
                            key={i}
                            className={`chat-message ${
                              x.sender_id === userData._id ? "me" : ""
                            }`}
                          >
                            {/* show the image tag when file media type is image */}
                            <div className="message-content d-flex">
                              {x?.message_type === inputTypeForChat.image ? (
                                <img
                                  src={baseurl + "/" + x?.media}
                                  style={{
                                    width: "98px",
                                    height: "98px",
                                  }}
                                />
                              ) : x?.message_type ===
                                inputTypeForChat.document ? (
                                <Button
                                  onClick={() => {
                                    setTimeout(() => {
                                      setViewModal(true);
                                      setInputType((prev) => ({
                                        ...prev,
                                        viewPdfData: x,
                                      }));
                                    }, 700);
                                  }}
                                >
                                  View document
                                </Button>
                              ) : (
                                <Typography
                                  fontSize={16}
                                  fontWeight={400}
                                  color="text.secondary"
                                >
                                  {x.content}
                                </Typography>
                              )}
                              <span>{fDateTime(x?.updatedAt, "p")}</span>
                            </div>
                          </div>
                        ))}
                      </InfiniteScroll>
                    </div>
                  )}
                </div>

                <div className="typing-indicators">
                  {Object.keys(typingStatus).map(
                    (userId) =>
                      typingStatus[userId] && <p key={userId}>typing...</p>
                  )}
                </div>
                <div className="input-container">
                  <TextField
                    value={message}
                    onKeyUp={handleKeyPress}
                    onChange={(e) => {
                      setMessage(e.target.value);
                      setInputType((prev) => ({
                        ...prev,
                        mediaType: "text",
                      }));
                    }}
                    InputProps={{
                      sx: {
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        borderTopLeftRadius: 10,
                        borderBottomLeftRadius: 10,
                      },
                      endAdornment: (
                        <InputAdornment
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => setShowUploadFile(true)}
                        >
                          <AttachmentIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ backgroundColor: "#F6F6F6", height: 56, border: 0 }}
                    fullWidth
                    type={"text"}
                    placeholder="Type Something..."
                  />
                  <Box>
                    <input
                      id="doc"
                      className="d-none"
                      type="file"
                      onChange={onChange}
                      accept={
                        inputType.mediaType === inputTypeForChat.document
                          ? acceptStringOfInputFile?.document
                          : acceptStringOfInputFile?.image
                      }
                      // multiple
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#009DA6",
                      height: 56,
                      width: 56,
                      borderTopRightRadius: 3,
                      borderBottomRightRadius: 3,
                    }}
                    onClick={(e) => {
                      if (inputType.inputTypeValue == "file") {
                        uploadtheFiles();
                      } else {
                        if (message.trim()) {
                          sendMessage(message, inputType.mediaType);
                          setMessage("");
                        }
                      }
                      // handleKeyPress(e)
                    }}
                  >
                    <Icon
                      icon="mdi:send"
                      sx={{ color: "white", width: 24, height: 24 }}
                    />
                  </Box>
                </div>
              </div>
            )}
            {showUploadFile && (
              <div className="image-upload-chat">
                <h5>UPload files</h5>
                <label
                  htmlFor="doc"
                  style={{ marginRight: "20px", fontSize: "20px" }}
                  onClick={() => inputTypeChange(inputTypeForChat.image)}
                >
                  Image
                </label>
                <label
                  htmlFor="doc"
                  style={{ fontSize: "20px" }}
                  onClick={() => inputTypeChange(inputTypeForChat.document)}
                >
                  Document
                </label>
                <div className="text-end close-btn-chat">
                  <ImCross
                    onClick={() => {
                      setShowUploadFile(false);
                      setInputType((prev) => ({
                        ...prev,
                        file: {},
                      }));
                    }}
                  />
                </div>
                {inputType?.file?.name ? (
                  <h5>file name: {inputType?.file?.name}</h5>
                ) : null}
              </div>
            )}
          </>
        )}
      </Grid>

      <ViewPdfModal
        open={viewModal}
        setOpen={setViewModal}
        data={{ url: baseurl + "/" + inputType?.viewPdfData?.media }}
      />
    </Grid>
  );
};

export default Chat;
