import { Box, CircularProgress } from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Router,
  RouterProvider,
  Routes,
  createBrowserRouter,
  useLocation,
} from "react-router-dom";
import DashboardLayout from "../admin-layouts/dashboard";
import ChatAsPerUser from "../admin-pages/manageChat/chatAsPerUser";
import Dashboard from "../dashboard";

import CreateNewPassword from "../pages/auth/Create-new-password";
import EmailVerification from "../pages/auth/EmailVerification";
import ForgotPassword from "../pages/auth/Forgot-password";
import Login from "../pages/auth/Login";
import SignUp from "../pages/auth/SignUp";
import {
  ActivityLog,
  AddAggrements,
  AddBanners,
  AddBlogs,
  AddCareers,
  AddEmployees,
  AddFAQ,
  AddNewRole,
  AddProperty,
  AddSocialMediaLinks,
  AddUsers,
  AdminBlogDetail,
  AdminDashboard,
  AdminDataProtection,
  AdminPropertiesInDemand,
  AdminPropertyManagement,
  AdminTestimonials,
  CreateNotification,
  CreateTestimonials,
  Employee,
  FinancePerformance,
  Landlords,
  LeaseAggrements,
  LoginPage,
  ManageAboutUs,
  ManageAggrements,
  ManageAggrementsAndDocuments,
  ManageBanners,
  ManageBlogs,
  ManageCareers,
  ManageChat,
  ManageEmployees,
  ManageFaq,
  ManagePrivacyPolicy,
  ManageRolesAndPermission,
  ManageSocialMediaLinks,
  ManageTransactions,
  ManageUsers,
  NotificationDetail,
  Notifications,
  Page404,
  Profile,
  PropertyManagers,
  PropertyRequestDetail,
  Renters,
  ReviewAndRatingMgt,
  UpdateProperty,
  UserDetails,
  ViewOnboardedProperty,
} from "./adminRoutes";
import ScrollToTop from "../hooks/use-scroll-to-top";
import Newsletter from "../admin-pages/newsletter";

import PrivateRoute from "./components/PrivateRoute";
import { routesChildren } from "../utils/webiste-routes";
import { RetrieveLocalUser } from "../utils/authService";
import EditNewRole from "../admin-pages/rolesandPermissionManagement/edit-new-role";
import { permissions } from "../utils/CONSTANTS";
import TermsAndConditions from "../pages/terms-&-conditions";
import ComingSoon from "../pages/coming-soon";
import HomePage from "../pages";
import Blogs from "../pages/Blogs";
import PropertyManagement from "../pages/property-management";
import AboutUs from "../pages/AboutUs";
import Careers from "../pages/Careers";
import Testimonials from "../pages/Testimonials";
import FAQ from "../pages/FAQ";
import PrivacyPolicy from "../pages/privacy-policy";
import DataProtection from "../pages/data-protection";
import ContactUs from "../pages/contactUs";
import SearchProperties from "../pages/common/search-properties";
import PropertyDetail from "../pages/common/property-detail";
import ReferAndEarn from "../pages/referAndEarn";
import ManageContactUs from "../admin-pages/manage-contact-us";
import BlogDetail from "../pages/Blogs/detail";
import Support from "../pages/renter/suppport";

const AppRoutes = () => {
  const loggedInUser = RetrieveLocalUser("user");
  const [adminRoutes, setAdminRoutes] = useState([
    {
      element: <AdminDashboard />,
      path: "dashboard",
      role: ["superAdmin"],
    },

    {
      element: <ManageUsers />,
      path: "manage-users",
      permissionValue: permissions.USER_MANGEMENT,
      role: ["superAdmin"],
    },
    {
      element: <AddUsers />,
      path: "manage-users/add-user",
      permissionValue: permissions.USER_MANGEMENT,
      role: ["superAdmin"],
    },
    {
      element: <ManageEmployees />,
      path: "manage-employees",
      permissionValue: permissions.MANAGE_EMPLOYEES,
      role: ["superAdmin"],
    },
    {
      element: <AddEmployees />,
      path: "manage-employees/add-employees",
      permissionValue: permissions.MANAGE_EMPLOYEES,
      role: ["superAdmin"],
    },
    {
      element: <UserDetails />,
      path: "manage-users/user-details",
      permissionValue: permissions.USER_MANGEMENT,
      role: ["superAdmin"],
    },
    {
      element: <ManageRolesAndPermission />,
      path: "roles-and-permissions-management",
      permissionValue: permissions.MANAGE_ROLES,
      role: ["superAdmin"],
    },
    {
      element: <AddNewRole />,
      path: "add-new-roles-and-permissions-management",
      permissionValue: permissions.MANAGE_ROLES,
      role: ["superAdmin"],
    },
    {
      element: <EditNewRole />,
      path: "edit-new-roles-and-permissions-management",
      permissionValue: permissions.MANAGE_ROLES,
      role: ["superAdmin"],
    },
    {
      element: <ReviewAndRatingMgt />,
      path: "review-and-rating-mgt",
      permissionValue: permissions.MANAGE_REVIEW,
      role: ["superAdmin"],
    },
    {
      element: <FinancePerformance />,
      path: "finance-performance",
      permissionValue: permissions.FINANCE_PERFORMANCE,
      role: ["superAdmin"],
    },
    {
      element: <ManageTransactions />,
      path: "manage-transactions",
      permissionValue: permissions.MANAGE_TRANSACTION,
      role: ["superAdmin"],
    },
    {
      element: <Employee />,
      path: "employee",
      permissionValue: permissions.MANAGE_EMPLOYEES,
      role: ["superAdmin"],
    },
    {
      element: <ManageRolesAndPermission />,
      path: "roles-and-permissions-management",
      permissionValue: permissions.MANAGE_ROLES,
      role: ["superAdmin"],
    },
    {
      element: <AdminPropertyManagement />,
      path: "property-management",
      permissionValue: permissions.MANAGE_PROPERTY,
      role: ["superAdmin"],
    },
    {
      element: <AdminPropertiesInDemand />,
      path: "properties-in-demand",
      permissionValue: permissions.MANAGE_PROPERTY,
      role: ["superAdmin"],
    },
    {
      element: <ManageAggrementsAndDocuments />,
      path: "manage-aggrements-documents",
      role: ["superAdmin"],
    },
    {
      element: <ManageBlogs />,
      path: "manage-blogs",
      role: ["superAdmin"],
    },
    {
      element: <Newsletter />,
      path: "newsletter",
      role: ["superAdmin"],
    },
    {
      element: <ManageAboutUs />,
      path: "manage-aboutUS",
      role: ["superAdmin"],
    },
    {
      element: <ManagePrivacyPolicy />,
      path: "manage-privacy-policy",
      role: ["superAdmin"],
    },
    {
      element: <Profile />,
      path: "profile",
      role: ["superAdmin"],
    },

    {
      element: <ActivityLog />,
      path: "activity-logs",
      role: ["superAdmin"],
    },
    {
      element: <ManageChat />,
      path: "manage-chat",
      role: ["superAdmin"],
    },
    {
      element: <ChatAsPerUser />,
      path: "user-chat",
      role: ["superAdmin"],
    },
    {
      element: <AddBlogs />,
      path: "add-blogs",
      role: ["superAdmin"],
    },
    {
      element: <AdminBlogDetail />,
      path: "blog-detail/:id",
      role: ["superAdmin"],
    },
    {
      element: <ManageFaq />,
      path: "manage-faq",
      role: ["superAdmin"],
    },
    {
      element: <AddFAQ />,
      path: "add-faq",
      role: ["superAdmin"],
    },

    {
      path: "404",
      element: <Page404 />,
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
    {
      element: <ManageCareers />,
      path: "manage-careers",
      role: ["superAdmin"],
    },
    {
      element: <AddCareers />,
      path: "add-careers",
      role: ["superAdmin"],
    },
    {
      element: <ManageContactUs />,
      path: "manage-contact-us",
      role: ["superAdmin"],
    },
    {
      element: <AdminTestimonials />,
      path: "testimonials",
      role: ["superAdmin"],
    },
    {
      element: <ManageSocialMediaLinks />,
      path: "manage-social-media-links",
      role: ["superAdmin"],
    },
    {
      element: <AddSocialMediaLinks />,
      path: "add-social-media-links",
      role: ["superAdmin"],
    },
    {
      element: <AdminDataProtection />,
      path: "data-protection",
      role: ["superAdmin"],
    },
    {
      element: <CreateTestimonials />,
      path: "add-testimonials",
      role: ["superAdmin"],
    },
    {
      element: <ManageBanners />,
      path: "manage-banners",
      role: ["superAdmin"],
    },
    {
      element: <AddBanners />,
      path: "add-banners",
      role: ["superAdmin"],
    },
    {
      element: <ManageAggrements />,
      path: "manage-aggrements",
      role: ["superAdmin"],
    },
    {
      element: <AddAggrements />,
      path: "add-aggrements",
      role: ["superAdmin"],
    },
    {
      element: <AddProperty />,
      path: "add-property",
      role: ["superAdmin"],
    },
    {
      element: <ViewOnboardedProperty />,
      path: "view-onboarded-property",
      role: ["superAdmin"],
    },
    {
      element: <PropertyRequestDetail />,
      path: "property-request-detail",
      role: ["superAdmin"],
    },
    {
      element: <AddProperty />,
      path: "property-management/:id",
      role: ["superAdmin"],
    },
    {
      element: <LeaseAggrements />,
      path: "lease-aggrements",
      role: ["superAdmin"],
    },
    {
      element: <Notifications />,
      path: "notifications",
      role: ["superAdmin"],
    },
    {
      element: <CreateNotification />,
      path: "create-notifications",
      role: ["superAdmin"],
    },
    {
      element: <Renters />,
      path: "renters",
      role: ["superAdmin"],
      permissionValue: permissions.USER_MANGEMENT,
    },
    {
      element: <PropertyManagers />,
      path: "property-managers",
      role: ["superAdmin"],
      permissionValue: permissions.USER_MANGEMENT,
    },
    {
      element: <Landlords />,
      path: "landlords",
      role: ["superAdmin"],
      permissionValue: permissions.USER_MANGEMENT,
    },
    {
      path: "notification-detail",
      element: <NotificationDetail />,
      role: ["superAdmin"],
    },
  ]);

  console.log("-loggedInUser", loggedInUser);

  //SET THE ADMIN ROUTES AS PER THE PERMISSION OF THE USER
  //@@@ if user role type is superAdmin then it will show all the route
  //@@ if the user role type not superAdmin then manage data as per the backend permission
  const setAdminRouteAsPerPermisions = () => {
    if (loggedInUser != null && loggedInUser?.role !== "superAdmin") {
      const data = adminRoutes
        .filter(
          (item) =>
            loggedInUser?.permissions?.includes(item.permissionValue) ||
            item.path === "dashboard"
        )
        .map((el) => {
          let updatedRole = el?.role
            ? [...el?.role, loggedInUser?.role]
            : [loggedInUser?.role];
          return { ...el, role: updatedRole };
        });
      console.log("--data", data, loggedInUser?.role);
      setAdminRoutes(data);
    }
  };

  useEffect(() => {
    setAdminRouteAsPerPermisions();
  }, []);

  const routes = createBrowserRouter([
    {
      path: "admin",
      element: (
        <DashboardLayout>
          <Suspense
            fallback={
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  height: "100vh",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            }
          >
            <Outlet />
          </Suspense>
        </DashboardLayout>
      ),
      children: adminRoutes,
    },
    {
      path: "admin/login",
      element: <LoginPage />,
    },
    {
      path: "/",
      element: (
        <Dashboard>
          <HomePage />
        </Dashboard>
      ),
    },
    {
      element: (
        <Dashboard>
          <Blogs />
        </Dashboard>
      ),
      path: "blogs",
    },
    {
      element: (
        <Dashboard>
          <BlogDetail />
        </Dashboard>
      ),
      path: "blog-detail",
    },
    {
      element: (
        <Dashboard>
          <AboutUs />
        </Dashboard>
      ),
      path: "aboutUs",
    },
    {
      element: (
        <Dashboard>
          <Careers />
        </Dashboard>
      ),
      path: "careers",
    },
    {
      element: (
        <Dashboard>
          <Testimonials />
        </Dashboard>
      ),
      path: "testimonials",
    },
    {
      element: (
        <Dashboard>
          <FAQ />
        </Dashboard>
      ),
      path: "faq",
    },
    {
      element: (
        <Dashboard>
          <PropertyManagement />
        </Dashboard>
      ),
      path: "property-management",
    },
    {
      element: (
        <Dashboard>
          <PrivacyPolicy />
        </Dashboard>
      ),
      path: "privacy-policy",
    },

    {
      element: (
        <Dashboard>
          <DataProtection />
        </Dashboard>
      ),
      path: "data-protection",
    },
    {
      element: (
        <Dashboard>
          <ContactUs />
        </Dashboard>
      ),
      path: "contactUs",
    },
    {
      element: (
        <Dashboard>
          <SearchProperties />
        </Dashboard>
      ),
      path: "filter-properties",
    },
    {
      element: (
        <Dashboard>
          <PropertyDetail />
        </Dashboard>
      ),
      path: "property-detail/:id",
    },
    {
      element: (
        <Dashboard>
          <ReferAndEarn />
        </Dashboard>
      ),
      path: "refer-and-earn",
    },
    {
      element: (
        <Dashboard>
          <Support />
        </Dashboard>
      ),
      path: "support",
    },
    {
      path: "/",
      element: (
        <PrivateRoute>
          <Dashboard>
            <Suspense fallback={<>Loading</>}>
              <ScrollToTop>
                <Outlet />
              </ScrollToTop>
            </Suspense>
          </Dashboard>
        </PrivateRoute>
      ),
      children: routesChildren.map((x) => {
        return {
          element: x.element,
          path: x.path,
        };
      }),
    },
    {
      element: <Login />,
      path: "login",
    },
    {
      element: <SignUp />,
      path: "signup",
    },
    {
      element: <EmailVerification />,
      path: "email-verification",
    },
    {
      element: <ForgotPassword />,
      path: "forgot-password",
    },
    {
      element: <CreateNewPassword />,
      path: "create-password",
    },
    {
      element: <TermsAndConditions />,
      path: "terms&conditions",
    },
  ]);

  return <RouterProvider router={routes} />;
};

export default AppRoutes;
