import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import rented from "../../../assets/images/rented.png";
import React, { useState } from "react";
import { LocationIcon, RatingIcon } from "../../../assets/icons";
import ButtonComponent from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { ReasonToLeaveTheProperty } from "../../../services/properties.services";
import { replaceHttpImgUrl } from "../../../utils/helperFunctionForValidte";
const LeavePropertyModal = ({ open, setOpen, modalData = {} }) => {
  const [formValue, setFormValue] = useState({
    reasonForLeave: {
      value: "",
      warning: false,
    },
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  //onSubmit the reason for the leave the property
  const onSubmitReasonForLeave = async (e) => {
    e.preventDefault();
    let data = { ...formValue };
    data = {
      ...data,
      reasonForLeave: {
        ...data.reasonForLeave,
        warning: data.reasonForLeave.value == "",
      },
    };
    setFormValue(data);
    if (!data.reasonForLeave.warning) {
      setLoading(true);
      const body = {
        reason: formValue?.reasonForLeave?.value,
      };
      const getData = await ReasonToLeaveTheProperty(
        modalData?.propertyData?._id,
        body
      );
      console.log("getData", getData);
      setLoading(false);
      navigate(-1);
    }
  };
  console.log("obnca", modalData);
  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        className="text-center"
        fontSize={40}
        fontWeight={"bold"}
        color={"text.primary"}
        sx={{
          "@media (max-width: 600px)": {
            fontSize: "20px",
          },
        }}
      >
        Leave this Property
      </DialogTitle>
      <DialogContent>
        <Card sx={{ width: "100%" }} className="p-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-center align-items-center leave_card_property gap-2">
              <img
                style={{ width: 127, height: 73, borderRadius: 10 }}
                src={
                  modalData?.propertyData?.images &&
                  replaceHttpImgUrl(modalData?.propertyData?.images[0]?.url)
                }
              />
              <div className="property_data">
                <Typography fontSize={24} color="#031013" fontWeight={"bold"}>
                  {modalData?.propertyData?.propertyName}
                </Typography>
                <div className="d-flex gap-1">
                  <LocationIcon />
                  <Typography color="#8D9B9F" fontWeight={200}>
                    {modalData?.propertyData?.address?.addressText}
                  </Typography>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center gap-1">
              {/* <RatingIcon />
              <Typography fontSize={18} color="#031013" fontWeight={"bold"}>
                4.6
              </Typography> */}
            </div>
          </div>
        </Card>
        <TextField
          fullWidth
          className="mt-2"
          multiline={true}
          rows={5}
          placeholder="Write your reason"
          onChange={(e) => {
            setFormValue((prev) => ({
              ...prev,
              reasonForLeave: {
                value: e.target.value,
                warning: false,
              },
            }));
          }}
        />
        {formValue.reasonForLeave.warning && (
          <span className="text-danger">Enter the reason</span>
        )}

        <div className="d-flex justify-content-between align-items-center py-2">
          <ButtonComponent
            title={"Cancel"}
            color="primary"
            sx={{
              borderRadius: 2,
              width: 323,
              height: 64,
              background: "white",
              border: "1px solid #C8D736",
            }}
            onClick={() => setOpen(false)}
          />
          &nbsp;&nbsp;
          <ButtonComponent
            sx={{ width: 323, height: 64, borderRadius: 2 }}
            title={"Submit"}
            color="primary"
            loading={loading}
            disabled={loading}
            onClick={(e) => {
              onSubmitReasonForLeave(e);
            }}
          />
        </div>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default LeavePropertyModal;
