import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TelePhone } from "../../../assets/svg";
import { theme } from "../../../assets/theme";
import ButtonComponent from "../../../components/Button";
import { markResolvedMaintenanceRequest } from "../../../services/maintenanaceRequestServices";
import { changeNumeicFormatDateToStandardDateFromatForSingle } from "../../../utils/format-time";
import AddRemarkModal from "./addRemarkModal";
import MarkResolvedModal from "./markResolvedModal";

export default function MaintainceDeatil() {
  const [modal, setModal] = useState(false);
  const [markModal, setMarkModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const { maintainceDetail } = useSelector((state) => state.MAINTAINCEREQUEST);
  console.log("maintainceDetail", maintainceDetail);
  // call the api for mark resolved as per the backend
  const markAsResolvedApiCall = async (e) => {
    e.stopPropagation();
    try {
      setLoading(true);
      const markResolved = await markResolvedMaintenanceRequest(
        maintainceDetail?._id
      );
      setLoading(false);
      if (markResolved?.status) {
        setMarkModal(true);
      } else {
        console.log("markResolved", markModal);
      }
    } catch (error) {
      console.log("err");
    }
  };
  return (
    <Container className="mt-4">
      <Box marginTop={"23px"}>
        <Typography
          className="phone-heading-30"
          fontSize={40}
          fontWeight={700}
          color={theme.palette.text.primary}
          marginBottom={"20px"}
        >
          Maintenance Requests
        </Typography>
      </Box>
      <Grid md={4} padding={"10px"}>
        <Grid container>
          <Grid item md={1} textAlign={"left"}>
            <img
              src={
                maintainceDetail?.renterDetails &&
                maintainceDetail?.renterDetails[0]?.picture
              }
              style={{
                borderRadius: "50%",
                height: "100px",
                width: "100px",
              }}
            />
          </Grid>
          <Grid item md={11} paddingLeft={"15px"}>
            <Typography color={"#13556D"} fontWeight={700} fontSize={18}>
              {maintainceDetail?.renterDetails &&
                maintainceDetail?.renterDetails[0]?.fullName}
            </Typography>
            <Typography
              className="mt-3 mb-1"
              color={"#8D9B9F"}
              fontWeight={400}
              fontSize={16}
            >
              <span style={{ marginRight: "10px" }}>{TelePhone()}</span>
              {maintainceDetail?.renterDetails &&
                maintainceDetail?.renterDetails[0]?.phone}
            </Typography>
            <Typography
              className="mb-3"
              color={"#8D9B9F"}
              fontWeight={400}
              fontSize={16}
            >
              {/* <span style={{ marginRight: "10px" }}>{LocationIcon()}</span> 6th
              Floor Tower A */}
            </Typography>
          </Grid>
          <Box>
            <Typography color={"#009DA6"} fontWeight={400} fontSize={14}>
              Request for
            </Typography>
            <Typography color={"#07262E"} fontWeight={400} fontSize={16}>
              {maintainceDetail?.concern}
            </Typography>
            <Typography color={"#009DA6"} fontWeight={400} fontSize={16}>
              Landlord Remark
            </Typography>
            <Typography color={"#07262E"} fontWeight={400} fontSize={16}>
              {maintainceDetail?.landlordRemark}
            </Typography>
            <button
              style={{
                background: "transparent",
                border: "transparent",
                color: "#C8D736",
                fontWeight: "bold",
                marginTop: "10px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                navigate("/chat", {
                  state: maintainceDetail?.renterID,
                });
              }}
            >
              Chat Now
            </button>
          </Box>

          <div className="d-flex justify-content-between w-100 mt-4 mb-1">
            <Typography color={"#009DA6"} fontWeight={400} fontSize={14}>
              Status
            </Typography>
            {maintainceDetail?.resolvedOn && (
              <Typography color={"#009DA6"} fontWeight={400} fontSize={14}>
                Requested on
              </Typography>
            )}
          </div>
          <div className="d-flex justify-content-between w-100">
            <Typography
              color={maintainceDetail?.resolvedOn ? "#C8D736" : "#ED1717"}
              fontWeight={600}
              fontSize={16}
            >
              {maintainceDetail?.resolvedOn ? "Resloved" : "Open"}
            </Typography>

            {maintainceDetail?.resolvedOn && (
              <Typography color={"#07262E"} fontWeight={400} fontSize={16}>
                {changeNumeicFormatDateToStandardDateFromatForSingle(
                  Number(maintainceDetail?.resolvedOn) / 1000
                )}
              </Typography>
            )}
          </div>

          {maintainceDetail?.resolvedOn ? null : (
            <div className="d-flex justify-content-between w-100 mt-4">
              {maintainceDetail?.landlordRemark ? null : (
                <ButtonComponent
                  title={"Add Remark"}
                  onClick={() => setModal(true)}
                  sx={{
                    border: "1px solid #C8D736",
                    backgroundColor: "#fff",

                    marginBottom: "20px",
                  }}
                />
              )}
              <ButtonComponent
                title={"Mark Resolved"}
                sx={{
                  marginBottom: "20px",
                }}
                loading={loading}
                disabled={loading}
                onClick={(e) => {
                  markAsResolvedApiCall(e);
                }}
              />
            </div>
          )}
          <AddRemarkModal
            open={modal}
            setOpen={setModal}
            heading={"Add Remark"}
            heading2={"Remark Added"}
            detailData={maintainceDetail}
          />
          <MarkResolvedModal
            open={markModal}
            setOpen={setMarkModal}
            detailData={maintainceDetail}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
