import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  LISTED_PROPERTY: "",
  RENTED_PROPERTY: false,
  ADD_PROPERTY: {
    propertyName: "",
    category: "",
    address: "",
    rent: "",
    name: "",
    bedrooms: "",
    rentType: "",
    estateName: "",
    buildNumber: "",
    streetName: "",
    serviceType: "",
    number_of_floors: "",
    number_of_bathrooms: "",
    number_of_rooms: "",
    carpetArea: "",
    age_of_construction: "",
    measurementOfFaculity: "",
    sqMetrForRent: "",
    no_of_toilet: "",
    no_of_administrative_offices: "",
    servicing: "",
    aboutProperty: "",
    type: "",
    furnishingType: "",
    landmark: "",
    superArea: "",
    availability: "",
    communityType: "",
    cautionDeposite: "",
    servicesCharges: "",
    rentFrequency: "",
    name: "",
    email: "",
    amenities: [],
    images: [],
    videos: [],
    documents: [],
    step: 0,
    inDemand: false,
    totalFieldCount: {
      propertyDetailCount: 18,
    },
    fillTotalCount: {
      propertyDetailCount: 0,
    },
    lat: "",
    lng: "",
    is_legal_partner: false,
  },
  API_CALL: false,
};

export const propertyListSlice = createSlice({
  name: "propertyList",
  initialState: initialState,
  reducers: {
    setTheRentedProperty: (state, action) => {
      console.log("action", action.payload);
      let data = { ...state };
      data = {
        ...data,
        RENTED_PROPERTY: action.payload === "Rent" ? true : false,
        LISTED_PROPERTY: action.payload,
      };
      return data;
    },
    setAddPropertyData: (state, action) => {
      let data = { ...state };
      data = {
        ...data,
        ADD_PROPERTY: action.payload,
      };
      return data;
    },
    resetTheAddProperty: (state, action) => {
      let data = { ...state };
      data = {
        ...data,
        ADD_PROPERTY: initialState.ADD_PROPERTY,
      };
      return data;
    },
    apiCallOnTheActionPerform: (state, action) => {
      let data = { ...state };
      data = {
        ...data,
        API_CALL: !state.API_CALL,
      };
      return data;
    },
  },
});
