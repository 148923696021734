import { format, getTime, formatDistanceToNow } from "date-fns";
import moment from "moment";

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || "dd MMM yyyy";
  const fixedDateString = String(date)?.replace(/GMT([+-]\d{2})(\d{2})/, "");
  return date ? format(new Date(fixedDateString), fm) : "";
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || "dd MMM yyyy p";

  return date ? format(new Date(date), fm) : "";
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : "";
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : "";
}


/**
 * fromat the date in the string type.
 *
 * @param {string} rent_period_start - date comes in the string which contain number.
 * @param {string} rent_period_end -  date comes in the string which contain number.
 *  @return - a string which contain start and end date 
 */

export function changeNumeicFormatDateToStandardDateFromat(
  rent_period_start,
  rent_period_end
) {
  const startDate = moment.unix(rent_period_start).format("DD MMM YYYY");
  const endDate = moment.unix(rent_period_end).format("DD MMM YYYY");
  return startDate + "-" + endDate;
}

/**
 * fromat the date in the string type.
 *
 * @param {string} date - date comes in the string which contain number.
 *  @return - the date in format and it's type is string.
 */
export function changeNumeicFormatDateToStandardDateFromatForSingle(
  date,
) {
  const dateValue = moment.unix(date).format("DD MMM YYYY");
  return dateValue
}


// compare two date
// param dateValue that compare the value
export const compareDate = (dateValue) => {
  const today = new Date();
  const dateCompare = new Date(dateValue);

  today.setHours(0, 0, 0, 0);
  dateCompare.setHours(0, 0, 0, 0);

  console.log("Current Date (midnight):", today.getTime());
  console.log("Compared Date (midnight):", dateCompare.getTime());

  // Check if today is greater than the compared date
  return today > dateCompare;
};
