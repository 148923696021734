import { Grid, TextField, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import appLogo from "../../assets/images/appLogo.png";
import {
  DownloadFromAppleStore,
  DownloadFromGooglePlay,
  FacebookSidebarIcon,
  InstagramSidebarIcon,
  LinkedinSidebarIcon,
  TwitterSidebarIcon,
} from "../../assets/svg";
import { theme } from "../../assets/theme";
import ButtonComponent from "../../components/Button";
import { addNewsLetter } from "../../services/cms/subscriptionNewsLetter";
import { validationSchemaNewsLetter } from "../../utils/schema";
import SuccessFullySubmitModal from "../../components/successFullySubmittedModal";
import { NotificationManager } from "react-notifications";

const Footer = () => {
  const [loading, setLoading] = useState(false);
  const [successFullyModal, setSuccessFullyModal] = useState(false);
  const subscriptionNewsLetterApiIntigration = async (
    values,
    { resetForm }
  ) => {
    setLoading(true);
    const postData = await addNewsLetter(values);
    if (postData?.status) {
      resetForm();
      setSuccessFullyModal(true);
    } else if (postData?.response?.status === 400) {
      NotificationManager.error("", postData?.response?.data?.message);
    } else {
      NotificationManager.error("", postData?.message);
    }
    console.log("postdata", postData);
    setLoading(false);
  };

  return (
    <footer
      className="footer-section"
      style={{
        backgroundColor: "#54BDC3",
        paddingTop: 50,
        paddingBottom: 20,
      }}
    >
      <Grid container>
        <Grid
          item
          md={6}
          display={"flex"}
          paddingLeft={10}
          flexDirection={"column"}
          className="footer-firstcol"
        >
          <div className="d-flex mt-4 gap-2 align-items-center">
            <img src={appLogo} style={{ width: 95, height: 58 }} />
            <Typography
              className="phone-heading-30 logo_text"
              fontSize={48}
              fontWeight={500}
              color={theme.palette.text.primary}
            >
              Rent Made Easy
            </Typography>
          </div>
          <Typography
            className="mt-3"
            // textAlign={"left"}
            fontSize={16}
            color={theme.palette.text.secondary}
          >
            Bringing together Landlord, Renter and Property
            <br /> managers for smooth rental Experience
          </Typography>

          <Typography
            className="mt-3 mt-md-5"
            color={theme.palette.text.primary}
            fontSize={24}
            fontWeight={500}
          >
            Subscribe to our newsletter
          </Typography>
          <div className="mt-1 footer-input">
            <Formik
              initialValues={{
                email: "",
              }}
              enableReinitialize
              validationSchema={validationSchemaNewsLetter}
              onSubmit={subscriptionNewsLetterApiIntigration}
            >
              {({ setFieldValue, errors, touched, values }) => (
                <Form>
                  <TextField
                    variant="outlined"
                    size="large"
                    sx={{
                      width: "100%",
                      background: "white",
                      // borderRadius: 1,
                      border: "none",
                    }}
                    placeholder="Enter your email"
                    name="email"
                    fullWidth
                    value={values.email}
                    onChange={(e) => setFieldValue("email", e.target.value)}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                  <ButtonComponent
                    className="footer-submit"
                    sx={{ height: 54, width: 152 }}
                    title={"Submit"}
                    type="submit"
                    loading={loading}
                    disabled={loading}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </Grid>
        <Grid className="footer_widget" item md={2} display={"flex"} gap={2} flexDirection={"column"}>
          <b>Company</b>
          <Link to={"/aboutUs"}>
            <Typography color={theme.palette.text.primary} fontWeight={600}>
              About Us
            </Typography>
          </Link>
          <Link to={"/careers"}>
            <Typography color={theme.palette.text.primary} fontWeight={600}>
              Careers
            </Typography>
          </Link>
          <Link to={"/testimonials"}>
            <Typography color={theme.palette.text.primary} fontWeight={600}>
              Testimonials
            </Typography>
          </Link>
          <Link to={"/faq"}>
            <Typography color={theme.palette.text.primary} fontWeight={600}>
              Faq
            </Typography>
          </Link>
          <Link to={"/privacy-policy"}>
            <Typography color={theme.palette.text.primary} fontWeight={600}>
              Privacy Policy
            </Typography>
          </Link>
          <Link to={"/data-protection"}>
            <Typography color={theme.palette.text.primary} fontWeight={600}>
              Data Protection
            </Typography>
          </Link>
        </Grid>
        <Grid
          item
          md={2}
          display={"flex"}
          gap={2}
          flexDirection={"column"}
          className="footer_widget"
        >
          <b>Get In touch</b>

          {/* <Link to={"/contactUs"}>
            <Typography color={theme.palette.text.primary} fontWeight={600}>
              Contact Us
            </Typography>
          </Link> */}
          <Link to={"/support"}>
            <Typography color={theme.palette.text.primary} fontWeight={600}>
              Support
            </Typography>
          </Link>
          <Link to={"/blogs"}>
            <Typography color={theme.palette.text.primary} fontWeight={600}>
              Blog
            </Typography>
          </Link>
        </Grid>
        <Grid item md={2} display={"flex"} gap={2} className="footer-col-last footer_widget" flexDirection={"column"}>
          <b>Our Offerings</b>
          <Link to={"/property-management"}>
            <Typography color={theme.palette.text.primary} fontWeight={600}>
              Rent
            </Typography>
          </Link>

          <Link>
            <Typography color={theme.palette.text.primary} fontWeight={600}>
              List n Earn
            </Typography>
          </Link>

          <Link>
            <Typography color={theme.palette.text.primary} fontWeight={600}>
              Rent insurance
            </Typography>
          </Link>

          <Link to={"/refer-and-earn"}>
            <Typography color={theme.palette.text.primary} fontWeight={600}>
              Refer And Earn
            </Typography>
          </Link>

          <Link to={"/refer-and-earn"}>
            <Typography color={theme.palette.text.primary} fontWeight={600}>
              Rent Now:Pay Later
            </Typography>
          </Link>
        </Grid>

        <Grid item md={12}>
          <div className="px-5">
            <hr style={{ height: 4, background: "#33B1B8", width: "100%" }} />{" "}
          </div>
        </Grid>

        <Grid item md={12} className="px-5 footer-last-section">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div className="d-flex align-items-center gap-3 mt-2 app-btns">
              <DownloadFromGooglePlay />
              <DownloadFromAppleStore />
            </div>
            <div className="d-flex align-items-center gap-4">
              <Link>
                <FacebookSidebarIcon color="#13556D" />
              </Link>
              <Link>
                <LinkedinSidebarIcon color="#13556D" />
              </Link>
              <Link>
                <TwitterSidebarIcon color="#13556D" />
              </Link>
              <Link>
                <InstagramSidebarIcon color="#13556D" />
              </Link>
            </div>
          </div>
        </Grid>
      </Grid>
      <SuccessFullySubmitModal
        open={successFullyModal}
        setOpen={setSuccessFullyModal}
        btnText="Close"
        headingAndText={{
          mainHeading: "You have successfully subscribe to our newsletter",
        }}
        onCLick={() => {
          setSuccessFullyModal(false);
        }}
      />
    </footer>
  );
};

export default Footer;
