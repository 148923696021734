import { API_Endpoints } from "./API_Endpoints";
import axiosInstance from "../utils/axios";

export const shareTheReferralCode = (body) => {
  return axiosInstance
    .post(API_Endpoints.referAndEarn.shareRefrealCode, body)
    .then((res) => res.data)
    .catch((err) => err);
};

export const verifyReferCode = (body) => {
  return axiosInstance
    .post(API_Endpoints.referAndEarn.validateReferralCode, body)
    .then((res) => res.data)
    .catch((err) => err);
};
