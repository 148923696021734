import {
  Box,
  Card,
  Chip,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import rented from "../../../assets/images/rented.png";
import ButtonComponent from "../../../components/Button";
import { LocationIcon, SearchIcon } from "../../../assets/icons";
import { useLocation, useNavigate } from "react-router-dom";
import ReviewPropertiesModal from "./reviewPropertiesModal";
import PropertyDetailCard from "../../../components/propertyDetailCard";
import { PropertyPropsObjEnum, roleType } from "../../../utils/appConstants";
import { RetrieveLocalUser } from "../../../utils/authService";
import { GetRentProperties } from "../../../services/rentapplications.service";
import Loader from "../../../components/loader";
import {
  citiesData,
  contentMaintenance,
  extractCites,
  ROLES,
} from "../../../utils/CONSTANTS";
import SendRequestModal from "../maintaince-request/sendRequestModal";
import { changeNumeicFormatDateToStandardDateFromat } from "../../../utils/format-time";
import {
  formatCurrency,
  replaceHttpImgUrl,
} from "../../../utils/helperFunctionForValidte";
import { API_Endpoints } from "../../../services/API_Endpoints";
import { useSelector } from "react-redux";
import useDebounceSearch from "../../../services/globalSearchService";
import { propertyType } from "../../../sections/user/utils";
export const rentedProperties = [
  {
    img: rented,
    rentPeriod: "01 Jan 2024 - Present",
    title: "Firenze Apartments Ikeja",
    location: "Gra Ikeja, Ikeja GRA, Ikeja, Lagos",
    price: "₦ 7,923/ monthly",
    status: "Currently living",
  },
  {
    img: rented,
    rentPeriod: "01 Jan 2024 - Present",
    title: "Firenze Apartments Ikeja",
    location: "Gra Ikeja, Ikeja GRA, Ikeja, Lagos",
    price: "₦ 7,923/ monthly",
    status: null,
  },
  {
    img: rented,
    rentPeriod: "01 Jan 2024 - Present",
    title: "Firenze Apartments Ikeja",
    location: "Gra Ikeja, Ikeja GRA, Ikeja, Lagos",
    price: "₦ 7,923/ monthly",
    status: null,
  },
  {
    img: rented,
    rentPeriod: "01 Jan 2024 - Present",
    title: "Firenze Apartments Ikeja",
    location: "Gra Ikeja, Ikeja GRA, Ikeja, Lagos",
    price: "₦ 7,923/ monthly",
    status: null,
  },
  {
    img: rented,
    rentPeriod: "01 Jan 2024 - Present",
    title: "Firenze Apartments Ikeja",
    location: "Gra Ikeja, Ikeja GRA, Ikeja, Lagos",
    price: "₦ 7,923/ monthly",
    status: null,
  },
];
const RentedProperties = () => {
  const userData = RetrieveLocalUser("user");
  const navigate = useNavigate();
  const [allStatusSearchState, setAllStatusSearchState] = useState({
    search: "",
    propertyType: "",
    city: "",
  });
  const [reviewPropertiesModal, setReviewPropertiesModal] = useState(false);
  const location = useLocation();
  const { RENTED_PROPERTY, LISTED_PROPERTY } = useSelector(
    (state) => state.PROPERTYLIST
  );
  const [modatData, setModalData] = useState({});
  const [raiseQueryModalRenter, setRaiseQueryModalRenter] = useState(false);
  const [rentedPropertiesArr, setRentedPropertiesArr] = useState([]);
  const [loading, setLoading] = useState(false);
  // conditional render of the heading as per the role and the location pathname
  const renderTitleConditionally = () => {
    const { role } = userData;
    const { pathname } = location;

    if (role === ROLES.landlord) {
      if (pathname === "/my-properties") {
        return "My Properties";
      } else if (pathname === "/rented-properties") {
        return "Rented Properties";
      } else if (pathname === "/listed-properties") {
        return "Listed Properties";
      } else if (pathname === "/vacant-properties") {
        return "Vacant Properties";
      } else {
        return "Rented Properties";
      }
    } else if (role === ROLES.propertyManager) {
      if (pathname === "/my-properties") {
        return "Assigned Properties";
      } else if (pathname === "/rented-properties") {
        return "Rented Properties";
      } else if (pathname === "/listed-properties") {
        return "Listed Properties";
      } else if (pathname === "/vacant-properties") {
        return "Vacant Properties";
      } else {
        return "Rented Properties";
      }
    } else {
      return "Rented Properties";
    }
  };

  // get the property listing from the backend
  const getThePropertyList = async () => {
    setLoading(true);
    const data = await GetRentProperties(
      `${API_Endpoints.rentApplications.GET_PROPERTIES}?search=${
        allStatusSearchState.search
      }&${
        LISTED_PROPERTY === "Rent" || LISTED_PROPERTY === "Vacant"
          ? `rented=${RENTED_PROPERTY}`
          : ""
      }&city=${
        allStatusSearchState.city === "All" ? "" : allStatusSearchState.city
      }&type=${allStatusSearchState.propertyType}`
    );
    if (data?.status) {
      setRentedPropertiesArr(data?.data);
    }
    setLoading(false);
  };

  useDebounceSearch(allStatusSearchState.search, getThePropertyList, 1000, {
    propertyType: allStatusSearchState.propertyType,
    city: allStatusSearchState.city,
  });

  console.log("---", rentedPropertiesArr);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container maxWidth={"xl"} className="my-5">
          <div className="my-3">
            <Typography
              component={"h2"}
              color={"text.primary"}
              fontWeight={800}
              fontSize={40}
              className="phone-heading-30"
            >
              {renderTitleConditionally()}
            </Typography>
            {userData?.role === ROLES.landlord ||
            userData?.role === ROLES.propertyManager ? (
              <Box className="mb-4 mt-4">
                <Grid container spacing={2}>
                  <Grid  item md={8} xs={12}>
                    <TextField
                      type="search"
                      variant="outlined"
                      className="w-100"
                      value={allStatusSearchState.search}
                      placeholder="search"
                      onChange={(e) => {
                        setAllStatusSearchState((prev) => ({
                          ...prev,
                          search: e.target.value,
                        }));
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment>
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={12}
                    justifyContent={"space-between"}
                 
                  >
                    {renderTitleConditionally() == "Listed Properties" && (
                      <>
                        <FormControl className="w-50">
                          <InputLabel id="demo-simple-select-label">
                            Select City
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Select City"
                            value={allStatusSearchState?.city}
                            onChange={(e) =>
                              setAllStatusSearchState((prev) => ({
                                ...prev,
                                city: e.target.value,
                              }))
                            }
                          >
                            <MenuItem value={"All"}>All</MenuItem>
                            {extractCites()?.map((item) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <FormControl className="w-50 ps-2">
                          <InputLabel id="demo-simple-select-label">
                            Property type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Property type"
                            value={allStatusSearchState.propertyType}
                            onChange={(e) =>
                              setAllStatusSearchState((prev) => ({
                                ...prev,
                                propertyType: e.target.value,
                              }))
                            }
                          >
                            {propertyType?.map((item) => (
                              <MenuItem value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Typography fontSize={24} fontWeight={500}>
                All the rental properties you have secured through our services.
              </Typography>
            )}
            {userData?.role === ROLES.landlord ||
            userData?.role === ROLES.propertyManager ? (
              <div>
                {rentedPropertiesArr?.length === 0 ? (
                  // condition to render the html if rentedPropertiesArr?.length === 0 show the text
                  <Typography fontSize={24} fontWeight={500}>
                    No Property Found
                  </Typography>
                ) : (
                  rentedPropertiesArr &&
                  rentedPropertiesArr?.map((x, i) => (
                    <PropertyDetailCard
                      data={x}
                      buttonShow={
                        PropertyPropsObjEnum.application_inspection_btn
                      }
                    />
                  ))
                )}
              </div>
            ) : (
              <div>
                {/* this below code is only work on the when the role is Renter */}
                {rentedPropertiesArr?.length === 0 ? (
                  <Typography fontSize={24} fontWeight={500}>
                    No Property Found
                  </Typography>
                ) : (
                  rentedPropertiesArr.map((x, i) => {
                    return (
                      <Card
                        elevation={0}
                        key={i}
                        className="my-3"
                        sx={{
                          cursor: "pointer",
                          borderRadius: 3,
                          boxShadow: "5px 5px 19px 4px #0000000d",
                        }}
                        onClick={() =>
                          navigate(`/rented-property-detail/${x?._id}`)
                        }
                      >
                        <Grid
                          container
                          spacing={3}
                          p={2}
                          display={"flex"}
                          justifyContent={"space-around"}
                          alignItems={"center"}
                          className="rented_properties_card"
                        >
                          <Grid item md={5}>
                            <Box
                              className="properrty-img"
                              sx={{ width: "100%", height: 258 }}
                            >
                              <img
                                src={replaceHttpImgUrl(x?.images[0]?.url)}
                                alt="Property"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  backgroundSize: "cover",
                                }}
                              />
                              <Chip
                                label={x.rented ? "Rented" : "Available"}
                                className="bg-white property-label"
                                sx={{
                                  color: "#009DA6",
                                  fontWeight: "bold",
                                  fontSize: 16,
                                }}
                              />
                            </Box>
                            {/* <img className="w-100" src={x.img} /> */}
                          </Grid>
                          <Grid item md={7}>
                            <div className="d-flex gap-1 py-2">
                              <b style={{ color: "#031013" }}>
                                Rented Period:{" "}
                                {changeNumeicFormatDateToStandardDateFromat(
                                  x?.rent_period_start,
                                  x?.rent_period_end
                                )}
                              </b>
                              <Typography color="#596E73" fontSize={18}>
                                {x.rentPeriod}
                              </Typography>
                            </div>
                            <Typography
                              className="py-1"
                              fontSize={24}
                              color="#031013"
                              fontWeight={"bold"}
                            >
                              {x?.propertyName}
                            </Typography>
                            <div className="d-flex gap-1 py-1 align-items-center">
                              <LocationIcon />
                              <Typography color="#8D9B9F" fontWeight={200}>
                                {x?.address?.addressText}
                              </Typography>
                            </div>
                            <Typography
                              className="py-2"
                              fontWeight={"bold"}
                              color="#031013"
                              fontSize={18}
                            >
                              {formatCurrency(x?.rent)}/{x?.rentType}
                            </Typography>
                            {/* {x.status === "Currently living" ? ( */}
                            <div className="d-flex justify-content-between align-items-center py-2 flex-wrap chat-btn ">
                              <ButtonComponent
                                title={"Chat now"}
                                color="primary"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  navigate("/chat", {
                                    state:
                                      x?.landlord_id ?? x?.property_manager_id,
                                  });
                                }}
                                sx={{
                                  borderRadius: 2,
                                  width: "47%",
                                  height: 64,
                                  background: "white",
                                  border: "1px solid #C8D736",
                                }}
                              />
                              <ButtonComponent
                                sx={{
                                  width: "47%",
                                  height: 64,
                                  borderRadius: 2,
                                  '@media (max-width: 600px)': {
                                    padding:"10px 15px"}
                                }}
                                title={"Raise a query"}
                                color="primary"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setModalData(x);
                                  setRaiseQueryModalRenter(true);
                                }}
                              />
                            </div>
                            {/* <ButtonComponent
                              sx={{
                                width: "100%",
                                height: 64,
                                borderRadius: 2,
                              }}
                              title={"Rate This Property"}
                              color="primary"
                              onClick={(event) => {
                                event.stopPropagation();
                                setModalData(x)
                                setReviewPropertiesModal(true);
                              }}
                            /> */}
                          </Grid>
                        </Grid>
                      </Card>
                    );
                  })
                )}
              </div>
            )}
          </div>
        </Container>
      )}

      {reviewPropertiesModal && (
        <ReviewPropertiesModal
          open={reviewPropertiesModal}
          setOpen={setReviewPropertiesModal}
          modalData={modatData}
        />
      )}
      {raiseQueryModalRenter && (
        <SendRequestModal
          open={raiseQueryModalRenter}
          setOpen={setRaiseQueryModalRenter}
          headingContent={contentMaintenance?.maintenanceRequest}
          propertyData={modatData}
        />
      )}
    </>
  );
};

export default RentedProperties;
