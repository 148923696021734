import { propertyStatus } from "../utils/CONSTANTS";

export const SOCKET_API_ENGPOINTS = {
  joinChatRoom: "/chat/room/join",
  getChatRooms: "/chat/rooms",
  getMessages: "/chat/messages",
  getContacts: "/chat/contacts",
  uploadImgDoc: "/upload/file/multiple",
};

export const API_Endpoints = {
  auth: {
    SIGNUP: "/signup",
    OTP_VERIFICATION: "/otpVerification",
    RESEND_OTP: "/resendOtp",
    LOGIN: "/login",
    SOCIAL_LOGIN: "/socialLogin",
    FORGOT_PASSWORD: "/forgot-password",
    VERIFY_OTP_FORGOTPASSWORD: "/verify-otp",
    RESET_PASSWORD: "/reset-password",
  },
  user: {
    USER_DETAIL: "/user?id=",
  },
  property: {
    ADD_PROPERTY: "/property",
    EDIT_PROPERTY: "/property/edit",
    PROPERTY_LIST: `/property/list`,
    SEARCH_PROPERTY: "property/search",
    GET_PROPERTY_DETAIL: "property",
    REASON_TO_LEAVE_PROPERTY: "/leave-property",
    FILTER_PROPERTY: "/properties",
    EDITBY_ADMIN: "/admin/property/edit",
  },
  review: {
    Add_review: "/review",
    GET_REVIEW: "/reviews",
    CHANGE_STATUS: "/review/change-status",
  },
  inspections: {
    GET_INSPECTION_LIST: "inspection",
    CANCEL_INSPECTION_REQ_BY_RENTER: "inspection/update-status",
    searchInspection: "inspection",
    editInspection: "/inspection/edit",
  },
  referAndEarn: {
    shareRefrealCode: "/refer",
    validateReferralCode: "/verify/referral-code",
  },
  rentApplications: {
    ADD_RENT_APPLICATION: "rentApplication",
    GET_PROPERTIES: "my-properties",
    GET_RENT_APPLICATION: "/rentApplications",
  },
  switchRole: "/switch-role",
  transaction: {
    getTransactionist: "/my-transactions",
    getTransactionDetail: "/transaction",
    rentTransaction: "/transactions/rent",
  },
  application_recived: {
    GET_APPLICATION_LIST: "/rentApplications",
    STATUS_CHANGED_REQ: "/rentApplication/update-status",
  },
  landlordPropertyManger: {
    getPropertyManger: "/property-managers",
    getPropertyMangerDetail: "/property-manager",
    getPropertyList: "/property-manager-property-lists",
  },
  dashboard: {
    landlordDashoard: "/dashboard",
  },
  payment: {
    getClientSecretId: "/payRent",
    addCard: "/card",
    payViaCard: "/payViaWallet",
  },
  notifications: {
    getNotification: "notifications",
  },
  renter: {
    getRenter: "/v2/my-renters",
    terminateRenter: "/terminate-renter",
    renterHistory: "/my-renter/history?id=",
    rentedProperty: "/my-renter/properties/current-rented?id=",
  },
  favourite: {
    setTheFavourite: "/property/favorite/",
    getFavouriteProperties: "/favorites",
  },
  maintenanceRequest: {
    submitMaintenanceRequest: "/maintenance",
    addMaintenanceRemark: "/maintenance/remark",
    cancelMaintenanceREquest: "/maintenance/cancel",
  },
  profile: {
    getProfileDetail: "/my-profile",
    uploadSingleImage: "/upload/image",
  },
  leaseAggrement: {
    submitLeaseAggrement: "/lease-aggrement",
    getLeaseAggrement: "/lease-aggrements",
    deleteLeaseAgreement: "/lease-aggerment",
  },
  wallet: {
    getWalletDetail: "/wallet",
  },
  manageCalenderSlot: {
    addMangeCalenderSlot: "/calender",
    getTimeSlotasPerTheDate: "/calender/timeslot",
    selectedMultipleSlot: "/calender/block/slot/multiple",
    getTheLanlordBlockTimeSlot: "/calender/blocked-timeslot",
  },

  manageBlogs: {
    getWebBlogs: "/admin/blogs",
  },
  manageNewsLetter: {
    addNewsLetterSubscriptions: "/news-letter/subscription",
  },
  contactUs: {
    addContactUs: "/contact-us",
  },
  propertyManger: {
    commissionList: "/commissions",
  },
};

export const ADMIN_API_ENDPOINTS = {
  dashoard: {
    getDashboardData: "/admin/dashboard",
  },
  manageUser: {
    addUser: "/admin/addUser",
    getListing: "/admin/userList",
    getUserByid: "/admin/user/",
    deleteUserByid: "/admin/user/",
    searchUser: "/admin/user",
    updateUserStatus: "/admin/user/update-account-status",
  },
  manageProperty: {
    getPropertyList: "/admin/properties/all",
    getPropertyDetailByID: "/admin/property",
    changeStatusOfProperty: "/admin/property/approval-status",
    deletePropeperty: "/admin/property",
  },
  manageRole: {
    addPermissions: "/admin/roles",
  },
  manageEmployee: {
    getEmployee: "/admin/employees",
    addEmployee: "/admin/addEmployee",
    getEmployeeById: "/admin/employee",
    updateEmployee: "/admin/employee",
    deleteEmployee: "/admin/employee",
    updateEmployeeStatus: "/admin/employee/update-status",
  },
  manageTransaction: {
    getTransaction: "/admin/transactions",
  },
  manageFinance: {
    getFinancePerformance: "/admin/finance",
  },
  leaseAggrement: {
    getLeaseAggrement: "/admin/lease-agreements",
  },
  updateUserStatus: "/admin/update-status",
  manageCms: {
    //blog api endpoints
    addBlog: "/admin/blog",
    getBlogs: "/admin/blogs",
    getBlogById: "/admin/blog",
    updateBlog: "/admin/blog",
    deleteBlog: "/admin/blog",
    getUserBlogById: "/blog",

    //about us api endpoints
    addUpdateAboutUs: "/admin/site/content",
    getAboutUs: "admin/site/content/slug",

    //privacy api endpoints
    addUpdatePrivacy: "/admin/site/content",
    getPrivacy: "/admin/site/content/slug",

    //data-protection api endpoints
    addDataProtection: "/admin/site/content",
    getDataProtection: "/admin/site/content/slug",

    //newslatter api endpoints
    getNewsLatter: "/admin/news-letter/subscriptions",

    //career api endpoints
    addCareer: "/admin/career",
    getCareerList: "/admin/career",
    updateCareer: "/admin/career",
    deleteCareer: "/admin/career",
    getCareerById: "/admin/career",
    getSkillExperience: "/admin/career/filters",
    //testimonial endpoints
    addTestimonial: "/admin/testimonial",
    updateTestimonial: "/admin/testimonial",
    getTestimonials: "/admin/testimonials",
    deleteTestimonial: "/admin/testimonial",

    //banners endpoints
    addBanner: "/admin/testimonials",
    updateBanner: "/admin/testimonials",
    getBanners: "/admin/testimonials",
    deleteBanner: "/admin/testimonials",

    //faq api endpoints
    addFaq: "/admin/faq",
    getFaqList: "/admin/faqs",
    getFaqById: "/admin/faq",
    updateFaq: "/admin/faq",
    deleteFaq: "/admin/faq",

    //Career api endpoints
    addCareer: "/admin/career",
    getCareerList: "/admin/careers",
    getCareerById: "/admin/career",
    updateCareer: "/admin/career",
    deleteCareer: "/admin/career",

    // contact us
    getContactUs: "/admin/contact-us/all",
  },
};
