import { TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import AuthenticationLayout from ".";
import ButtonComponent from "../../components/Button";
import { validateEmail } from "../../utils/helperFunctionForValidte";
import { baseURL } from "../../utils/axios";
import { API_Endpoints } from "../../services/API_Endpoints";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import { useDispatch } from "react-redux";
import { authSlice } from "../../redux/auth/authSlice";
import { useNavigate } from "react-router-dom";
const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState({
    email: {
      value: "",
      warning: false,
    },
  });
  const [loading, setLoading] = useState(false);
  // vvalidate the data and submit the data to backend
  const onSubmit = async (e) => {
    e.preventDefault();
    let data = { ...formValue };
    data = {
      ...data,
      email: {
        ...data.email,
        warning: validateEmail(data.email.value),
      },
    };
    setFormValue(data);
    if (!data?.email?.warning) {
      try {
        setLoading(true);
        const response = await axios.post(
          baseURL + API_Endpoints.auth.FORGOT_PASSWORD,
          {
            email: formValue.email.value,
          }
        );
        console.log("repsonse", response);
        if (response?.data?.status) {
          NotificationManager.success("", response?.data?.message);
          dispatch(
            authSlice.actions.setforgotPasswordUserId({
              id: response.data.data.id,
              email: formValue.email.value,
            })
          ); // this dispatch is used for the set the user id that get from the backend and user fill the email
          navigate("/email-verification");
        } else {
          NotificationManager.error("", response.message);
        }
        setLoading(false);
      } catch (error) {
        console.log("er", error);
        NotificationManager.error("", error?.response?.data?.message);
        setLoading(false);
      }
    }
  };
  return (
    <AuthenticationLayout>
      <div className="phone-side-spacing">
        <Typography
          fontSize={24}
          fontWeight={"bold"}
          color="#111111"
          className="text-center"
          mb={2}
        >
          Forgot Password
        </Typography>

        <Typography fontSize={16} color="#8D9B9F" className="text-center break-hide">
          Enter the email address with your account and we'll send
          <br /> and email with confirmation to reset your password.
        </Typography>

        <div className="mt-4">
          <TextField
            placeholder={"renter@gmail.com"}
            label={"Email id"}
            value={formValue.email.value}
            fullWidth
            onChange={(e) =>
              setFormValue((prev) => ({
                ...prev,
                email: {
                  value: e.target.value.trimStart(),
                  warning: validateEmail(e.target.value),
                },
              }))
            }
          />
          {formValue.email.warning && (
            <span className="text-danger">Enter the valid email</span>
          )}
        </div>
        <ButtonComponent
          title="Reset"
          sx={{ width: "100%", mt: 3, height: 53 }}
          color="secondary"
          onClick={onSubmit}
          loading={loading}
          disabled={loading}
        />
      </div>
    </AuthenticationLayout>
  );
};

export default ForgotPassword;
